import ActionTypes from './candidates.types';
import * as _ from "lodash";

const INITIAL_STATE = {
    search: {
        hasError: false,
        errorMessage: null,
        loading: false,
        filters: { },
        results: [],
        pager: null
    },

    invitedCandidates: { }
}

export default function candidatesReducer(state=INITIAL_STATE, action) {
    const {type, payload, originalPayload} = action;
    switch(type) {
        case ActionTypes.INVITE_CANDIDATE_START:
        {
          const [candidateId] = payload.candidates;
          const key = `${payload.projectId}:${candidateId}`;

          return {
            ...state,
            invitedCandidates:{
              ...state.invitedCandidates,
              [key]: {
                inviting: true,
                invited: false,
                inviteError: null
              }
            }
          };
        }
        case ActionTypes.INVITE_CANDIDATE_FINISHED:
        {
          const {
            projectId,
            candidates: [candidateId]
          } = originalPayload;

          const key = `${projectId}:${candidateId}`;

          return {
            ...state,
            invitedCandidates:{
              ...state.invitedCandidates,
              [key]: {
                inviting: false,
                invited: true,
                inviteError: null
              }
            }
          };
        }
        case ActionTypes.INVITE_CANDIDATE_ERROR:
        {
          const {
            projectId,
            candidates: [candidateId]
          } = originalPayload;

          const key = `${projectId}:${candidateId}`;
          return {
            ...state,
            invitedCandidates:{
              ...state.invitedCandidates,
              [key]: {
                inviting: false,
                invited: false,
                inviteError: payload
              }
            }
          };
        }
        case ActionTypes.SEARCH_CANDIDATES_FETCH_START:
            return {
                ...state,
                search: {
                    ...state.candidates,
                    results: state.search.results ? [...state.search.results] : null,
                    businessProfiles: state.search.businessProfiles ? [ ...state.search.businessProfiles ] : null,
                    loading: _.isNull(state.search.pager),
                    hasError: false,
                    errorMessage: null,
                    filters: payload
                }
            }
        case ActionTypes.SEARCH_CANDIDATES_FETCH_FINISHED:
            return {
                ...state,
                search: {
                  ...state.candidates,
                  loading: false,
                  results: (payload.pager.isFirstFetch) ? (payload.candidates) : state.search.results ? [...(state.search.results || []), ...(payload.candidates || [])] : null,
                  businessProfiles: (payload.pager.isFirstFetch) ? (payload.businessProfiles) : state.search.businessProfiles ? [...(state.search.businessProfiles || []),...(payload.businessProfiles || [])] : null,
                  pager: payload.pager,
                }
            }
        case ActionTypes.SEARCH_CANDIDATES_FETCH_ERROR:
            return {
                ...state,
                search: {
                    ...state.candidates,
                    loading: false,
                    hasError: true,
                    errorMessage: payload
                }
            }
        default:
            return state
    }
}
