import { all } from 'redux-saga/effects';

import {
  fetchLanguages,
  fetchRejectReasons,
  fetchProjectTypes,
  fetchSubProjectTypes,
  fetchIndustries,
  fetchQualifications,
  fetchTimeslots,
  fetchExpertiseLevels,
  fetchApplicationStatuses,
  fetchCourses,
  fetchPricingTypes,
  fetchCrms,
  fetchAssessmentTypes,
  fetchTypingAssessmentTypes,
  fetchJobLocationTypes,
  fetchWorkPreferenceTypes,
  fetchFeatureFlags,
  fetchSkills,
  fetchAddressOfGeoLocation,
  fetchDetailsOfIfsc,
  fetchWorkSpaceSetup,
  fetchLeaveCategories,
  fetchcompanyTypes,
  fetchRankings,
} from './reducers/data/data.sagas';

import {
  candidateSearch,
  inviteCandidate,
} from './reducers/candidates/candidates.sagas';

import {
  listTeams,
  addTeam,
  getTeam,
  addMember,
  addMemberToTeam,
  listRoles,
  getRole,
  listPermissions,
  addRole,
  updateRole,
  updateTeamUser,
  getTeamLabels,
} from './reducers/teams/teams.sagas';

import {
  createProject,
  listProjects,
  getProjectDetail,
  getProjectApplications,
  getProjectApplicationsV2,
  getProjectApplicationsWithSubStatus,
  freezeProjectTimeSlot,
  unfreezeProjectTimeSlot,
  updateProject,
  deleteProject,
  getProjectIntegrations,
  updateProjectIntegration,
  approveWorkReport,
  addCrm,
  getPricingEstimate,
} from './reducers/project/project.sagas';

import {
  userSignup,
  userLogin,
  verifyOtp,
  resendOtp,
  signout,
  forgotPassword,
  validateResetPasswordToken,
  resetPassword,
  updateUser,
  changePassword,
  deactivateUser,
  deleteUser,
  setFeatureFlag,
  setOnboadingData,
  resendBypassOtp,
  verifyBypassOtp,
  getAdminBypassDetails,
  reportAbuse,
  addToFavorites,
  getFavorites,
} from './reducers/user/user.sagas';

import {
  getAllBusinesses,
  getBusiness,
  updateBusiness,
  getMainsummary,
  getDailerSummary,
  getSlotSummary,
  getqualityMetrics,
  getrrDetails,
  getcSatDetails,
  getpcdDetails,
  getcSatSummary,
  getproductionAgents,
  getproductivitySummary,
  getrrSummary,
  getpcdSummary,
  getAgentLogins,
  agentView,
  getBusinessTransactions,
  getBusinessWallet,
  requestBusinessInvitation,
  businessInvitation,
  toggleAnnexure,
  fetchOfferLetterConfig,
  configureProjectsForAnnexure,
  configureOfferLetter,
  getCampaigns,
  updateExternalCampaign,
  addCampaign,
  getProductivityLogs,
  getCampaignFields,
  updateCampaignField,
  removeCampaignField,
  addCampaignField,
} from './reducers/business/business.sagas';

import {
  updateApplicationStatus,
  fetchApplicationProductivity,
  fetchApplicationProductivityDetails,
  fetchApplicationProductivityReports,
} from './reducers/applications/applications.sagas';

import {
  jobSearch,
  applyForAJob,
  getApplications,
  getWork,
  updateWorkReportTimer,
  saveWorkReport,
  applyLeave,
  getJobDetails,
} from './reducers/jobs/jobs.sagas';

import {
  getFullProfile,
  updateProfile,
  getExperiences,
  addExperience,
  getBankDetails,
  updateBankDetails,
  getKyc,
  updateKyc,
  getQualifications,
  getCertifications,
  removeOrUpdateQualification,
  removeOrUpdateCertification,
  removeOrUpdateExperience,
  addQualification,
  addCertification,
  updateExperienceJobFunctions,
  getDocuments,
  getKycDetails,
  saveKycDetails,
  getShortProfile,
  fetchNotificationPreferences,
  toggleNotificationPreference,
  getJobFunctionPriorities,
  manageJobFunctionPriority,
  fetchVoizResume,
  generateProfileVideo,
  getMockCallSet,
  getMockCallScenarios,
  generateProfileAudio,
} from './reducers/profile/profile.sagas';

import {
  getKycDocuments,
  getProjectDocuments,
} from './reducers/uploads/uploads.sagas';

import {
  clearNotifications,
  removeNotification,
  getNotifications,
  getNotificationSnapshot,
  markAsRead,
  getUnreadCount,
} from './reducers/notifications/notification.sagas';

import { makePayment } from './reducers/payment/payment.sagas';

import { applicantSearch } from './reducers/applicants/applicants.sagas';

import {
  getUserAssessments,
  getNextQuestion,
  getPrevQuestion,
  startAssessment,
  submitTypingAssessment,
} from './reducers/assessments/assessments.sagas';
import {
  getJobsSnap,
  getProfileCompletion,
  getStatistics,
} from './reducers/home/home.sagas';

import { getAadhaarSession, verifyAadhaarOtp } from './reducers/kyc/kyc.sagas';

import {
  startWork,
  endBreak,
  endWork,
  takeABreak,
  getSessionDetails,
} from './reducers/workSessions/workSessions.sagas';

export default function* rootSaga() {
  yield all([
    // data.saga
    fetchLanguages(),
    fetchRejectReasons(),
    fetchRankings(),
    fetchProjectTypes(),
    fetchSubProjectTypes(),
    fetchIndustries(),
    fetchQualifications(),
    fetchTimeslots(),
    fetchExpertiseLevels(),
    fetchApplicationStatuses(),
    fetchCourses(),
    fetchPricingTypes(),
    fetchCrms(),
    fetchAssessmentTypes(),
    fetchTypingAssessmentTypes(),
    fetchJobLocationTypes(),
    fetchWorkPreferenceTypes(),
    fetchFeatureFlags(),
    fetchSkills(),
    fetchAddressOfGeoLocation(),
    fetchDetailsOfIfsc(),
    fetchWorkSpaceSetup(),

    candidateSearch(),
    inviteCandidate(),

    getAllBusinesses(),
    getBusiness(),
    updateBusiness(),
    getMainsummary(),
    getDailerSummary(),
    getSlotSummary(),
    getqualityMetrics(),
    getrrDetails(),
    getcSatDetails(),
    getpcdDetails(),
    getcSatSummary(),
    getproductionAgents(),
    getproductivitySummary(),
    getrrSummary(),
    getpcdSummary(),
    getAgentLogins(),
    agentView(),
    getCampaignFields(),
    updateCampaignField(),
    removeCampaignField(),
    addCampaignField(),

    updateApplicationStatus(),

    getFullProfile(),
    updateProfile(),
    getExperiences(),
    addExperience(),
    getBankDetails(),
    updateBankDetails(),
    getJobFunctionPriorities(),
    manageJobFunctionPriority(),
    fetchVoizResume(),
    generateProfileVideo(),
    getKyc(),
    updateKyc(),

    getQualifications(),
    getCertifications(),
    removeOrUpdateQualification(),
    removeOrUpdateCertification(),
    removeOrUpdateExperience(),
    addQualification(),
    addCertification(),
    updateExperienceJobFunctions(),
    getDocuments(),
    getKycDetails(),
    saveKycDetails(),
    fetchNotificationPreferences(),
    toggleNotificationPreference(),
    // Projects
    createProject(),
    listProjects(),
    getCampaigns(),
    updateExternalCampaign(),
    addCampaign(),
    getProjectDetail(),
    getProjectApplications(),
    getProjectApplicationsV2(),
    getProjectApplicationsWithSubStatus(),
    approveWorkReport(),
    fetchOfferLetterConfig(),
    configureProjectsForAnnexure(),
    configureOfferLetter(),
    freezeProjectTimeSlot(),
    unfreezeProjectTimeSlot(),
    updateTeamUser(),
    updateProject(),
    deleteProject(),
    getProjectIntegrations(),
    updateProjectIntegration(),
    addCrm(),
    toggleAnnexure(),
    getTeamLabels(),
    jobSearch(),
    applyForAJob(),
    getApplications(),
    getWork(),
    updateWorkReportTimer(),
    saveWorkReport(),
    applyLeave(),
    getJobDetails(),

    userSignup(),
    userLogin(),
    verifyOtp(),
    verifyBypassOtp(),
    getAdminBypassDetails(),
    resendOtp(),
    resendBypassOtp(),
    signout(),
    forgotPassword(),
    validateResetPasswordToken(),
    resetPassword(),
    updateUser(),
    changePassword(),
    deactivateUser(),
    deleteUser(),
    setFeatureFlag(),
    setOnboadingData(),
    getShortProfile(),
    getMockCallSet(),
    getMockCallScenarios(),
    generateProfileAudio(),
    reportAbuse(),
    addToFavorites(),
    getFavorites(),
    getKycDocuments(),
    getProjectDocuments(),

    getNotifications(),
    getNotificationSnapshot(),
    markAsRead(),
    clearNotifications(),
    removeNotification(),
    getUnreadCount(),

    makePayment(),

    listTeams(),
    addTeam(),
    getTeam(),
    addMember(),
    addMemberToTeam(),
    listRoles(),
    listPermissions(),
    getRole(),
    addRole(),
    updateRole(),
    applicantSearch(),
    getBusinessTransactions(),
    getBusinessWallet(),
    requestBusinessInvitation(),
    businessInvitation(),
    getPricingEstimate(),
    getProductivityLogs(),
    // ASSESSMENTS
    getUserAssessments(),
    getNextQuestion(),
    getPrevQuestion(),
    startAssessment(),
    submitTypingAssessment(),
    fetchLeaveCategories(),
    fetchcompanyTypes(),
    fetchApplicationProductivity(),
    fetchApplicationProductivityDetails(),
    fetchApplicationProductivityReports(),
    // HOME
    getStatistics(),
    getProfileCompletion(),
    getJobsSnap(),

    // KYC
    getAadhaarSession(),
    verifyAadhaarOtp(),

    // WORK SESSION
    startWork(),
    endBreak(),
    endWork(),
    takeABreak(),
    getSessionDetails()
  ]);
}
