/* eslint-disable func-style */
import { takeEvery } from 'redux-saga/effects';
import TeamActionTypes from './teams.types';
import { commonAjaxHandler } from '../../url';

const handleListRoles = commonAjaxHandler('/teams/roles', {
  method: 'get',
  start: TeamActionTypes.LIST_ROLES_START,
  success: TeamActionTypes.LIST_ROLES_FINISHED,
  error: TeamActionTypes.LIST_ROLES_ERROR,
  payloadKeys: ['roles'],
});

const handleListPermissions = commonAjaxHandler('/teams/permissions', {
  method: 'get',
  start: TeamActionTypes.LIST_PERMISSIONS_START,
  success: TeamActionTypes.LIST_PERMISSIONS_FINISHED,
  error: TeamActionTypes.LIST_PERMISSIONS_ERROR,
  payloadKeys: ['permissions'],
});

const handleGetRole = commonAjaxHandler((p) => `/teams/role/${p.id}`, {
  method: 'get',
  start: TeamActionTypes.GET_ROLE_START,
  success: TeamActionTypes.GET_ROLE_FINISHED,
  error: TeamActionTypes.GET_ROLE_ERROR,
  payloadKeys: ['role'],
});

const handleAddRole = commonAjaxHandler('/teams/role/add', {
  method: 'post',
  start: TeamActionTypes.ADD_ROLE_START,
  success: TeamActionTypes.ADD_ROLE_FINISHED,
  error: TeamActionTypes.ADD_ROLE_ERROR,
  payloadKeys: ['role'],
});

const handleUpdateRole = commonAjaxHandler(
  (p) => `/teams/role/${p.id}/update`,
  {
    method: 'post',
    start: TeamActionTypes.UPDATE_ROLE_START,
    success: TeamActionTypes.UPDATE_ROLE_FINISHED,
    error: TeamActionTypes.UPDATE_ROLE_ERROR,
    payloadKeys: ['role'],
  }
);

const handleListTeams = commonAjaxHandler('/teams/list', {
  method: 'POST',
  start: TeamActionTypes.LIST_TEAMS_START,
  success: TeamActionTypes.LIST_TEAMS_FINISHED,
  error: TeamActionTypes.LIST_TEAMS_ERROR,
  payloadKeys: ['teams', 'teamUserRoles', 'teamUsers'],
});

const handleAddTeam = commonAjaxHandler('/teams/create', {
  method: 'POST',
  start: TeamActionTypes.ADD_TEAM_START,
  success: TeamActionTypes.ADD_TEAM_FINISHED,
  error: TeamActionTypes.ADD_TEAM_ERROR,
  payloadKeys: ['team'],
});

const handleGetTeam = commonAjaxHandler((p) => `/teams/${p.teamId}/details`, {
  method: 'GET',
  start: TeamActionTypes.GET_TEAM_START,
  success: TeamActionTypes.GET_TEAM_FINISHED,
  error: TeamActionTypes.GET_TEAM_ERROR,
  payloadKeys: [
    'team',
    'projects',
    'businessAdminUsers',
    'nonAdminUsers',
    'teamRoles',
  ],
});

const handleAddMember = commonAjaxHandler('/teams/member/add', {
  method: 'POST',
  start: TeamActionTypes.ADD_MEMBER_START,
  success: TeamActionTypes.ADD_MEMBER_FINISHED,
  error: TeamActionTypes.ADD_MEMBER_ERROR,
  payloadKeys: ['nonAdminUsers', 'newUser'],
});

const handleAddMemberToTeam = commonAjaxHandler(
  (p) => `/teams/${p.member.teamId}/member/add`,
  {
    method: 'POST',
    start: TeamActionTypes.ADD_MEMBER_TO_TEAM_START,
    success: TeamActionTypes.ADD_MEMBER_TO_TEAM_FINISHED,
    error: TeamActionTypes.ADD_MEMBER_TO_TEAM_ERROR,
    payloadKeys: [
      'team',
      'projects',
      'businessAdminUsers',
      'nonAdminUsers',
      'teamRoles',
    ],
  }
);

const handleUpdateTeamUser = commonAjaxHandler(
  (p) => `/teams/update/user`,
  {
    method: 'POST',
    start: TeamActionTypes.UPDATE_TEAM_USER_START,
    success: TeamActionTypes.UPDATE_TEAM_USER_FINISHED,
    error: TeamActionTypes.UPDATE_TEAM_USER_ERROR,
  }
);


const handleListTeamLabels = commonAjaxHandler('/teams/labels', {
  method: 'GET',
  start: TeamActionTypes.GET_TEAM_LABELS_START,
  success: TeamActionTypes.GET_TEAM_LABELS_FINISHED,
  error: TeamActionTypes.GET_TEAM_LABELS_ERROR,
  payloadKeys: ['teamLabels'],
});


export function* listTeams() {
  yield takeEvery(TeamActionTypes.LIST_TEAMS, handleListTeams);
}

export function* addTeam() {
  yield takeEvery(TeamActionTypes.ADD_TEAM, handleAddTeam);
}

export function* getTeam() {
  yield takeEvery(TeamActionTypes.GET_TEAM, handleGetTeam);
}

export function* addMember() {
  yield takeEvery(TeamActionTypes.ADD_MEMBER, handleAddMember);
}

export function* addMemberToTeam() {
  yield takeEvery(TeamActionTypes.ADD_MEMBER_TO_TEAM, handleAddMemberToTeam);
}

export function* listRoles() {
  yield takeEvery(TeamActionTypes.LIST_ROLES, handleListRoles);
}

export function* listPermissions() {
  yield takeEvery(TeamActionTypes.LIST_PERMISSIONS, handleListPermissions);
}

export function* getRole() {
  yield takeEvery(TeamActionTypes.GET_ROLE, handleGetRole);
}

export function* addRole() {
  yield takeEvery(TeamActionTypes.ADD_ROLE, handleAddRole);
}

export function* updateRole() {
  yield takeEvery(TeamActionTypes.UPDATE_ROLE, handleUpdateRole);
}

export function* updateTeamUser() {
  yield takeEvery(TeamActionTypes.UPDATE_TEAM_USER, handleUpdateTeamUser);
}

export function* getTeamLabels() {
  yield takeEvery(TeamActionTypes.GET_TEAM_LABELS, handleListTeamLabels);
}
