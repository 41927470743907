/* eslint-disable func-style */
import BusinessActionTypes from './business.types';

export function updateExternalCampaign(externalCampaignId, campaignData) {
  return {
    type: BusinessActionTypes.UPDATE_EXTERNAL_CAMPAIGN,
    payload: { externalCampaignId, campaignData },
  };
}

export function getCampaignFields(campaignId) {
  return {
    type: BusinessActionTypes.BUSINESS_GET_CAMPAIGN_FIELDS,
    payload: { campaignId },
  };
}

export function removeCampaignField(campaignId, fieldId) {
  return {
    type: BusinessActionTypes.BUSINESS_REMOVE_CAMPAIGN_FIELD,
    payload: { campaignId, fieldId },
  };
}

export function addCampaignField(campaignId, fieldData) {
  return {
    type: BusinessActionTypes.BUSINESS_ADD_CAMPAIGN_FIELD,
    payload: { campaignId, fieldData },
  };
}

export function updateCampaignField(campaignId, fieldId, fieldData) {
  return {
    type: BusinessActionTypes.BUSINESS_UPDATE_CAMPAIGN_FIELD,
    payload: { campaignId, fieldId, fieldData },
  };
}

export function getAllBusinesses() {
  return {
    type: BusinessActionTypes.GET_ALL_BUSINESSES,
    payload: {},
  };
}

export function getProductivityLogs(filters) {
  return {
    type: BusinessActionTypes.BUSINESS_GET_PRODUCTIVITY_LOGS,
    payload: filters,
  };
}

export function setBusiness(business) {
  return {
    type: BusinessActionTypes.SET_BUSINESS,
    payload: { business },
  };
}

export function saveBusiness(business) {
  return {
    type: BusinessActionTypes.BUSINESS_SAVE_INFO,
    payload: business,
  };
}

export function getBusiness() {
  return {
    type: BusinessActionTypes.BUSINESS_GET,
  };
}

export function getAgentLogins(projectId) {
  return {
    type: BusinessActionTypes.AGENTLOGINS_GET,
    payload: { projectId },
  };
}

export function getMainsummary(selectionRange) {
  return {
    type: BusinessActionTypes.MAINSUMMARY_GET,
    payload: selectionRange,
  };
}

export function getDailerSummary(projectId) {
  return {
    type: BusinessActionTypes.DAILERSUMMARY_GET,
    payload: { projectId },
  };
}

export function getSlotSummary(projectId) {
  return {
    type: BusinessActionTypes.SLOTSUMMARY_GET,
    payload: { projectId },
  };
}

export function getqualityMetrics(projectId) {
  return {
    type: BusinessActionTypes.QUALITYMATRIX_GET,
    payload: { projectId },
  };
}

export function getcSatSummary(projectId) {
  return {
    type: BusinessActionTypes.CSATSUMMARY_GET,
    payload: { projectId },
  };
}

export function getcSatDetails(projectId, segment, days) {
  return {
    type: BusinessActionTypes.CSATDETAILS_GET,
    payload: { projectId, segment, days },
  };
}

export function getrrSummary(projectId) {
  return {
    type: BusinessActionTypes.RRSUMMARY_GET,
    payload: { projectId },
  };
}

export function getrrDetails(projectId, segment, days) {
  return {
    type: BusinessActionTypes.RRDETAILS_GET,
    payload: { projectId, segment, days },
  };
}

export function getpcdSummary(projectId) {
  return {
    type: BusinessActionTypes.PCDSUMMARY_GET,
    payload: { projectId },
  };
}

export function getpcdDetails(projectId, segment, days) {
  return {
    type: BusinessActionTypes.PCDDETAILS_GET,
    payload: { projectId, segment, days },
  };
}

export function getproductivitySummary(projectId) {
  return {
    type: BusinessActionTypes.PRODUCTIVITYSUMMARY_GET,
    payload: { projectId },
  };
}

export function getproductionAgents({ projectId, startDate, endDate }) {
  return {
    type: BusinessActionTypes.PRODUCTIONAGENTS_GET,
    payload: { projectId, startDate, endDate },
  };
}

export function agentView(projectId, userId) {
  return {
    type: BusinessActionTypes.AGENTVIEW_GET,
    payload: { projectId, userId },
  };
}

export function getBusinessTransactions() {
  return {
    type: BusinessActionTypes.GET_BUSINESS_TRANSACTIONS,
  };
}

export function getBusinessWalletBalance() {
  return {
    type: BusinessActionTypes.GET_BUSINESS_WALLET,
  };
}

export function requestBusinessInvitation(query) {
  return {
    type: BusinessActionTypes.REQUEST_BUSINESS_INVITATION,
    payload: { query },
  };
}

export function businessInvitation(action, token) {
  return {
    type: BusinessActionTypes.BUSINESS_INVITATION,
    payload: { action, token },
  };
}

export function toggleAnnexure(payload) {
  return {
    type: BusinessActionTypes.TOGGLE_ANNEXURE_STATE,
    payload,
  };
}

export function getOfferLetterConfig(payload) {
  return {
    type: BusinessActionTypes.FETCH_OFFERLETTER_CONFIG,
    payload,
  };
}

export function configureProjectsForAnnexure(payload) {
  return {
    type: BusinessActionTypes.CONFIGURE_PROJECTS_FOR_ANNEXURE,
    payload,
  };
}

export function configureOfferLetter(payload) {
  return {
    type: BusinessActionTypes.CONFIGURE_OFFER_LETTER,
    payload,
  };
}

export function getCampaigns() {
  return {
    type: BusinessActionTypes.GET_CAMPAIGNS,
  };
}

export function addCampaign(payload) {
  return {
    type: BusinessActionTypes.ADD_CAMPAIGN,
    payload,
  };
}

export function setCampaignOrTeamModal(showing) {
  return {
    type: BusinessActionTypes.SET_CAMPAIGN_OR_TEAM_MODAL_SHOWING,
    payload: { showing },
  };
}

export function toggleUpdateAddressModal() {
  return {
    type: BusinessActionTypes.TOGGLE_UPDATE_ADDRESS_MODAL,
  };
}
