/* eslint-disable func-style */
export const BusinessType = {
  DEMAND: 'DEMAND',
  AGENT: 'NONE',
};

export function getGenders() {
  return [
    { id: 'male', label: 'Male' },
    { id: 'female', label: 'Female' },
  ];
}

export function getExperiences() {
  return [
    { id: 'inbound', label: 'Inbound' },
    { id: 'outbound', label: 'Outbound' },
    { id: 'email', label: 'Email' },
    { id: 'fresher', label: 'Fresher' },
    { id: 'chat', label: 'Chat' },
  ];
}

export const genders = [
  { value: 'male', label: 'Male', id: 1, name: 'male' },
  { value: 'female', label: 'Female', id: 2, name: 'female' },
  { value: 'wont_say', label: "I'd rather not say", id: 3, name: 'wont_say' },
];
