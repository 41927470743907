import TeamActionTypes from './teams.types';

const INITIAL_STATE = {
  loading: false,
  teams: null,
  teamUsers: [],
  teamLabels: null,
  teamUserRoles: null,
  listLoadError: null,
  pager: null,
  currentActiveTeam: null,
  currentTeamMembers: null,

  roles: null,
  currentRole: null,
  permissions: null,
  removeRoleError: null,
  addRoleErrors: null,
  addRoleSuccess: null,
  roleErrors: null,

  teamMemberAddError: null,
  teamMemberAddSuccess: null,

  addMemberErrors: null,
  addMemberSuccess: null,
  selectedTeam: null,
};

export default function teamReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TeamActionTypes.RESET_ROLES:
      return {
        ...state,
        loading: false,
        addRoleSuccess: null,
        addRoleErrors: null,
        removeRoleError: null,
      };
    case TeamActionTypes.ADD_ROLE_START:
      return {
        ...state,
        loading: true,
        addRoleSuccess: null,
        addRoleErrors: null,
        currentRole: null,
        addRoleSuccess: null,
      };

    case TeamActionTypes.ADD_ROLE_FINISHED:
      return {
        ...state,
        loading: false,
        addRoleSuccess: true,
      };
    case TeamActionTypes.ADD_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        addRoleErrors: action.payload,
      };
    case TeamActionTypes.LIST_ROLES_START:
      return {
        ...state,
        loading: true,
      };
    case TeamActionTypes.LIST_ROLES_FINISHED:
      return {
        ...state,
        loaing: false,
        roles: action.payload.roles,
      };
    case TeamActionTypes.LIST_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        roleErrors: action.payload,
      };

    case TeamActionTypes.GET_ROLE_START:
      return {
        ...state,
        loading: true,
        currentRole: null,
      };
    case TeamActionTypes.GET_ROLE_FINISHED:
      return {
        ...state,
        loading: false,
        currentRole: action.payload.role,
      };
    case TeamActionTypes.GET_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        roleErrors: action.payload,
      };

    case TeamActionTypes.REMOVE_ROLE_START:
      return {
        ...state,
        loading: true,
      };
    case TeamActionTypes.REMOVE_ROLE_FINISHED:
      return {
        ...state,
        loading: false,
      };
    case TeamActionTypes.REMOVE_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        removeRoleError: action.payload,
      };
    case TeamActionTypes.UPDATE_ROLE_START:
      return {
        ...state,
        loading: true,
        addRoleSuccess: null,
        addRoleErrors: null,
      };
    case TeamActionTypes.UPDATE_ROLE_FINISHED:
      return {
        ...state,
        loading: false,
        addRoleSuccess: true,
      };
    case TeamActionTypes.UPDATE_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        addRoleErrors: action.payload,
      };
    case TeamActionTypes.LIST_PERMISSIONS_START:
      return {
        ...state,
        permissions: null,
        loading: true,
      };
    case TeamActionTypes.LIST_PERMISSIONS_FINISHED:
      return {
        ...state,
        loading: false,
        permissions: action.payload.permissions,
      };
    case TeamActionTypes.LIST_PERMISSIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case TeamActionTypes.ADD_MEMBER_TO_TEAM_START:
      return {
        ...state,
        memberLoading: true,
        teamMemberAddError: null,
      };
    case TeamActionTypes.ADD_MEMBER_TO_TEAM_FINISHED: {
      const { projects, team, businessAdminUsers, nonAdminUsers, teamRoles } =
        action.payload;
      return {
        ...state,
        memberLoading: false,
        teamMemberAddSuccess: true,
        currentActiveTeam: team,
        projects,
        businessAdminUsers,
        nonAdminUsers,
        teamRoles,
      };
    }
    case TeamActionTypes.ADD_MEMBER_TO_TEAM_ERROR:
      return {
        ...state,
        memberLoading: false,
        teamMemberAddError: action.payload,
      };
    case TeamActionTypes.RESET_ADD_MEMBER:
      return {
        ...state,
        addMemberErrors: null,
        addMemberSuccess: null,
        newMember: null,
        memberLoading: false,
      };
    case TeamActionTypes.ADD_MEMBER_START:
      return {
        ...state,
        memberLoading: true,
      };

    case TeamActionTypes.ADD_MEMBER_FINISHED:
      return {
        ...state,
        memberLoading: false,
        addMemberSuccess: true,
        newMember: action.payload.newMamber,
        nonAdminUsers: action.payload.nonAdminUsers,
      };

    case TeamActionTypes.ADD_MEMBER_ERROR:
      return {
        ...state,
        memberLoading: false,
        addMemberErrors: action.payload,
      };

    case TeamActionTypes.UPDATE_TEAM_START:
    case TeamActionTypes.UPDATE_TEAM_FINISHED:
    case TeamActionTypes.UPDATE_TEAM_ERROR:
      return state;

    case TeamActionTypes.DELETE_TEAM_START:
    case TeamActionTypes.DELETE_TEAM_FINISHED:
    case TeamActionTypes.DELETE_TEAM_ERROR:
      return state;

    case TeamActionTypes.GET_TEAM_START:
      return {
        ...state,
        loading: true,
        currentActiveTeam: null,
      };
    case TeamActionTypes.GET_TEAM_FINISHED: {
      const { projects, team, businessAdminUsers, nonAdminUsers, teamRoles } =
        action.payload;
      return {
        ...state,
        loading: false,
        currentActiveTeam: team,
        projects,
        businessAdminUsers,
        nonAdminUsers,
        teamRoles,
      };
    }
    case TeamActionTypes.GET_TEAM_ERROR:
      return {
        ...state,
        currentActiveTeam: null,
        loadError: action.payload,
      };
    case TeamActionTypes.LIST_TEAMS_START:
      return {
        ...state,
        loading: true,
        teams: null,
        teamLabels: null,
        teamUsers: [],
      };
    case TeamActionTypes.LIST_TEAMS_FINISHED:
      return {
        ...state,
        loading: false,
        teams: action.payload.teams,
        teamUserRoles: action.payload.teamUserRoles,
        teamUsers: action.payload.teamUsers,
      };
    case TeamActionTypes.LIST_TEAMS_ERROR:
      return {
        ...state,
        loading: false,
        listLoadError: action.payload,
      };
    case TeamActionTypes.RESET_ADD_TEAM:
      return {
        ...state,
        loading: false,
        addError: null,
        added: null,
      };
    case TeamActionTypes.ADD_TEAM_START:
      return {
        ...state,
        addError: null,
        added: null,
      };
    case TeamActionTypes.ADD_TEAM_FINISHED:
      return {
        ...state,
        addError: false,
        added: true,
      };
    case TeamActionTypes.ADD_TEAM_ERROR:
      return {
        ...state,
        added: false,
        addError: action.payload,
      };
    case TeamActionTypes.GET_TEAM_LABELS_FINISHED:
      return {
        ...state,
        teamLabels: action.payload.teamLabels,
      };
    case TeamActionTypes.SET_SELECTED_TEAM:
      return {
        ...state,
        selectedTeam: action.payload.team,
      };
    default:
      return state;
  }
}
