import {createStore, applyMiddleware, compose} from 'redux';
import logger from 'redux-logger';

import rootSaga from './root.saga';
import rootReducer from './reducers/root.reducer';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [ sagaMiddleware ];

!window.process.env.REACT_APP_API_URL.match(/https:\/\/api.voizworks.com/) && middlewares.push(logger);

const store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

export default store;


