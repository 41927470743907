export default {
  SEARCH_JOBS: 'SEARCH_JOBS',
  SEARCH_JOBS_START: 'SEARCH_JOBS_START',
  SEARCH_JOBS_FINISHED: 'SEARCH_JOBS_FINISHED',
  SEARCH_JOBS_ERROR: 'SEARCH_JOBS_ERROR',

  APPLY_FOR_JOB: 'APPLY_FOR_JOB',
  APPLY_FOR_JOB_START: 'APPLY_FOR_JOB_START',
  APPLY_FOR_JOB_FINISHED: 'APPLY_FOR_JOB_FINISHED',
  APPLY_FOR_JOB_ERROR: 'APPLY_FOR_JOB_ERROR',

  GET_APPLICATIONS: 'GET_APPLICATIONS',
  GET_APPLICATIONS_START: 'GET_APPLICATIONS_START',
  GET_APPLICATIONS_FINISHED: 'GET_APPLICATIONS_FINISHED',
  GET_APPLICATIONS_ERROR: 'GET_APPLICATIONS_ERROR',

  GET_WORK: 'GET_WORK',
  GET_WORK_START: 'GET_WORK_START',
  GET_WORK_FINISHED: 'GET_WORK_FINISHED',
  GET_WORK_ERROR: 'GET_WORK_ERROR',

  UPDATE_WORK_REPORT_TIMER: 'UPDATE_WORK_REPORT_TIMER',
  UPDATE_WORK_REPORT_TIMER_START: 'UPDATE_WORK_REPORT_TIMER_START',
  UPDATE_WORK_REPORT_TIMER_FINISHED: 'UPDATE_WORK_REPORT_TIMER_FINISHED',
  UPDATE_WORK_REPORT_TIMER_ERROR: 'UPDATE_WORK_REPORT_TIMER_ERROR',

  SAVE_WORK_REPORT: 'SAVE_WORK_REPORT',
  SAVE_WORK_REPORT_START: 'SAVE_WORK_REPORT_START',
  SAVE_WORK_REPORT_FINISHED: 'SAVE_WORK_REPORT_FINISHED',
  SAVE_WORK_REPORT_ERROR: 'SAVE_WORK_REPORT_ERROR',

  APPLY_LEAVE: 'APPLY_LEAVE',
  APPLY_LEAVE_START: 'APPLY_LEAVE_START',
  APPLY_LEAVE_FINISHED: 'APPLY_LEAVE_FINISHED',
  APPLY_LEAVE_ERROR: 'APPLY_LEAVE_ERROR',

  GET_JOB_DETAILS: 'GET_JOB_DETAILS',
  GET_JOB_DETAILS_START: 'GET_JOB_DETAILS_START',
  GET_JOB_DETAILS_FINISHED: 'GET_JOB_DETAILS_FINISHED',
  GET_JOB_DETAILS_ERROR: 'GET_JOB_DETAILS_ERROR',
};
