import { UserActionType } from './user.types';

export function initializeChat(chats) {
  return { type: UserActionType.INITIALIZE_CHAT, payload: chats };
}

export function setUnreadChatCount(count) {
  return { type: UserActionType.UNREAD_CHAT_COUNT, payload: count };
}

export function setCurrentSelectedChat(profileId) {
  return { type: UserActionType.CURRENT_SELECTED_CHAT, payload: profileId };
}

export function setBackLink(link) {
  return { type: UserActionType.SET_BACK_LINK, payload: link };
}

export function setMessages(profileId, messages) {
  return {
    type: UserActionType.SET_MESSAGES,
    payload: { profileId, messages },
  };
}

export function appendMessage(profileId, message) {
  return {
    type: UserActionType.APPEND_MESSAGE,
    payload: { profileId, message },
  };
}

export function signupReset() {
  return { type: UserActionType.SIGNUP_RESET };
}

export function selectBusinessType(businessType) {
  return {
    type: UserActionType.SIGNUP_SELECT_BUSINESS_TYPE,
    payload: businessType,
  };
}

export function newUserSignup(user) {
  return {
    type: UserActionType.USER_SIGNUP,
    payload: user,
  };
}

export function userLogin(loginDetails) {
  return {
    type: UserActionType.USER_LOGIN,
    payload: loginDetails,
  };
}

export function loginReset() {
  return { type: UserActionType.LOGIN_RESET };
}

export function forgotPassword(forgotPasswordDetails) {
  return {
    type: UserActionType.FORGOT_PASSWORD,
    payload: forgotPasswordDetails,
  };
}

export function resetPassword(resetPassword) {
  return {
    type: UserActionType.RESET_PASSWORD,
    payload: resetPassword,
  };
}

export function changePassword(passwordObject) {
  return {
    type: UserActionType.CHANGE_PASSWORD,
    payload: passwordObject,
  };
}

export function validateResetPasswordToken(token) {
  return {
    type: UserActionType.VALIDATE_PASSWORD_RESET_TOKEN,
    payload: { token },
  };
}

export function setUser(user) {
  localStorage.setItem('user', JSON.stringify(user));
  return { type: UserActionType.USER_SET, payload: { user } };
}

export function setVoizUser(user) {
  localStorage.setItem('voizUser', JSON.stringify(user));
  return { type: UserActionType.VOIZ_USER_SET, payload: { voizUser: user } };
}

export function setUserAndToken({ user, token, voizUser }) {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('token', token);
  if (voizUser) {
    localStorage.setItem('voizUser', JSON.stringify(voizUser));
  } else {
    localStorage.removeItem('voizUser');
  }
  return {
    type: UserActionType.SET_USER_AND_TOKEN,
    payload: { user, token, voizUser },
  };
}

export function resendOtp(user, otpFor) {
  return {
    type: UserActionType.OTP_SEND,
    payload: { user, otpFor },
  };
}

export function verifyOtp(otp, otpFor) {
  return {
    type: UserActionType.OTP_VERIFY,
    payload: { otp, otpFor },
  };
}

export function signoutUser() {
  return {
    type: UserActionType.SIGNOUT,
  };
}

export function updateUser(user) {
  return {
    type: UserActionType.UPDATE_USER,
    payload: { user },
  };
}

export function deactiveUser(password) {
  return { type: UserActionType.DEACTIVATE_USER, payload: { password } };
}

export function deleteUser() {
  return { type: UserActionType.DELETE_USER };
}

export function setFeatureFlag(flag, state) {
  return {
    type: UserActionType.SET_FEATURE_FLAG,
    payload: { flag, state: state ? 'on' : 'off' },
  };
}

export function setOnboadingData(meta) {
  return {
    type: UserActionType.SET_ONBOARDING_DATA,
    payload: { meta },
  };
}

export function toggleOtpModalFor(field) {
  return {
    type: UserActionType.TOGGLE_OTP_MODAL,
    payload: { field },
  };
}

export function resendBypassOtp(userId, adminId) {
  return {
    type: UserActionType.BYPASS_OTP_SEND,
    payload: { userId, adminId },
  };
}

export function verifyBypassOtp(payload) {
  return {
    type: UserActionType.VERIFY_BYPASS_OTP,
    payload,
  };
}

export function getAdminBypassDetails(token) {
  return {
    type: UserActionType.GET_ADMINBYPASS_DETAILS,
    payload: { token },
  };
}

export function incrementUnreadChatCount(chatId) {
  return {
    type: UserActionType.INCREMENT_UNREAD_CHAT_COUNT,
    payload: { chatId },
  };
}

export function clearUnreadChatCount(chatId) {
  return {
    type: UserActionType.CLEAR_UNREAD_CHAT_COUNT,
    payload: { chatId },
  };
}

export function reportAbuse(payload) {
  return {
    type: UserActionType.REPORT_ABUSE,
    payload,
  };
}

export function addToFavorites(projectId) {
  return {
    type: UserActionType.ADD_TO_FAVORITES,
    payload: { projectId },
  };
}

export function getFavorites() {
  return {
    type: UserActionType.GET_USER_FAVORITES,
  };
}
