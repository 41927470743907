/* eslint-disable func-style */
import { takeEvery } from 'redux-saga/effects';
import { commonAjaxHandler } from '../../url';
import JobActionTypes from './jobs.types';

const handleJobSearch = commonAjaxHandler(
  (p) => (p.newView ? '/jobs' : '/jobs/search'),
  {
    method: 'POST',
    start: JobActionTypes.SEARCH_JOBS_START,
    success: JobActionTypes.SEARCH_JOBS_FINISHED,
    error: JobActionTypes.SEARCH_JOBS_ERROR,
    payloadKeys: ['jobs', 'pager'],
  }
);

const handleApplyForAJob = commonAjaxHandler(
  (p) => `/jobs/${p.projectId}/apply${p.editKey ? `?key=${p.editKey}` : ''}`,
  {
    method: 'post',
    start: JobActionTypes.APPLY_FOR_JOB_START,
    success: JobActionTypes.APPLY_FOR_JOB_FINISHED,
    error: JobActionTypes.APPLY_FOR_JOB_ERROR,
    payloadKeys: [],
  }
);

const handleGetApplications = commonAjaxHandler((p) => '/jobs/applications', {
  method: 'POST',
  start: JobActionTypes.GET_APPLICATIONS_START,
  success: JobActionTypes.GET_APPLICATIONS_FINISHED,
  error: JobActionTypes.GET_APPLICATIONS_ERROR,
  payloadKeys: [
    'applications',
    'pager',
    'statusCounts',
    'labelMapping',
    'status',
  ],
});

const handleGetWork = commonAjaxHandler((p) => '/jobs/work', {
  method: 'GET',
  start: JobActionTypes.GET_WORK_START,
  success: JobActionTypes.GET_WORK_FINISHED,
  error: JobActionTypes.GET_WORK_ERROR,
  payloadKeys: ['work'],
});

const handleUpdateWorkReportTimer = commonAjaxHandler(
  (p) => `/jobs/agentDailyReportSession/${p.applicationId}/${p.action}`,
  {
    method: 'POST',
    start: JobActionTypes.UPDATE_WORK_REPORT_TIMER_START,
    success: JobActionTypes.UPDATE_WORK_REPORT_TIMER_FINISHED,
    error: JobActionTypes.UPDATE_WORK_REPORT_TIMER_ERROR,
    payloadKeys: ['work'],
  }
);

const handleSaveWorkReport = commonAjaxHandler(
  (p) => `/jobs/workReport/${p.reportId}`,
  {
    method: 'POST',
    start: JobActionTypes.SAVE_WORK_REPORT_START,
    success: JobActionTypes.SAVE_WORK_REPORT_FINISHED,
    error: JobActionTypes.SAVE_WORK_REPORT_ERROR,
    payloadKeys: [],
  }
);

const handleApplyLeave = commonAjaxHandler(
  (p) => `/jobs/agentLeave/${p.action ? p.action : 'apply'}/${p.applicationId}`,
  {
    method: 'POST',
    start: JobActionTypes.APPLY_LEAVE_START,
    success: JobActionTypes.APPLY_LEAVE_FINISHED,
    error: JobActionTypes.APPLY_LEAVE_ERROR,
    payloadKeys: [],
  }
);

const handleGetJobDetails = commonAjaxHandler(
  (p) => `/job/${p.projectId}/details`,
  {
    method: 'GET',
    start: JobActionTypes.GET_JOB_DETAILS_START,
    success: JobActionTypes.GET_JOB_DETAILS_FINISHED,
    error: JobActionTypes.GET_JOB_DETAILS_ERROR,
    payloadKeys: ['job'],
  }
);

export function* jobSearch() {
  yield takeEvery(JobActionTypes.SEARCH_JOBS, handleJobSearch);
}

export function* applyForAJob() {
  yield takeEvery(JobActionTypes.APPLY_FOR_JOB, handleApplyForAJob);
}

export function* getApplications() {
  yield takeEvery(JobActionTypes.GET_APPLICATIONS, handleGetApplications);
}

export function* getWork() {
  yield takeEvery(JobActionTypes.GET_WORK, handleGetWork);
}

export function* updateWorkReportTimer() {
  yield takeEvery(
    JobActionTypes.UPDATE_WORK_REPORT_TIMER,
    handleUpdateWorkReportTimer
  );
}

export function* saveWorkReport() {
  yield takeEvery(JobActionTypes.SAVE_WORK_REPORT, handleSaveWorkReport);
}

export function* applyLeave() {
  yield takeEvery(JobActionTypes.APPLY_LEAVE, handleApplyLeave);
}

export function* getJobDetails() {
  yield takeEvery(JobActionTypes.GET_JOB_DETAILS, handleGetJobDetails);
}
