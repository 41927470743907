export default {
  GET_SHORT_PROFILE: 'GET_SHORT_PROFILE',
  GET_SHORT_PROFILE_START: 'GET_SHORT_PROFILE_START',
  GET_SHORT_PROFILE_FINISHED: 'GET_SHORT_PROFILE_FINISHED',
  GET_SHORT_PROFILE_ERROR: 'GET_SHORT_PROFILE_ERROR',

  GET_FULL_PROFILE: 'GET_FULL_PROFILE',
  GET_FULL_PROFILE_START: 'GET_FULL_PROFILE_START',
  GET_FULL_PROFILE_FINISHED: 'GET_FULL_PROFILE_FINISHED',
  GET_FULL_PROFILE_ERROR: 'GET_FULL_PROFILE_ERROR',

  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_PROFILE_START: 'UPDATE_PROFILE_START',
  UPDATE_PROFILE_FINISHED: 'UPDATE_PROFILE_FINISHED',
  UPDATE_PROFILE_ERROR: 'UPDATE_PROFILE_ERROR',

  GET_EXPERIENCES: 'GET_EXPERIENCES',
  GET_EXPERIENCES_START: 'GET_EXPERIENCES_START',
  GET_EXPERIENCES_FINISHED: 'GET_EXPERIENCES_FINISHED',
  GET_EXPERIENCES_ERROR: 'GET_EXPERIENCES_ERROR',

  ADD_EXPERIENCE: 'ADD_EXPERIENCE',
  ADD_EXPERIENCE_START: 'ADD_EXPERIENCE_START',
  ADD_EXPERIENCE_FINISHED: 'ADD_EXPERIENCE_FINISHED',
  ADD_EXPERIENCE_ERROR: 'ADD_EXPERIENCE_ERROR',

  GET_KYC: 'GET_KYC',
  GET_KYC_START: 'GET_KYC_START',
  GET_KYC_FINISHED: 'GET_KYC_FINISHED',
  GET_KYC_ERROR: 'GET_KYC_ERROR',

  UPDATE_KYC: 'UPDATE_KYC',
  UPDATE_KYC_START: 'UPDATE_KYC_START',
  UPDATE_KYC_FINISHED: 'UPDATE_KYC_FINISHED',
  UPDATE_KYC_ERROR: 'UPDATE_KYC_ERROR',

  GET_BANK_DETAILS: 'GET_BANK_DETAILS',
  GET_BANK_DETAILS_START: 'GET_BANK_DETAILS_START',
  GET_BANK_DETAILS_FINISHED: 'GET_BANK_DETAILS_FINISHED',
  GET_BANK_DETAILS_ERROR: 'GET_BANK_DETAILS_ERROR',

  UPDATE_BANK_DETAILS: 'UPDATE_BANK_DETAILS',
  UPDATE_BANK_DETAILS_START: 'UPDATE_BANK_DETAILS_START',
  UPDATE_BANK_DETAILS_FINISHED: 'UPDATE_BANK_DETAILS_FINISHED',
  UPDATE_BANK_DETAILS_ERROR: 'UPDATE_BANK_DETAILS_ERROR',

  SET_PROFILE_HIDDEN: 'SET_PROFILE_HIDDEN',
  SET_PROFILE_HIDDEN_START: 'SET_PROFILE_HIDDEN_START',
  SET_PROFILE_HIDDEN_FINISHED: 'SET_PROFILE_HIDDEN_FINISHED',
  SET_PROFILE_HIDDEN_ERROR: 'SET_PROFILE_HIDDEN_ERROR',

  GET_CERTIFICATIONS: 'GET_CERTIFICATIONS',
  GET_CERTIFICATIONS_START: 'GET_CERTIFICATIONS_START',
  GET_CERTIFICATIONS_FINISHED: 'GET_CERTIFICATIONS_FINISHED',
  GET_CERTIFICATIONS_ERROR: 'GET_CERTIFICATIONS_ERROR',

  ADD_CERTIFICATION: 'ADD_CERTIFICATION',
  ADD_CERTIFICATION_START: 'ADD_CERTIFICATION_START',
  ADD_CERTIFICATION_FINISHED: 'ADD_CERTIFICATION_FINISHED',
  ADD_CERTIFICATION_ERROR: 'ADD_CERTIFICATION_ERROR',

  REMOVE_OR_UPDATE_CERTIFICATION: 'REMOVE_OR_UPDATE_CERTIFICATION',
  REMOVE_OR_UPDATE_CERTIFICATION_START: 'REMOVE_OR_UPDATE_CERTIFICATION_START',
  REMOVE_OR_UPDATE_CERTIFICATION_FINISHED:
    'REMOVE_OR_UPDATE_CERTIFICATION_FINISHED',
  REMOVE_OR_UPDATE_CERTIFICATION_ERROR: 'REMOVE_OR_UPDATE_CERTIFICATION_ERROR',

  GET_QUALIFICATIONS: 'GET_QUALIFICATIONS',
  GET_QUALIFICATIONS_START: 'GET_QUALIFICATIONS_START',
  GET_QUALIFICATIONS_FINISHED: 'GET_QUALIFICATIONS_FINISHED',
  GET_QUALIFICATIONS_ERROR: 'GET_QUALIFICATIONS_ERROR',

  ADD_QUALIFICATION: 'ADD_QUALIFICATION',
  ADD_QUALIFICATION_START: 'ADD_QUALIFICATION_START',
  ADD_QUALIFICATION_FINISHED: 'ADD_QUALIFICATION_FINISHED',
  ADD_QUALIFICATION_ERROR: 'ADD_QUALIFICATION_ERROR',

  REMOVE_OR_UPDATE_QUALIFICATION: 'REMOVE_OR_UPDATE_QUALIFICATION',
  REMOVE_OR_UPDATE_QUALIFICATION_START: 'REMOVE_OR_UPDATE_QUALIFICATION_START',
  REMOVE_OR_UPDATE_QUALIFICATION_FINISHED:
    'REMOVE_OR_UPDATE_QUALIFICATION_FINISHED',
  REMOVE_OR_UPDATE_QUALIFICATION_ERROR: 'REMOVE_OR_UPDATE_QUALIFICATION_ERROR',

  REMOVE_OR_UPDATE_EXPERIENCE: 'REMOVE_OR_UPDATE_EXPERIENCE',
  REMOVE_OR_UPDATE_EXPERIENCE_START: 'REMOVE_OR_UPDATE_EXPERIENCE_START',
  REMOVE_OR_UPDATE_EXPERIENCE_FINISHED: 'REMOVE_OR_UPDATE_EXPERIENCE_FINISHED',
  REMOVE_OR_UPDATE_EXPERIENCE_ERROR: 'REMOVE_OR_UPDATE_EXPERIENCE_ERROR',

  ADD_EXP_JOBFUNCTIONS: 'ADD_EXP_JOBFUNCTIONS',
  ADD_EXP_JOBFUNCTIONS_START: 'ADD_EXP_JOBFUNCTIONS_START',
  ADD_EXP_JOBFUNCTIONS_FINISHED: 'ADD_EXP_JOBFUNCTIONS_FINISHED',
  ADD_EXP_JOBFUNCTIONS_ERROR: 'ADD_EXP_JOBFUNCTIONS_ERROR',

  GET_DOCUMENTS: 'GET_DOCUMENTS',
  GET_DOCUMENTS_START: 'GET_DOCUMENTS_START',
  GET_DOCUMENTS_FINISHED: 'GET_DOCUMENTS_FINISHED',
  GET_DOCUMENTS_ERROR: 'GET_DOCUMENTS_ERROR',

  GET_KYC_DETAILS: 'GET_KYC_DETAILS',
  GET_KYC_DETAILS_START: 'GET_KYC_DETAILS_START',
  GET_KYC_DETAILS_FINISHED: 'GET_KYC_DETAILS_FINISHED',
  GET_KYC_DETAILS_ERROR: 'GET_KYC_DETAILS_ERROR',

  SAVE_KYC_DETAILS: 'SAVE_KYC_DETAILS',
  SAVE_KYC_DETAILS_START: 'SAVE_KYC_DETAILS_START',
  SAVE_KYC_DETAILS_FINISHED: 'SAVE_KYC_DETAILS_FINISHED',
  SAVE_KYC_DETAILS_ERROR: 'SAVE_KYC_DETAILS_ERROR',

  GET_NOTIFICATION_PREFERENCES: 'GET_NOTIFICATION_PREFERENCES',
  GET_NOTIFICATION_PREFERENCES_START: 'GET_NOTIFICATION_PREFERENCES_START',
  GET_NOTIFICATION_PREFERENCES_FINISHED:
    'GET_NOTIFICATION_PREFERENCES_FINISHED',
  GET_NOTIFICATION_PREFERENCES_ERROR: 'GET_NOTIFICATION_PREFERENCES_ERROR',

  TOGGLE_NOTIFICATION_PREFERENCE: 'TOGGLE_NOTIFICATION_PREFERENCE',
  TOGGLE_NOTIFICATION_PREFERENCE_START: 'TOGGLE_NOTIFICATION_PREFERENCE_START',
  TOGGLE_NOTIFICATION_PREFERENCE_FINISHED:
    'TOGGLE_NOTIFICATION_PREFERENCE_FINISHED',
  TOGGLE_NOTIFICATION_PREFERENCE_ERROR: 'TOGGLE_NOTIFICATION_PREFERENCE_ERROR',

  GET_JOBFUNCTION_PRIORITIES: 'GET_JOBFUNCTION_PRIORITIES',
  GET_JOBFUNCTION_PRIORITIES_START: 'GET_JOBFUNCTION_PRIORITIES_START',
  GET_JOBFUNCTION_PRIORITIES_FINISHED: 'GET_JOBFUNCTION_PRIORITIES_FINISHED',
  GET_JOBFUNCTION_PRIORITIES_ERROR: 'GET_JOBFUNCTION_PRIORITIES_ERROR',

  MANAGE_JOBFUNCTION_PRIORITY: 'MANAGE_JOBFUNCTION_PRIORITY',
  MANAGE_JOBFUNCTION_PRIORITY_START: 'MANAGE_JOBFUNCTION_PRIORITY_START',
  MANAGE_JOBFUNCTION_PRIORITY_FINISHED: 'MANAGE_JOBFUNCTION_PRIORITY_FINISHED',
  MANAGE_JOBFUNCTION_PRIORITY_ERROR: 'MANAGE_JOBFUNCTION_PRIORITY_ERROR',

  SET_AADHAAR_PAN_MODE: 'SET_AADHAAR_PAN_MODE',

  SET_KYC_META: 'SET_KYC_META',
  SET_KYC: 'SET_KYC',

  GET_VOIZ_RESUME: 'GET_VOIZ_RESUME',
  GET_VOIZ_RESUME_START: 'GET_VOIZ_RESUME_START',
  GET_VOIZ_RESUME_FINISHED: 'GET_VOIZ_RESUME_FINISHED',
  GET_VOIZ_RESUME_ERROR: 'GET_VOIZ_RESUME_ERROR',

  GENERATE_PROFILE_VIDEO: 'GENERATE_PROFILE_VIDEO',
  GENERATE_PROFILE_VIDEO_START: 'GENERATE_PROFILE_VIDEO_START',
  GENERATE_PROFILE_VIDEO_FINISHED: 'GENERATE_PROFILE_VIDEO_FINISHED',
  GENERATE_PROFILE_VIDEO_ERROR: 'GENERATE_PROFILE_VIDEO_ERROR',

  GENERATE_PROFILE_AUDIO: 'GENERATE_PROFILE_AUDIO',
  GENERATE_PROFILE_AUDIO_START: 'GENERATE_PROFILE_AUDIO_START',
  GENERATE_PROFILE_AUDIO_FINISHED: 'GENERATE_PROFILE_AUDIO_FINISHED',
  GENERATE_PROFILE_AUDIO_ERROR: 'GENERATE_PROFILE_AUDIO_ERROR',

  GET_MOCK_CALL_SCENARIOS: 'GET_MOCK_CALL_SCENARIOS',
  GET_MOCK_CALL_SCENARIOS_START: 'GET_MOCK_CALL_SCENARIOS_START',
  GET_MOCK_CALL_SCENARIOS_FINISHED: 'GET_MOCK_CALL_SCENARIOS_FINISHED',
  GET_MOCK_CALL_SCENARIOS_ERROR: 'GET_MOCK_CALL_SCENARIOS_ERROR',

  GET_MOCK_CALL_SET: 'GET_MOCK_CALL_SET',
  GET_MOCK_CALL_SET_START: 'GET_MOCK_CALL_SET_START',
  GET_MOCK_CALL_SET_FINISHED: 'GET_MOCK_CALL_SET_FINISHED',
  GET_MOCK_CALL_SET_ERROR: 'GET_MOCK_CALL_SET_ERROR',

  SET_MOCK_CALL_META: 'SET_MOCK_CALL_META',
};
