export default {
    SEARCH_CANDIDATES: 'SEARCH_CANDIDATES',
    SEARCH_CANDIDATES_FETCH_START: 'SEARCH_CANDIDATES_FETCH_START',
    SEARCH_CANDIDATES_FETCH_FINISHED: 'SEARCH_CANDIDATES_FETCH_FINISHED',
    SEARCH_CANDIDATES_FETCH_ERROR: 'SEARCH_CANDIDATES_FETCH_ERROR',

    INVITE_CANDIDATE: 'INVITE_CANDIDATE',
    INVITE_CANDIDATE_START: 'INVITE_CANDIDATE_START',
    INVITE_CANDIDATE_FINISHED: 'INVITE_CANDIDATE_FINISHED',
    INVITE_CANDIDATE_ERROR: 'INVITE_CANDIDATE_ERROR',
}