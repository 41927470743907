export default {
  REMOVE_APPLICANT: 'REMOVE_APPLICANT',
  MOVE_APPLICANT_TO_REJECTED: 'MOVE_APPLICANT_TO_REJECTED',
  INCREMENT_UNREAD_CHAT_COUNT: 'PROJECT_INCREMENT_UNREAD_CHAT_COUNT',
  CLEAR_UNREAD_CHAT_COUNT: 'PROJECT_CLEAR_UNREAD_CHAT_COUNT',

  SET_PROJECT_DETAILS: 'SET_PROJECT_DETAILS',
  CLEAR_CREATE_PROJECT: 'CLEAR_CREATE_PROJECT',

  CREATE_PROJECT: 'CREATE_PROJECT',
  CREATE_PROJECT_START: 'CREATE_PROJECT_START',
  CREATE_PROJECT_FINISHED: 'CREATE_PROJECT_FINISHED',
  CREATE_PROJECT_ERROR: 'CREATE_PROJECT_ERROR',

  LIST_PROJECTS: 'LIST_PROJECTS',
  LIST_PROJECTS_START: 'LIST_PROJECTS_START',
  LIST_PROJECTS_FINISHED: 'LIST_PROJECTS_FINISHED',
  LIST_PROJECTS_ERROR: 'LIST_PROJECTS_ERROR',

  PROJECT_DETAIL: 'PROJECT_DETAIL',
  PROJECT_DETAIL_CLEAR: 'PROJECT_DETAIL_CLEAR',
  PROJECT_DETAIL_START: 'PROJECT_DETAIL_START',
  PROJECT_DETAIL_FINISHED: 'PROJECT_DETAIL_FINISHED',
  PROJECT_DETAIL_ERROR: 'PROJECT_DETAIL_ERROR',

  PROJECT_APPLICANTS: 'PROJECT_APPLICANTS',
  PROJECT_APPLICANTS_CLEAR: 'PROJECT_APPLICANTS_CLEAR',
  PROJECT_APPLICANTS_START: 'PROJECT_APPLICANTS_START',
  PROJECT_APPLICANTS_FINISHED: 'PROJECT_APPLICANTS_FINISHED',
  PROJECT_APPLICANTS_ERROR: 'PROJECT_APPLICANTS_ERROR',

  PROJECT_APPLICANTS_V2: 'PROJECT_APPLICANTS_V2',
  PROJECT_APPLICANTS_CLEAR_V2: 'PROJECT_APPLICANTS_CLEAR_V2',
  PROJECT_APPLICANTS_START_V2: 'PROJECT_APPLICANTS_START_V2',
  PROJECT_APPLICANTS_FINISHED_V2: 'PROJECT_APPLICANTS_FINISHED_V2',
  PROJECT_APPLICANTS_ERROR_V2: 'PROJECT_APPLICANTS_ERROR_V2',

  PROJECT_APPLICANTS_SUBSTAT: 'PROJECT_APPLICANTS_SUBSTAT',
  PROJECT_APPLICANTS_SUBSTAT_CLEAR: 'PROJECT_APPLICANTS_SUBSTAT_CLEAR',
  PROJECT_APPLICANTS_SUBSTAT_START: 'PROJECT_APPLICANTS_SUBSTAT_START',
  PROJECT_APPLICANTS_SUBSTAT_FINISHED: 'PROJECT_APPLICANTS_SUBSTAT_FINISHED',
  PROJECT_APPLICANTS_SUBSTAT_ERROR: 'PROJECT_APPLICANTS_SUBSTAT_ERROR',

  PROJECT_INTEGRATIONS: 'PROJECT_INTEGRATIONS',
  PROJECT_INTEGRATIONS_START: 'PROJECT_INTEGRATIONS_START',
  PROJECT_INTEGRATIONS_FINISHED: 'PROJECT_INTEGRATIONS_FINISHED',
  PROJECT_INTEGRATIONS_ERROR: 'PROJECT_INTEGRATIONS_ERROR',

  PROJECT_UPDATE_INTEGRATION: 'PROJECT_UPDATE_INTEGRATION',
  PROJECT_UPDATE_INTEGRATION_START: 'PROJECT_UPDATE_INTEGRATION_START',
  PROJECT_UPDATE_INTEGRATION_FINISHED: 'PROJECT_UPDATE_INTEGRATION_FINISHED',
  PROJECT_UPDATE_INTEGRATION_ERROR: 'PROJECT_UPDATE_INTEGRATION_ERROR',

  FREEZE_PROJECT_TIMESLOT: 'FREEZE_PROJECT_TIMESLOT',
  FREEZE_PROJECT_TIMESLOT_START: 'FREEZE_PROJECT_TIMESLOT_START',
  FREEZE_PROJECT_TIMESLOT_FINISHED: 'FREEZE_PROJECT_TIMESLOT_FINISHED',
  FREEZE_PROJECT_TIMESLOT_ERROR: 'FREEZE_PROJECT_TIMESLOT_ERROR',

  UNFREEZE_PROJECT_TIMESLOT: 'UNFREEZE_PROJECT_TIMESLOT',
  UNFREEZE_PROJECT_TIMESLOT_START: 'UNFREEZE_PROJECT_TIMESLOT_START',
  UNFREEZE_PROJECT_TIMESLOT_FINISHED: 'UNFREEZE_PROJECT_TIMESLOT_FINISHED',
  UNFREEZE_PROJECT_TIMESLOT_ERROR: 'UNFREEZE_PROJECT_TIMESLOT_ERROR',

  UPDATE_PROJECT: 'UPDATE_PROJECT',
  UPDATE_PROJECT_START: 'UPDATE_PROJECT_START',
  UPDATE_PROJECT_FINISHED: 'UPDATE_PROJECT_FINISHED',
  UPDATE_PROJECT_ERROR: 'UPDATE_PROJECT_ERROR',

  DELETE_PROJECT: 'DELETE_PROJECT',
  DELETE_PROJECT_START: 'DELETE_PROJECT_START',
  DELETE_PROJECT_FINISHED: 'DELETE_PROJECT_FINISHED',
  DELETE_PROJECT_ERROR: 'DELETE_PROJECT_ERROR',

  APPROVE_WORK_REPORT: 'APPROVE_WORK_REPORT',
  APPROVE_WORK_REPORT_START: 'APPROVE_WORK_REPORT_START',
  APPROVE_WORK_REPORT_FINISHED: 'APPROVE_WORK_REPORT_FINISHED',
  APPROVE_WORK_REPORT_ERROR: 'APPROVE_WORK_REPORT_ERROR',

  CLEAR_PAGER: 'CLEAR_PAGER',

  ADD_CRM: 'ADD_CRM',
  ADD_CRM_START: 'ADD_CRM_START',
  ADD_CRM_FINISHED: 'ADD_CRM_FINISHED',
  ADD_CRM_ERROR: 'ADD_CRM_ERROR',

  CONFIG_OFFERLETTER: 'CONFIG_OFFERLETTER',
  CONFIG_OFFERLETTER_START: 'CONFIG_OFFERLETTER_START',
  CONFIG_OFFERLETTER_FINISHED: 'CONFIG_OFFERLETTER_FINISHED',
  CONFIG_OFFERLETTER_ERROR: 'CONFIG_OFFERLETTER_ERROR',

  FETCH_OFFERLETTER_CONFIG: 'FETCH_OFFERLETTER_CONFIG',
  FETCH_OFFERLETTER_CONFIG_START: 'FETCH_OFFERLETTER_CONFIG_START',
  FETCH_OFFERLETTER_CONFIG_FINISHED: 'FETCH_OFFERLETTER_CONFIG_FINISHED',
  FETCH_OFFERLETTER_CONFIG_ERROR: 'FETCH_OFFERLETTER_CONFIG_ERROR',

  TOGGLE_ANNEXURE_STATE: 'TOGGLE_ANNEXURE_STATE',
  TOGGLE_ANNEXURE_STATE_START: 'TOGGLE_ANNEXURE_STATE_START',
  TOGGLE_ANNEXURE_STATE_FINISHED: 'TOGGLE_ANNEXURE_STATE_FINISHED',
  TOGGLE_ANNEXURE_STATE_ERROR: 'TOGGLE_ANNEXURE_STATE_ERROR',

  GET_PRICING_ESTIMATE: 'GET_PRICING_ESTIMATE',
  GET_PRICING_ESTIMATE_START: 'GET_PRICING_ESTIMATE_START',
  GET_PRICING_ESTIMATE_FINISHED: 'GET_PRICING_ESTIMATE_FINISHED',
  GET_PRICING_ESTIMATE_ERROR: 'GET_PRICING_ESTIMATE_ERROR',

  SET_WINDOW_LOCATION: 'SET_WINDOW_LOCATION',
};
