import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setWindowLocation } from '../redux/reducers/assessments/assessments.actions';

export const ModalStyling = {
  overlay: {
    width: '100%',
    height: '100%',
    background: 'transparent',
    position: 'fixed',
    zIndex: 2000,
    display: 'flex',
  },
  content: {
    padding: '0px',

    width: '980px',
    margin: '0 auto',
    height: 'fit-content',
    background: 'white',
    border: 'none',
  },
};

export const getBgColorFor4Cs = (status, score) => {
  let color = 'expert-bg';

  if (status === 'languages') {
    if (score === 'Novice') {
      color = 'novice-bg';
    }

    if (score === 'Intermediate') {
      color = 'intermediate-bg';
    }

    if (score === 'Advanced') {
      color = 'intermediate-bg';
    }

    return color;
  }

  if (status === 'Communication') {
    if (score < 10) {
      color = 'novice-bg';
    }

    if (score === 10) {
      color = 'intermediate-bg';
    }
    return color;
  }

  if (status === 'overall') {
    if (score < 20) {
      color = 'novice-bg';
    }

    if (score === 20) {
      color = 'intermediate-bg';
    }
    return color;
  }

  if (status === 'Compassion' || status === 'Composure') {
    if (score <= 3) {
      color = 'novice-bg';
    }

    if (score === 4) {
      color = 'intermediate-bg';
    }
    return color;
  }

  if (status === 'Customer Sentrity') {
    if (score === 2) {
      color = 'intermediate-bg';
    }
    if (score < 2) {
      color = 'novice-bg';
    }
    return color;
  }
};

export const uncheckedIcon = (
  <svg
    viewBox="-2 -5 14 20"
    height="100%"
    width="100%"
    style={{ position: 'absolute', top: 0 }}
  >
    <path
      d="M9.9 2.12L7.78 0 4.95 2.828 2.12 0 0 2.12l2.83 2.83L0 7.776 2.123 9.9 4.95 7.07 7.78 9.9 9.9 7.776 7.072 4.95 9.9 2.12"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export const checkedIcon = (
  <svg
    height="100%"
    width="100%"
    viewBox="-2 -5 17 21"
    style={{ position: 'absolute', top: 0 }}
  >
    <path
      d="M11.264 0L5.26 6.004 2.103 2.847 0 4.95l5.26 5.26 8.108-8.107L11.264 0"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export const CommonLogo = ({ margin = 4, isCommon = true }) => {
  const dispatch = useDispatch();
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setWindowLocation('/'));
      }}
    >
      <img
        src={
          isCommon
            ? 'https://www.voizworks.com/images/logo.svg'
            : require('../assets/logo-white.png')
        }
        alt=""
        width={115}
        className={`m-${margin} logo`}
      />
    </Link>
  );
};

export const ToastIcon = ({ type }) => {
  if (type === 'error') {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 12C22 17.5228 17.5228 22 12 22H2.9937C2.11018 22 1.66771 20.9229 2.29245 20.2929L4.2495 18.3195C2.84334 16.597 2 14.397 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.2071 8.79289C15.5976 9.18342 15.5976 9.81658 15.2071 10.2071L13.4142 12L15.2071 13.7929C15.5976 14.1834 15.5976 14.8166 15.2071 15.2071C14.8166 15.5976 14.1834 15.5976 13.7929 15.2071L12 13.4142L10.2071 15.2071C9.81658 15.5976 9.18342 15.5976 8.79289 15.2071C8.40237 14.8166 8.40237 14.1834 8.79289 13.7929L10.5858 12L8.79289 10.2071C8.40237 9.81658 8.40237 9.18342 8.79289 8.79289C9.18342 8.40237 9.81658 8.40237 10.2071 8.79289L12 10.5858L13.7929 8.79289C14.1834 8.40237 14.8166 8.40237 15.2071 8.79289Z"
          fill="#FF507A"
        />
      </svg>
    );
  }

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20H0.993697C0.110179 20 -0.332289 18.9229 0.292453 18.2929L2.2495 16.3195C0.843343 14.597 1.21409e-08 12.397 1.21409e-08 10C1.21409e-08 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.48043 12.8946 8.73478 13 9 13C9.26522 13 9.51957 12.8946 9.70711 12.7071L13.7071 8.70711Z"
        fill="#31C440"
      />
    </svg>
  );
};
