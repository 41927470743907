import UploadTypes from './uploads.types';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { commonAjaxHandler } from '../../url';

const handleGetKycDocuments = commonAjaxHandler('/document/KycDocuments', {
  method: 'GET',
  start: UploadTypes.GET_KYC_DOCUMENTS_ITEM_START,
  success: UploadTypes.GET_KYC_DOCUMENTS_ITEM_FINISHED,
  error: UploadTypes.GET_KYC_DOCUMENTS_ITEM_ERROR,
  payloadKeys: ['documents', 'hadUploadedAllKycDocuments'],
});

const handleGetProjectDocuments = commonAjaxHandler(
  (p) => `/document/project/${p.projectId}/documents`,
  {
    method: 'GET',
    start: UploadTypes.GET_PROJECT_UPLOAD_ITEMS_START,
    success: UploadTypes.GET_PROJECT_UPLOAD_ITEMS_FINISHED,
    error: UploadTypes.GET_PROJECT_UPLOAD_ITEMS_ERROR,
    payloadKeys: ['documents'],
  }
);

export function* getKycDocuments() {
  yield takeLatest(UploadTypes.GET_KYC_DOCUMENTS_ITEM, handleGetKycDocuments);
}

export function* getProjectDocuments() {
  yield takeLatest(
    UploadTypes.GET_PROJECT_UPLOAD_ITEMS,
    handleGetProjectDocuments
  );
}
