import ActionTypes from "./applicants.types";
import { takeLatest, call, put } from "redux-saga/effects";
import { urlFor, fetch, commonAjaxHandler } from "../../url";

const handleFetchApplicantSearch = commonAjaxHandler(
  (p) => {
    return `/project/${p.projectId}/applicants`;
  },
  {
    method: "POST",
    start: ActionTypes.SEARCH_APPLICANTS_FETCH_START,
    success: ActionTypes.SEARCH_APPLICANTS_FETCH_FINISHED,
    error: ActionTypes.SEARCH_APPLICANTS_FETCH_ERROR,
    payloadKeys: ["applicants", "alreadyApplied", "pager"],
  }
);

export function* applicantSearch() {
  yield takeLatest(ActionTypes.SEARCH_APPLICANTS, handleFetchApplicantSearch);
}
