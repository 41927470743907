/* eslint-disable default-param-last */
import KycActionTypes from './kyc.types';

const INITIAL_STATE = {
  aadhaar: {
    loading: false,
    session: null,
    errors: null,
    step: 0,
    failed: false,
  },
};

export default function KycReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case KycActionTypes.GET_AADHAAR_SESSION_STARTED:
      return {
        ...state,
        aadhaar: {
          ...state.aadhaar,
          errors: null,
          loading: true,
        },
      };
    case KycActionTypes.GET_AADHAAR_SESSION_FINISHED:
      return {
        ...state,
        aadhaar: {
          ...state.aadhaar,
          loading: false,
          session: action.payload.session,
          step: 1,
        },
      };
    case KycActionTypes.GET_AADHAAR_SESSION_ERROR:
      return {
        ...state,
        aadhaar: {
          ...state.aadhaar,
          errors: action.payload,
          loading: false,
          session: null,
        },
      };

    case KycActionTypes.VERIFY_AADHAAR_OTP_STARTED:
      return {
        ...state,
        aadhaar: {
          ...state.aadhaar,
          otpSent: false,
          errors: null,
          loading: true,
        },
      };
    case KycActionTypes.VERIFY_AADHAAR_OTP_FINISHED:
      return {
        ...state,
        aadhaar: {
          ...state.aadhaar,
          loading: false,
          otpSent: true,
          verified: true,
          step: 1,
        },
      };
    case KycActionTypes.VERIFY_AADHAAR_OTP_ERROR:
      return {
        ...state,
        aadhaar: {
          ...state.aadhaar,
          loading: false,
          errors: action.payload,
        },
      };

    default:
      return state;
  }
}
