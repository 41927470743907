import { serverUrlFor } from '../../url';
import ProfileActionTypes from './profile.types';

const INITIAL_STATE = {
  currentShortProfile: null,
  shortProfileFetchError: null,

  currentFullProfile: null,
  fullProfileFetchError: null,
  currentResume: null,

  experiences: [],
  qualifications: [],
  certifications: [],
  notificationPreferences: [],
  experiencesLoading: false,
  qualificationsLoading: false,
  certificationsLoading: false,
  notificationPreferencesLoading: false,
  jobFunctionPrioritiesLoading: false,
  jobPreferences: [],
  allJobFunctions: [],

  documentsLoading: false,
  qualificationAction: {
    errors: null,
    success: null,
  },
  certificationAction: {
    errors: null,
    success: null,
  },

  kyc: {
    aadhaarPanMode: 'PREVIEW',
    meta: {
      step: 0,
      subStep: 0,
      errors: null,
    },
    saving: false,
  },

  kycError: null,

  bankAccountDetails: {},
  bankAccountDetailErrors: null,
  updateProfileErrors: null,
  documents: [],
  renderedVideo: { loading: false, path: null },
  loading: false,
  mockCall: { loading: false, list: [], scenarios: [] },
};

export default function profileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ProfileActionTypes.SET_PREFERENCES_START:
      return {
        ...state,
        loading: true,
      };
    case ProfileActionTypes.SET_PREFERENCES_FINISHED:
      const { user } = action.payload;
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        user,
        preferences: action.payload.preferences,
      };

    case ProfileActionTypes.SET_PREFERENCES_ERROR:
      return {
        ...state,
        loading: false,
        preferenceSaveError: action.payload,
      };

    case ProfileActionTypes.ADD_EXPERIENCE_START:
      return {
        ...state,
        loading: true,
        experienceAction: {
          errors: null,
          success: null,
        },
      };

    case ProfileActionTypes.ADD_EXPERIENCE_FINISHED:
      return {
        ...state,
        loading: false,
        experienceAction: {
          errors: null,
          success: true,
        },
      };

    case ProfileActionTypes.ADD_EXPERIENCE_ERROR:
      return {
        ...state,
        loading: false,
        experienceAction: {
          success: false,
          errors: action.payload,
        },
      };

    case ProfileActionTypes.GET_EXPERIENCES_START:
      return {
        ...state,
        experiencesLoading: true,
        experiences: [],
      };
    case ProfileActionTypes.GET_EXPERIENCES_FINISHED:
      return {
        ...state,
        experiencesLoading: false,
        experiences: action.payload.experiences,
      };
    case ProfileActionTypes.GET_EXPERIENCES_ERROR:
      return {
        ...state,
        experiencesLoading: false,
        experiences: [],
      };
    case ProfileActionTypes.GET_SHORT_PROFILE_START:
      return {
        ...state,
        loading: true,
        currentShortProfile: {},
        shortProfileFetchError: null,
      };
    case ProfileActionTypes.GET_SHORT_PROFILE_FINISHED:
      let data = {
        ...state,
        loading: false,
        currentShortProfile: action.payload.profile,
        shortProfileFetchError: null,
      };
      if (action.payload.certifications) {
        data.certifications = action.payload.certifications;
      }

      if (action.payload.experiences) {
        data.experiences = action.payload.experiences;
      }

      if (action.payload.qualifications) {
        data.qualifications = action.payload.qualifications;
      }
      return data;
    case ProfileActionTypes.GET_SHORT_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        currentShortProfile: {},
        shortProfileFetchError: action.payload,
      };
    case ProfileActionTypes.GET_FULL_PROFILE_START:
      return {
        ...state,
        loading: true,
        currentFullProfile: null,
        fullProfileFetchError: null,
      };
    case ProfileActionTypes.GET_FULL_PROFILE_FINISHED:
      return {
        ...state,
        loading: false,
        currentFullProfile: action.payload.profile,
        fullProfileFetchError: null,
      };
    case ProfileActionTypes.GET_FULL_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        currentFullProfile: null,
        fullProfileFetchError: action.payload,
      };
    case ProfileActionTypes.GET_BANK_DETAILS_START:
      return {
        ...state,
        loading: true,
        bankAccountDetails: null,
        bankAccountDetailErrors: null,
      };
    case ProfileActionTypes.GET_BANK_DETAILS_FINISHED:
      return {
        ...state,
        loading: false,
        bankAccountDetails: action.payload.bank,
        bankAccountDetailErrors: null,
      };

    case ProfileActionTypes.GET_BANK_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        bankAccountDetails: null,
        bankAccountDetailErrors: action.payload.errors,
      };
    case ProfileActionTypes.GET_KYC_START:
    case ProfileActionTypes.GET_KYC_DETAILS_START:
      return {
        ...state,
        loading: true,
        kyc: {
          ...state.kyc,
          loading: true,
        },
      };
    case ProfileActionTypes.GET_KYC_FINISHED:
    case ProfileActionTypes.GET_KYC_DETAILS_FINISHED:
      const kyc = action.payload.kyc || action.payload.details || {};
      return {
        ...state,
        loading: false,
        kyc: {
          ...state.kyc,
          loading: false,
          ...kyc,
        },
      };

    case ProfileActionTypes.GET_KYC_ERROR:
      return {
        ...state,
        loading: false,
        kyc: {
          ...state.kyc,
          loading: false,
        },
        kycError: action.payload,
      };
    case ProfileActionTypes.UPDATE_PROFILE_START:
      return {
        ...state,
        loading: action.payload.upSection !== 'SUMMARY',
      };
    case ProfileActionTypes.UPDATE_PROFILE_FINISHED:
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        currentFullProfile: action.payload.profile,
        currentShortProfile: action.payload.profile,
        user: action.payload.user,
      };
    case ProfileActionTypes.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        updateProfileErrors: action.payload,
      };

    case ProfileActionTypes.UPDATE_BANK_DETAILS_START:
      return {
        ...state,
        loading: true,
        bankAccountDetailErrors: null,
      };
    case ProfileActionTypes.UPDATE_BANK_DETAILS_FINISHED:
      return {
        ...state,
        loading: false,
        bankAccountDetails: action.payload.bank,
        user: action.payload.user,
        bankAccountDetailErrors: null,
      };
    case ProfileActionTypes.UPDATE_BANK_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        bankAccountDetailErrors: action.payload,
      };

    case ProfileActionTypes.UPDATE_KYC_START:
      return {
        ...state,
        loading: false,
        kycError: action.payload,
      };
    case ProfileActionTypes.UPDATE_KYC_FINISHED:
      return {
        ...state,
        loading: false,
        kycError: action.payload,
      };
    case ProfileActionTypes.UPDATE_KYC_ERROR:
      return {
        ...state,
        loading: false,
        kycError: action.payload,
      };
    case ProfileActionTypes.GET_QUALIFICATIONS_START:
      return {
        ...state,
        qualificationsLoading: true,
        qualifications: [],
      };
    case ProfileActionTypes.GET_QUALIFICATIONS_FINISHED:
      return {
        ...state,
        qualificationsLoading: false,
        qualifications: action.payload.education,
      };
    case ProfileActionTypes.GET_QUALIFICATIONS_ERROR:
      return {
        ...state,
        qualificationsLoading: false,
        qualifications: [],
      };
    case ProfileActionTypes.GET_CERTIFICATIONS_START:
      return {
        ...state,
        certificationsLoading: true,
        certifications: [],
      };
    case ProfileActionTypes.GET_CERTIFICATIONS_FINISHED:
      return {
        ...state,
        certificationsLoading: false,
        certifications: action.payload.certifications,
      };
    case ProfileActionTypes.GET_CERTIFICATIONS_ERROR:
      return {
        ...state,
        certificationsLoading: false,
        certifications: [],
      };
    case ProfileActionTypes.GET_DOCUMENTS_START:
      return {
        ...state,
        documentsLoading: true,
        document: [],
      };
    case ProfileActionTypes.GET_DOCUMENTS_FINISHED:
      return {
        ...state,
        documentsLoading: false,
        documents: action.payload.documents,
      };

    case ProfileActionTypes.GET_DOCUMENTS_ERROR:
      return {
        ...state,
        documentsLoading: false,
        documents: [],
      };

    case ProfileActionTypes.ADD_QUALIFICATION_START:
      return {
        ...state,
        loading: true,
        qualificationsLoading: true,
        qualificationAction: {
          errors: null,
          success: null,
        },
      };

    case ProfileActionTypes.ADD_QUALIFICATION_FINISHED:
      return {
        ...state,
        loading: false,
        qualificationsLoading: false,
        qualificationAction: {
          errors: null,
          success: true,
        },
      };

    case ProfileActionTypes.ADD_QUALIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        qualificationsLoading: false,
        qualificationAction: {
          success: false,
          errors: action.payload,
        },
      };

    case ProfileActionTypes.GENERATE_PROFILE_VIDEO_START:
      return {
        ...state,
        renderedVideo: {
          loading: true,
          path: null,
        },
      };

    case ProfileActionTypes.GENERATE_PROFILE_VIDEO_FINISHED:
      return {
        ...state,
        renderedVideo: {
          loading: false,
          path: action.payload.fileName,
        },
        currentShortProfile: action.payload.profile,
      };

    case ProfileActionTypes.GENERATE_PROFILE_VIDEO_ERROR:
      return {
        ...state,
        renderedVideo: {
          loading: false,
          path: null,
        },
      };

    case ProfileActionTypes.ADD_CERTIFICATION_START:
      return {
        ...state,
        loading: true,
        certificationsLoading: true,
        certificationAction: {
          errors: null,
          success: null,
        },
      };

    case ProfileActionTypes.ADD_CERTIFICATION_FINISHED:
      return {
        ...state,
        loading: false,
        certificationsLoading: false,
        certificationAction: {
          errors: null,
          success: true,
        },
      };

    case ProfileActionTypes.ADD_CERTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        certificationsLoading: false,
        certificationAction: {
          success: false,
          errors: action.payload,
        },
      };

    case ProfileActionTypes.REMOVE_OR_UPDATE_CERTIFICATION_START:
    case ProfileActionTypes.REMOVE_OR_UPDATE_QUALIFICATION_START:
    case ProfileActionTypes.REMOVE_OR_UPDATE_EXPERIENCE_START:
      return {
        ...state,
        loading: true,
      };

    case ProfileActionTypes.REMOVE_OR_UPDATE_CERTIFICATION_FINISHED:
    case ProfileActionTypes.REMOVE_OR_UPDATE_QUALIFICATION_FINISHED:
    case ProfileActionTypes.REMOVE_OR_UPDATE_EXPERIENCE_FINISHED:
      return {
        ...state,
        loading: false,
        certificationAction: {
          errors: null,
          success: true,
        },
        qualificationAction: {
          success: true,
          errors: null,
        },
        experienceAction: {
          success: true,
          errors: null,
        },
        ...action.payload,
      };

    case ProfileActionTypes.REMOVE_OR_UPDATE_CERTIFICATION_ERROR:
    case ProfileActionTypes.REMOVE_OR_UPDATE_EXPERIENCE_ERROR:
    case ProfileActionTypes.REMOVE_OR_UPDATE_QUALIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        certificationAction: {
          success: null,
          errors: action.payload,
        },
        qualificationAction: {
          success: null,
          errors: action.payload,
        },
        experienceAction: {
          success: null,
          errors: action.payload,
        },
      };

    case ProfileActionTypes.ADD_EXP_JOBFUNCTIONS_FINISHED:
      return { ...state, experiences: action.payload.experiences };

    case ProfileActionTypes.SET_AADHAAR_PAN_MODE:
      return {
        ...state,
        kyc: { ...state.kyc, aadhaarPanMode: action.payload.mode },
      };

    case ProfileActionTypes.SAVE_KYC_DETAILS_START:
      return {
        ...state,
        kyc: { ...state.kyc, saving: true, errors: {} },
      };

    case ProfileActionTypes.SAVE_KYC_DETAILS_FINISHED:
      return {
        ...state,
        kyc: { ...state.kyc, saving: false },
      };
    case ProfileActionTypes.SAVE_KYC_DETAILS_ERROR:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          saving: false,
          meta: { ...(state.kyc?.meta || {}), errors: action.payload },
        },
      };

    case ProfileActionTypes.SET_KYC_META:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          meta: action.payload.meta || { step: 0, subStep: 0 },
        },
      };

    case ProfileActionTypes.SET_KYC:
      return {
        ...state,
        kyc: {
          ...state.kyc,
          aadhaarDetails: action.payload.aadhaar ? action.payload.aadhaar : {},
          panDetails: action.payload.pan ? action.payload.pan : {},
        },
      };

    case ProfileActionTypes.GET_NOTIFICATION_PREFERENCES_START:
      return {
        ...state,
        notificationPreferencesLoading: true,
      };
    case ProfileActionTypes.TOGGLE_NOTIFICATION_PREFERENCE_FINISHED:
      return {
        ...state,
        notificationPreferences: action.payload.notificationPreferences || [],
      };
    case ProfileActionTypes.GET_NOTIFICATION_PREFERENCES_FINISHED:
    case ProfileActionTypes.GET_NOTIFICATION_PREFERENCES_ERROR:
      return {
        ...state,
        notificationPreferences: action.payload.notificationPreferences || [],
        notificationPreferencesLoading: false,
      };
    case ProfileActionTypes.GET_JOBFUNCTION_PRIORITIES_START:
      return {
        ...state,
        jobFunctionPrioritiesLoading: true,
      };

    case ProfileActionTypes.GET_JOBFUNCTION_PRIORITIES_FINISHED:
      return {
        ...state,
        jobFunctionPrioritiesLoading: false,
        allJobFunctions: action.payload.allJobFunctions || [],
        jobPreferences: action.payload.jobPreferences || [],
      };

    case ProfileActionTypes.GET_JOBFUNCTION_PRIORITIES_ERROR:
      return {
        ...state,
        jobFunctionPrioritiesLoading: false,
      };

    case ProfileActionTypes.MANAGE_JOBFUNCTION_PRIORITY_FINISHED:
      return {
        ...state,
        currentFullProfile: action.payload.profile,
      };

    case ProfileActionTypes.GET_VOIZ_RESUME_START:
      return {
        ...state,
        loading: true,
        currentResume: null,
      };

    case ProfileActionTypes.GET_VOIZ_RESUME_FINISHED:
      return {
        ...state,
        loading: false,
        currentResume: action.payload.resume,
      };

    case ProfileActionTypes.GET_VOIZ_RESUME_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ProfileActionTypes.GET_MOCK_CALL_SET_START:
      return {
        ...state,
        mockCall: { ...state.mockCall, list: [] },
      };

    case ProfileActionTypes.GET_MOCK_CALL_SET_FINISHED:
      return {
        ...state,
        loading: false,
        mockCall: {
          ...state.mockCall,
          list: action.payload.audioSet,
        },
      };

    case ProfileActionTypes.GET_MOCK_CALL_SET_ERROR:
      return {
        ...state,
        mockCall: { ...state.mockCall, loading: false, list: [] },
      };

    case ProfileActionTypes.GET_MOCK_CALL_SCENARIOS_START:
      return {
        ...state,
        mockCall: { ...state.mockCall, loading: true, scenarios: [] },
      };

    case ProfileActionTypes.GET_MOCK_CALL_SCENARIOS_FINISHED:
      return {
        ...state,
        loading: false,
        mockCall: {
          ...state.mockCall,
          loading: false,
          scenarios: action.payload.mockCallScenarios,
        },
      };

    case ProfileActionTypes.GET_MOCK_CALL_SCENARIOS_ERROR:
      return {
        ...state,
        mockCall: { ...state.mockCall, loading: false, scenarios: [] },
      };

    case ProfileActionTypes.SET_MOCK_CALL_META:
      return {
        ...state,
        mockCall: { ...state.mockCall, meta: action.payload },
      };

    case ProfileActionTypes.GENERATE_PROFILE_AUDIO_START:
      return {
        ...state,
        mockCall: { ...state.mockCall, rendering: true },
      };

    case ProfileActionTypes.GENERATE_PROFILE_AUDIO_FINISHED:
      return {
        ...state,
        mockCall: {
          ...state.mockCall,
          rendering: false,
          meta: {
            ...state.mockCall.meta,
            playAudio: serverUrlFor(`/agentAudios/${action.payload.path}`),
          },
        },
      };

    case ProfileActionTypes.GENERATE_PROFILE_AUDIO_ERROR:
      return {
        ...state,
        mockCall: { ...state.mockCall, rendering: false },
      };

    default:
      return state;
  }
}
