/* eslint-disable func-style */
import ActionTypes from './assessments.types';

export function getUserAssessments(jobFunctionId, suggest) {
  return {
    type: ActionTypes.GET_USER_ASSESSMENTS,
    payload: { jobFunctionId, suggest },
  };
}

export function setSelectedAssessment(assessment) {
  return { type: ActionTypes.SET_SELECTED_ASSESSMENT, payload: { assessment } };
}

export function resetSelectedAssessment() {
  return { type: ActionTypes.SET_SELECTED_ASSESSMENT };
}

export function nextQuestion(
  currentQuestion,
  selectedOptionId,
  endAssessment = false,
  isInactive
) {
  return {
    type: ActionTypes.GET_NEXT_QUESTION,
    payload: {
      uuid: localStorage.getItem('assessmentUuid'),
      currentQuestion,
      selectedOptionId,
      isFirst: currentQuestion?.serialNo === 1,
      endAssessment,
      isInactive,
    },
  };
}

export function prevQuestion(currentQuestion) {
  return {
    type: ActionTypes.GET_PREV_QUESTION,
    payload: {
      uuid: localStorage.getItem('assessmentUuid'),
      currentQuestion,
    },
  };
}

export function startAssessment(jobFunctionAssessmentId) {
  return {
    type: ActionTypes.START_ASSESSMENT,
    payload: { jobFunctionAssessmentId },
  };
}

export function submitTypingAssessment(payload) {
  return {
    type: ActionTypes.SUBMIT_TYPING_ASSESSMENT,
    payload,
  };
}

export function setWindowLocation(payload) {
  return {
    type: ActionTypes.SET_WINDOW_LOCATION,
    payload,
  };
}
