/* eslint-disable func-style */
import { takeEvery } from 'redux-saga/effects';
import { commonAjaxHandler } from '../../url';
import ApplicationActionTypes from './applications.types';

const handleApplicationStatusUpdate = commonAjaxHandler(
  (p) => `/project/${p.projectId}/application/${p.applicationId}/update`,
  {
    method: 'post',
    start: ApplicationActionTypes.APPLICATION_CHANGE_STATE_START,
    success: ApplicationActionTypes.APPLICATION_CHANGE_STATE_FINISHED,
    error: ApplicationActionTypes.APPLICATION_CHANGE_STATE_ERROR,
    payloadKeys: [],
  }
);

const handleGetApplicationProductivity = commonAjaxHandler(
  (p) => `/jobs/application/${p.applicationId}/productivity/all`,
  {
    method: 'post',
    start: ApplicationActionTypes.APPLICATION_PRODUCTIVITY_START,
    success: ApplicationActionTypes.APPLICATION_PRODUCTIVITY_FINISHED,
    error: ApplicationActionTypes.APPLICATION_PRODUCTIVITY_ERROR,
    payloadKeys: ['appProductivity'],
  }
);
const handleGetApplicationProductivityCalendar = commonAjaxHandler(
  (p) => `/jobs/application/${p.applicationId}/productivity/calendar`,
  {
    method: 'post',
    start: ApplicationActionTypes.APPLICATION_PRODUCTIVITY_CALENDAR_START,
    success: ApplicationActionTypes.APPLICATION_PRODUCTIVITY_CALENDAR_FINISHED,
    error: ApplicationActionTypes.APPLICATION_PRODUCTIVITY_CALENDAR_ERROR,
    payloadKeys: ['appProductivity'],
  }
);
const handleGetApplicationProductivityReports = commonAjaxHandler(
  (p) => `/jobs/application/${p.applicationId}/productivity/reports`,
  {
    method: 'post',
    start: ApplicationActionTypes.APPLICATION_PRODUCTIVITY_REPORT_START,
    success: ApplicationActionTypes.APPLICATION_PRODUCTIVITY_REPORT_FINISHED,
    error: ApplicationActionTypes.APPLICATION_PRODUCTIVITY_REPORT_ERROR,
    payloadKeys: ['appProductivity'],
  }
);
const handleGetApplicationProductivityDetails = commonAjaxHandler(
  (p) =>
    `/jobs/application/${p.applicationId}/productivity/productivitydetails`,
  {
    method: 'post',
    start: ApplicationActionTypes.APPLICATION_PRODUCTIVITY_DETAILS_START,
    success: ApplicationActionTypes.APPLICATION_PRODUCTIVITY_DETAILS_FINISHED,
    error: ApplicationActionTypes.APPLICATION_PRODUCTIVITY_DETAILS_ERROR,
    payloadKeys: ['appProductivity'],
  }
);

export function* updateApplicationStatus() {
  yield takeEvery(
    ApplicationActionTypes.APPLICATION_CHANGE_STATE,
    handleApplicationStatusUpdate
  );
}

export function* fetchApplicationProductivity() {
  yield takeEvery(
    ApplicationActionTypes.APPLICATION_PRODUCTIVITY,
    handleGetApplicationProductivity
  );
}

export function* fetchApplicationProductivityCalendar() {
  yield takeEvery(
    ApplicationActionTypes.APPLICATION_PRODUCTIVITY_CALENDAR,
    handleGetApplicationProductivityCalendar
  );
}
export function* fetchApplicationProductivityReports() {
  yield takeEvery(
    ApplicationActionTypes.APPLICATION_PRODUCTIVITY_REPORT,
    handleGetApplicationProductivityReports
  );
}
export function* fetchApplicationProductivityDetails() {
  yield takeEvery(
    ApplicationActionTypes.APPLICATION_PRODUCTIVITY_DETAILS,
    handleGetApplicationProductivityDetails
  );
}
