import * as _ from 'lodash';
import ProjectActionTypes from './project.types';

const INITIAL_STATE = {
  loading: false,
  created: null,

  applicationStats: {},

  project: null,
  projectLoadError: null,
  updateSuccess: null,
  updateErrors: null,

  applicationsLoading: false,
  applications: [],
  applicationsLoadError: null,
  applicationSummary: null,

  // Hiring v2
  selectedApplications: [],
  rejectedApplications: [],
  stages: [],
  hiringStatus: null,
  applicantsLoading: false,
  applicantsLoadError: null,

  applicationSubStatusLoading: false,
  applicationSubStatusCounts: null,

  listingFilters: {},
  projects: [],
  listingErrors: null,
  pager: null,
  hideActions: null,
  create: {
    step: 0,
    peopleRequirement: {
      languages: [],
      noOfAgents: null,
    },
    estimate: null,
  },
  productivity: null,
  createErrors: null,
  projectOfferLetterConfig: null,
  businessOfferAnnexures: null,
};

export default function projectReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ProjectActionTypes.INCREMENT_UNREAD_CHAT_COUNT: {
      const { chatId } = action.payload;

      let index = state.selectedApplications.findIndex(
        (current) => String(current.applicationId) === String(chatId)
      );

      if (index >= 0) {
        state.selectedApplications[index].unreadCount += 1;
        return state;
      }

      index = state.rejectedApplications.findIndex(
        (current) => String(current.applicationId) === String(chatId)
      );

      if (index >= 0) {
        state.rejectedApplications[index].unreadCount += 1;
        return state;
      }

      return state;
    }

    case ProjectActionTypes.CLEAR_UNREAD_CHAT_COUNT: {
      const { chatId } = action.payload;

      let index = state.selectedApplications.findIndex(
        (current) => String(current.applicationId) === String(chatId)
      );

      if (index >= 0) {
        state.selectedApplications[index].unreadCount = 0;
        return state;
      }

      index = state.rejectedApplications.findIndex(
        (current) => String(current.applicationId) === String(chatId)
      );

      if (index >= 0) {
        state.rejectedApplications[index].unreadCount = 0;
        return state;
      }

      return state;
    }
    case ProjectActionTypes.MOVE_APPLICANT_TO_REJECTED: {
      const { applicant } = action.payload;

      // Hack to allow select from reject bucket
      if (applicant.appStatus === 'pending')
        applicant.appStatus = 'offer_reject';

      return {
        ...state,
        selectedApplications: state.selectedApplications.filter(
          (current) => current.applicationId !== applicant.applicationId
        ),
        rejectedApplications: [applicant, ...state.rejectedApplications],
      };
    }
    case ProjectActionTypes.REMOVE_APPLICANT: {
      const { applicant } = action.payload;

      if (applicant.appStatus === 'offer_reject') {
        // Set the status manually
        applicant.appStatus = 'pending';

        // Just move hiim back to selected bucket
        const index = state.rejectedApplications.findIndex(
          (current) => current.applicationId === applicant.applicationId
        );

        state.rejectedApplications.splice(index, 1);

        // When offer reject, just move him to selected bucket
        return {
          ...state,
          selectedApplications: [applicant, ...state.selectedApplications],
          rejectedApplications: state.rejectedApplications,
        };
      } else {
        return {
          ...state,
          selectedApplications: state.selectedApplications.filter(
            (current) => current.applicationId !== applicant.applicationId
          ),
        };
      }
    }
    case ProjectActionTypes.PROJECT_APPLICANTS_START_V2:
      return {
        ...state,
        applicantsLoading: true,
        selectedApplications: [],
        rejectedApplications: [],
        applicantsLoadError: null,
      };
    case ProjectActionTypes.PROJECT_APPLICANTS_CLEAR_V2:
      return {
        ...state,
        applicantsLoading: false,
        selectedApplications: [],
        rejectedApplications: [],
        applicantsLoadError: null,
      };

    case ProjectActionTypes.PROJECT_APPLICANTS_FINISHED_V2:
      const { selectedApplications, rejectedApplications } = action.payload;
      return {
        ...state,
        selectedApplications: selectedApplications
          ? selectedApplications
          : state.selectedApplications,
        rejectedApplications: rejectedApplications
          ? rejectedApplications
          : state.rejectedApplications,
        hiringStatus: action.payload.hiringStatus,
        stages: action.payload.stages,
        applicantsLoading: false,
        applicantsLoadError: null,
      };

    case ProjectActionTypes.PROJECT_APPLICANTS_ERROR_V2:
      return {
        ...state,
        applicants: [],
        applicantsLoading: false,
        applicantsLoadError: 'Something went wrong',
        applicantSummary: null,
      };
    case ProjectActionTypes.PROJECT_UPDATE_INTEGRATION_START:
      return {
        ...state,
        loading: true,
        integrationError: null,
      };
    case ProjectActionTypes.PROJECT_UPDATE_INTEGRATION_FINISHED:
      return {
        ...state,
        loading: false,
      };
    case ProjectActionTypes.PROJECT_UPDATE_INTEGRATION_ERROR:
      return {
        ...state,
        loading: false,
        integrationError: action.payload,
      };
    case ProjectActionTypes.PROJECT_INTEGRATIONS_START:
      return {
        ...state,
        integrationError: null,
        loading: true,
      };
    case ProjectActionTypes.PROJECT_INTEGRATIONS_FINISHED:
      return {
        ...state,
        loading: false,
        integrations: action.payload.integrations,
      };
    case ProjectActionTypes.PROJECT_INTEGRATIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ProjectActionTypes.DELETE_PROJECT_START:
      return {
        ...state,
        loading: true,
      };
    case ProjectActionTypes.DELETE_PROJECT_FINISHED:
    case ProjectActionTypes.DELETE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ProjectActionTypes.UPDATE_PROJECT_START:
      return {
        ...state,
        loading: !action.payload.managementTypeToggle,
        updateSuccess: null,
        updateErrors: null,
      };
    case ProjectActionTypes.UPDATE_PROJECT_FINISHED:
      return {
        ...state,
        project: action.payload.project,
        updateSuccess: true,
        updateErrors: null,
        loading: false,
      };
    case ProjectActionTypes.UPDATE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        updateErrors: action.payload,
      };
    case ProjectActionTypes.FREEZE_PROJECT_TIMESLOT_START:
    case ProjectActionTypes.UNFREEZE_PROJECT_TIMESLOT_START:
      return {
        ...state,
        applicationStats: {
          ...state.applicationStats,
          [action.payload.projectId]: {
            ...state.applicationStats[action.payload.projectId],
            loading: true,
          },
        },
      };
    case ProjectActionTypes.FREEZE_PROJECT_TIMESLOT_FINISHED:
    case ProjectActionTypes.UNFREEZE_PROJECT_TIMESLOT_FINISHED:
      return {
        ...state,
        applicationStats: {
          ...state.applicationStats,
          [action.originalPayload.projectId]: {
            loading: false,
            stats: action.payload.stats,
          },
        },
      };
    case ProjectActionTypes.FREEZE_PROJECT_TIMESLOT_ERROR:
    case ProjectActionTypes.UNFREEZE_PROJECT_TIMESLOT_ERROR:
      return {
        ...state,
        applicationStats: {
          ...state.applicationStats,
          [action.originalPayload.projectId]: {
            ...state.applicationStats[action.originalPayload.projectId],
            loading: false,
          },
        },
      };
    case ProjectActionTypes.PROJECT_DETAIL_START:
      return {
        ...state,
        loading: true,
        project: null,
        projectLoadError: null,
      };
    case ProjectActionTypes.PROJECT_DETAIL_FINISHED:
      const { project } = action.payload;
      return {
        ...state,
        loading: false,
        projectLoadError: null,
        project,
      };
    case ProjectActionTypes.PROJECT_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        project: null,
        projectLoadError: action.payload,
      };
    case ProjectActionTypes.PROJECT_DETAIL_CLEAR:
      return {
        ...state,
        loading: false,
        project: null,
        projectLoadError: null,
      };
    case ProjectActionTypes.PROJECT_APPLICANTS_START:
      return {
        ...state,
        applicationsLoading: _.isNull(state.pager),
        applicationsLoadError: null,
        applicationSummary: null,
        hideActions: null,
      };
    case ProjectActionTypes.PROJECT_APPLICANTS_FINISHED:
      const { applications, summary, pager } = action.payload;
      return {
        ...state,
        applicationsLoading: false,
        applicationsLoadError: null,
        applicationSummary: summary,
        applications: pager.isFirstFetch
          ? applications
          : [...state.applications, ...applications],
        pager,
        hideActions: action.payload.hideActions,
        applicationIds: action.payload.applicationIds,
      };
    case ProjectActionTypes.PROJECT_APPLICANTS_ERROR:
      return {
        ...state,
        applications: null,
        applicationsLoadError: action.payload,
        applicationsLoading: false,
      };
    case ProjectActionTypes.PROJECT_APPLICANTS_CLEAR:
      return {
        ...state,
        applications: null,
        applicationsLoadError: null,
        applicationsLoading: false,
      };
    case ProjectActionTypes.PROJECT_APPLICANTS_SUBSTAT_START:
      return {
        ...state,
        applicationsLoading: _.isNull(state.pager),
        applicationsLoadError: null,
        applicationSummary: null,
      };
    case ProjectActionTypes.PROJECT_APPLICANTS_SUBSTAT_FINISHED:
      return {
        ...state,
        applicationsLoading: false,
        applicationsLoadError: null,
        applicationSummary: action.payload.summary,
        applications: action.payload.pager.isFirstFetch
          ? action.payload.applications
          : [...state.applications, ...action.payload.applications],
        pager: action.payload.pager,
        hideActions: action.payload.hideActions,
        applicationIds: action.payload.applicationIds,
      };
    case ProjectActionTypes.PROJECT_APPLICANTS_SUBSTAT_ERROR:
      return {
        ...state,
        applications: null,
        applicationsLoadError: action.payload,
        applicationsLoading: false,
      };
    case ProjectActionTypes.PROJECT_APPLICANTS_SUBSTAT_CLEAR:
      return {
        ...state,
        applications: null,
        applicationsLoadError: null,
        applicationsLoading: false,
      };
    case ProjectActionTypes.LIST_PROJECTS_START:
      return {
        ...state,
        loading: _.isNull(state.pager),
        projects: action.payload.page > 1 ? state.projects : [],
      };
    case ProjectActionTypes.LIST_PROJECTS_FINISHED:
      return {
        ...state,
        loading: false,
        projects:
          action.payload.pager && action.payload.pager.isFirstFetch
            ? action.payload.projects
            : [...state.projects, ...action.payload.projects],
        projectStats: action.payload.projectStats,
        demandApplications: {
          ...(state.demandApplications || {}),
          ...action.payload.demandApplications,
        },
        pager: action.payload.pager,
      };
    case ProjectActionTypes.LIST_PROJECTS_ERROR:
      return {
        ...state,
        loading: false,
        projects: [],
        pager: null,
        listingError: action.payload,
      };
    case ProjectActionTypes.CLEAR_CREATE_PROJECT:
      return {
        ...state,
        create: {
          step: 0,
          peopleRequirement: {
            languages: [],
            noOfAgents: null,
          },
          estimate: null,
        },
        created: null,
        createErrors: null,
      };

    case ProjectActionTypes.CREATE_PROJECT_START:
      return {
        ...state,
        loading: true,
      };
    case ProjectActionTypes.CREATE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        createErrors: action.payload,
      };
    case ProjectActionTypes.CREATE_PROJECT_FINISHED:
      return {
        ...state,
        loading: false,
        createErrors: null,
        created: action.payload,
      };
    case ProjectActionTypes.SET_PROJECT_DETAILS:
      localStorage.setItem(
        'project',
        JSON.stringify({
          ...state.create,
          ...action.payload,
        })
      );
      return {
        ...state,
        create: {
          ...state.create,
          ...action.payload,
        },
      };
    case ProjectActionTypes.CLEAR_PAGER:
      return {
        ...state,
        pager: null,
        applications: [],
      };
    case ProjectActionTypes.APPROVE_WORK_REPORT_START:
      return {
        ...state,
        applicationsLoading: true,
      };
    case ProjectActionTypes.APPROVE_WORK_REPORT_FINISHED:
    case ProjectActionTypes.APPROVE_WORK_REPORT_ERROR:
      return {
        ...state,
        applicationsLoading: false,
      };

    case ProjectActionTypes.ADD_CRM_START:
      return {
        ...state,
        loading: true,
      };
    case ProjectActionTypes.ADD_CRM_FINISHED:
      return {
        ...state,
        loading: false,
        project: action.payload.project,
      };
    case ProjectActionTypes.ADD_CRM_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ProjectActionTypes.CONFIG_OFFERLETTER_START:
    case ProjectActionTypes.CONFIG_OFFERLETTER_ERROR:
    case ProjectActionTypes.FETCH_OFFERLETTER_CONFIG_ERROR:
      return {
        ...state,
        loading: true,
      };
    case ProjectActionTypes.CONFIG_OFFERLETTER_FINISHED:
    case ProjectActionTypes.FETCH_OFFERLETTER_CONFIG_FINISHED:
      return {
        ...state,
        loading: false,
        projectOfferLetterConfig: action.payload.projectOfferLetterConfig,
        businessOfferAnnexures: action.payload.businessOfferAnnexures,
      };
    case ProjectActionTypes.GET_PRICING_ESTIMATE_START:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
        },
      };

    case ProjectActionTypes.GET_PRICING_ESTIMATE_FINISHED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          estimate: action.payload.estimate,
          pricingEstimates: action.payload.pricingEstimates,
        },
      };

    default:
      return state;
  }
}
