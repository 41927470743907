export const HomeActionType = {
  GET_STATISTICS: 'GET_STATISTICS',
  GET_STATISTICS_STARTED: 'GET_STATISTICS_STARTED',
  GET_STATISTICS_FINISHED: 'GET_STATISTICS_FINISHED',
  GET_STATISTICS_ERROR: 'GET_STATISTICS_ERROR',

  GET_PROFILECOMPLETION: 'GET_PROFILECOMPLETION',
  GET_PROFILECOMPLETION_STARTED: 'GET_PROFILECOMPLETION_STARTED',
  GET_PROFILECOMPLETION_FINISHED: 'GET_PROFILECOMPLETION_FINISHED',
  GET_PROFILECOMPLETION_ERROR: 'GET_PROFILECOMPLETION_ERROR',

  GET_JOBS_SNAP: 'GET_JOBS_SNAP',
  GET_JOBS_SNAP_STARTED: 'GET_JOBS_SNAP_STARTED',
  GET_JOBS_SNAP_FINISHED: 'GET_JOBS_SNAP_FINISHED',
  GET_JOBS_SNAP_ERROR: 'GET_JOBS_SNAP_ERROR',
};

export default HomeActionType;
