/* eslint-disable default-param-last */
import HomeActionTypes from './home.types';

const INITIAL_STATE = {
  statistics: {
    loading: false,
    items: null,
  },

  profileCompletion: {
    loading: false,
    items: null,
  },

  jobsSnap: {
    loading: false,
    items: null,
  },
};

export default function HomeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HomeActionTypes.GET_PROFILECOMPLETION_STARTED:
      return {
        ...state,
        profileCompletion: {
          items: null,
          loading: true,
        },
      };
    case HomeActionTypes.GET_PROFILECOMPLETION_FINISHED:
      return {
        ...state,
        profileCompletion: {
          loading: false,
          items: action.payload.profileCompletion,
        },
      };
    case HomeActionTypes.GET_PROFILECOMPLETION_ERROR:
      return {
        ...state,
        profileCompletion: {
          items: null,
          loading: false,
        },
      };
    case HomeActionTypes.GET_STATISTICS_STARTED:
      return {
        ...state,
        statistics: {
          items: null,
          loading: true,
          meta: null,
        },
      };
    case HomeActionTypes.GET_STATISTICS_FINISHED:
      return {
        ...state,
        statistics: {
          meta: action.payload.meta,
          loading: false,
          items: action.payload.statistics,
        },
      };
    case HomeActionTypes.GET_STATISTICS_ERROR:
      return {
        ...state,
        statistics: {
          items: null,
          meta: null,
          loading: false,
        },
      };

    case HomeActionTypes.GET_JOBS_SNAP_STARTED:
      return {
        ...state,
        jobsSnap: {
          items: null,
          loading: true,
        },
      };
    case HomeActionTypes.GET_JOBS_SNAP_FINISHED:
      return {
        ...state,
        jobsSnap: {
          loading: false,
          items: action.payload.userApplications,
        },
      };
    case HomeActionTypes.GET_JOBS_SNAP_ERROR:
      return {
        ...state,
        jobsSnap: {
          items: null,
          loading: false,
        },
      };

    default:
      return state;
  }
}
