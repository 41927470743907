import DataActionTypes from './data.types';

const INITIAL_STATE = {
  projectTypes: {
    loading: false,
    items: null,
  },

  featureFlags: {
    loading: false,
    items: null,
  },

  crms: {
    loading: false,
    items: null,
  },

  pricingTypes: {
    loading: false,
    items: null,
  },

  subProjectTypes: {
    loading: false,
    items: {},
  },

  languages: {
    loading: false,
    items: null,
  },

  industries: {
    loading: false,
    items: null,
  },

  languageProficiencies: {
    loading: false,
    items: null,
  },

  timeSlots: {
    loading: false,
    items: null,
  },

  applicationStatuses: {
    loading: false,
    items: null,
  },

  expertiseLevels: {
    loading: false,
    items: null,
  },

  qualifications: {
    loading: false,
    items: null,
  },

  courses: {
    loading: false,
    items: null,
  },

  jobLocationTypes: {
    loading: false,
    items: null,
  },

  assessmentTypes: {
    loading: false,
    items: null,
  },

  typingAssessmentTypes: {
    loading: false,
    items: null,
  },

  projectManagementTypes: {
    loading: false,
    items: null,
  },

  skills: {
    loading: false,
    items: null,
  },

  workPreferenceTypes: {
    loading: false,
    items: null,
  },
  clientFirstProject: {
    loading: false,
    items: null,
  },

  geoLocation: {
    loading: false,
    coordinates: {},
    address: null,
    hasError: false,
  },

  detailsOfIfsc: {
    loading: false,
    details: null,
  },

  workSpaceSetup: {
    loading: false,
    items: null,
  },

  leaveCategories: {
    loading: false,
    items: null,
  },

  companyTypes: {
    loading: false,
    items: null,
  },

  rankings: {
    loading: false,
    items: null,
  },
};

function _start(key, state) {
  return {
    ...state,
    [key]: {
      loading: true,
      items: null,
      error: null,
    },
  };
}

function _finished(key, state, payload) {
  return {
    ...state,
    [key]: {
      loading: false,
      items: payload,
      error: null,
    },
  };
}

function _error(key, state, error) {
  return {
    ...state,
    [key]: {
      loading: false,
      error,
      items: null,
    },
  };
}

export default function DataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DataActionTypes.FETCH_COURSES_START:
      return _start('courses', state);
    case DataActionTypes.FETCH_COURSES_FINISHED:
      return _finished('courses', state, action.payload);
    case DataActionTypes.FETCH_COURSES_ERROR:
      return _error('courses', state, action.payload);

    case DataActionTypes.FETCH_INDUSTRIES_START:
      return _start('industries', state);
    case DataActionTypes.FETCH_INDUSTRIES_FINISHED:
      return _finished('industries', state, action.payload);
    case DataActionTypes.FETCH_INDUSTRIES_ERROR:
      return _error('industries', state, action.payload);

    case DataActionTypes.FETCH_TIME_SLOTS_START:
      return _start('timeSlots', state);
    case DataActionTypes.FETCH_TIME_SLOTS_FINISHED:
      return _finished('timeSlots', state, action.payload);
    case DataActionTypes.FETCH_TIME_SLOTS_ERROR:
      return _error('timeSlots', state, action.payload);
    case DataActionTypes.FETCH_APPLICATION_STATUSES_START:
      return _start('applicationStatuses', state);
    case DataActionTypes.FETCH_APPLICATION_STATUSES_FINISHED:
      return _finished('applicationStatuses', state, action.payload);
    case DataActionTypes.FETCH_APPLICATION_STATUSES_ERROR:
      return _error('applicationStatuses', state, action.payload);

    case DataActionTypes.FETCH_EXPERTISE_LEVELS_START:
      return _start('expertiseLevels', state);
    case DataActionTypes.FETCH_EXPERTISE_LEVELS_FINISHED:
      return _finished('expertiseLevels', state, action.payload);
    case DataActionTypes.FETCH_EXPERTISE_LEVELS_ERROR:
      return _error('expertiseLevels', state, action.payload);

    case DataActionTypes.FETCH_QUALIFICATIONS_START:
      return _start('qualifications', state);
    case DataActionTypes.FETCH_QUALIFICATIONS_FINISHED:
      return _finished('qualifications', state, action.payload);
    case DataActionTypes.FETCH_QUALIFICATIONS_ERROR:
      return _error('qualifications', state, action.payload);

    case DataActionTypes.FETCH_ASSESSMENT_TYPES_START:
      return _start('assessmentTypes', state);
    case DataActionTypes.FETCH_ASSESSMENT_TYPES_FINISHED:
      return _finished('assessmentTypes', state, action.payload);
    case DataActionTypes.FETCH_ASSESSMENT_TYPES_ERROR:
      return _error('assessmentTypes', state, action.payload);

    case DataActionTypes.FETCH_WORKPREFERENCE_TYPES_START:
      return _start('workPreferenceTypes', state);
    case DataActionTypes.FETCH_WORKPREFERENCE_TYPES_FINISHED:
      return _finished('workPreferenceTypes', state, action.payload);
    case DataActionTypes.FETCH_WORKPREFERENCE_TYPES_ERROR:
      return _error('workPreferenceTypes', state, action.payload);

    case DataActionTypes.FETCH_JOBLOCATION_TYPES_START:
      return _start('jobLocationTypes', state);
    case DataActionTypes.FETCH_JOBLOCATION_TYPES_FINISHED:
      return _finished('jobLocationTypes', state, action.payload);
    case DataActionTypes.FETCH_JOBLOCATION_TYPES_ERROR:
      return _error('jobLocationTypes', state, action.payload);

    case DataActionTypes.FETCH_TYPINGASSESSMENTTYPE_TYPES_START:
      return _start('typingAssessmentTypes', state);
    case DataActionTypes.FETCH_TYPINGASSESSMENTTYPE_TYPES_FINISHED:
      return _finished('typingAssessmentTypes', state, action.payload);
    case DataActionTypes.FETCH_TYPINGASSESSMENTTYPE_TYPES_ERROR:
      return _error('typingAssessmentTypes', state, action.payload);

    case DataActionTypes.FETCH_LEAVE_CATEGORIES_START:
      return _start('leaveCategories', state);
    case DataActionTypes.FETCH_LEAVE_CATEGORIES_FINISHED:
      return _finished('leaveCategories', state, action.payload);
    case DataActionTypes.FETCH_LEAVE_CATEGORIES_ERROR:
      return _error('leaveCategories', state, action.payload);

    case DataActionTypes.FETCH_SUB_PROJECT_TYPES_START:
      return {
        ...state,
        subProjectTypes: {
          ...state.subProjectTypes,
          loading: true,
        },
      };
    case DataActionTypes.FETCH_SUB_PROJECT_TYPES_FINISHED:
      return {
        ...state,
        subProjectTypes: {
          loading: false,
          items: {
            ...state.subProjectTypes.items,
            [action.originalPayload.projectTypeId]:
              action.payload.subProjectTypes,
          },
          error: null,
        },
      };
    case DataActionTypes.FETCH_SUB_PROJECT_TYPES_ERROR:
      return {
        ...state,
        subProjectTypes: {
          loading: false,
          items: {
            ...state.subProjectTypes.items,
          },
          error: action.payload,
        },
      };

    case DataActionTypes.FETCH_PROJECT_TYPES_START:
      return _start('projectTypes', state);
    case DataActionTypes.FETCH_PROJECT_TYPES_FINISHED:
      return _finished('projectTypes', state, action.payload);
    case DataActionTypes.FETCH_PROJECT_TYPES_ERROR:
      return _error('projectTypes', state, action.payload);

    case DataActionTypes.FETCH_LANGUAGES_START:
      return _start('languages', state, action.payload);
    case DataActionTypes.FETCH_LANGUAGES_ERROR:
      return _error('languages', state, action.payload);
    case DataActionTypes.FETCH_LANGUAGES_FINISHED:
      return _finished('languages', state, action.payload);

    case DataActionTypes.FETCH_PRICING_TYPES_START:
      return _start('pricingTypes', state, action.payload);
    case DataActionTypes.FETCH_PRICING_TYPES_ERROR:
      return _error('pricingTypes', state, action.payload);
    case DataActionTypes.FETCH_PRICING_TYPES_FINISHED:
      return _finished('pricingTypes', state, action.payload);

    case DataActionTypes.FETCH_CRMS_START:
      return _start('crms', state, action.payload);
    case DataActionTypes.FETCH_CRMS_ERROR:
      return _error('crms', state, action.payload);
    case DataActionTypes.FETCH_CRMS_FINISHED:
      return _finished('crms', state, action.payload);

    case DataActionTypes.FETCH_FEATURE_FLAGS_START:
      return _start('featureFlags', state, action.payload);
    case DataActionTypes.FETCH_FEATURE_FLAGS_ERROR:
      return _error('featureFlags', state, action.payload);
    case DataActionTypes.FETCH_FEATURE_FLAGS_FINISHED:
      return _finished('featureFlags', state, action.payload);

    case DataActionTypes.FETCH_SKILLS_START:
      return _start('skills', state, action.payload);
    case DataActionTypes.FETCH_SKILLS_ERROR:
      return _error('skills', state, action.payload);
    case DataActionTypes.FETCH_SKILLS_FINISHED:
      return _finished('skills', state, action.payload);

    case DataActionTypes.FETCH_WORK_SPACE_SETUP_START:
      return _start('workSpaceSetup', state, action.payload);
    case DataActionTypes.FETCH_WORK_SPACE_SETUP_ERROR:
      return _error('workSpaceSetup', state, action.payload);
    case DataActionTypes.FETCH_WORK_SPACE_SETUP_FINISHED:
      return _finished('workSpaceSetup', state, action.payload);

    case DataActionTypes.FETCH_COMPANY_TYPES_START:
      return _start('companyTypes', state, action.payload);
    case DataActionTypes.FETCH_COMPANY_TYPES_ERROR:
      return _error('companyTypes', state, action.payload);
    case DataActionTypes.FETCH_COMPANY_TYPES_FINISHED:
      return _finished('companyTypes', state, action.payload);

    case DataActionTypes.FETCH_ADDRESS_OF_GEOLOCATION_START:
      return {
        ...state,
        geoLocation: {
          ...state.geoLocation,
          loading: true,
          coordinates: action.payload.coordinates,
          address: null,
          hasError: false,
        },
      };
    case DataActionTypes.FETCH_ADDRESS_OF_GEOLOCATION_FINISHED:
      return {
        ...state,
        geoLocation: {
          ...state.geoLocation,
          loading: false,
          address: action.payload.address,
          hasError: false,
        },
      };
    case DataActionTypes.FETCH_ADDRESS_OF_GEOLOCATION_ERROR:
      return {
        ...state,
        geoLocation: {
          ...state.geoLocation,
          loading: false,
          address: null,
          hasError: true,
        },
      };

    case DataActionTypes.FETCH_BANK_WITH_IFSC_START:
      return {
        ...state,
        detailsOfIfsc: {
          ...state.detailsOfIfsc,
          loading: true,
          details: null,
        },
      };
    case DataActionTypes.FETCH_BANK_WITH_IFSC_FINISHED:
      return {
        ...state,
        detailsOfIfsc: {
          ...state.detailsOfIfsc,
          loading: false,
          details: action.payload.bank,
        },
      };
    case DataActionTypes.FETCH_BANK_WITH_IFSC_ERROR:
      return {
        ...state,
        detailsOfIfsc: {
          ...state.detailsOfIfsc,
          loading: false,
          details: null,
        },
      };

    case DataActionTypes.RESET_DETAILS_OF_IFSC:
      return {
        ...state,
        detailsOfIfsc: {
          ...state.detailsOfIfsc,
          loading: false,
          details: null,
        },
      };

    case DataActionTypes.FETCH_REJECT_REASONS_START:
      return _start('rejectReasons', state, action.payload);
    case DataActionTypes.FETCH_REJECT_REASONS_ERROR:
      return _error('rejectReasons', state, action.payload);
    case DataActionTypes.FETCH_REJECT_REASONS_FINISHED:
      return _finished('rejectReasons', state, action.payload);


    case DataActionTypes.FETCH_RANKINGS_START:
      return _start('rankings', state, action.payload);
    case DataActionTypes.FETCH_RANKINGS_ERROR:
      return _error('rankings', state, action.payload);
    case DataActionTypes.FETCH_RANKINGS_FINISHED:
      return _finished('rankings', state, action.payload);

    default:
      return state;
  }
}
