const keys = [
  'SESSION_DETAILS',
  'START_WORK',
  'END_WORK',
  'TAKE_A_BREAK',
  'END_BREAK',
];
const suffixes = ['', '_START', '_FINISHED', '_ERROR'];
const types = {};
keys.forEach((k) => {
  suffixes.forEach((s) => {
    const value = k + s;
    types[value] = value;
  });
});

export default types;
