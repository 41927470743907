export default {
  SET_BUSINESS: 'SET_BUSINESS',

  BUSINESS_GET_CAMPAIGN_FIELDS: 'BUSINESS_GET_CAMPAIGN_FIELDS',
  BUSINESS_GET_CAMPAIGN_FIELDS_START: 'BUSINESS_GET_CAMPAIGN_FIELDS_START',
  BUSINESS_GET_CAMPAIGN_FIELDS_FINISHED: 'BUSINESS_GET_CAMPAIGN_FIELDS_FINISHED',
  BUSINESS_GET_CAMPAIGN_FIELDS_ERROR: 'BUSINESS_GET_CAMPAIGN_FIELDS_ERROR',

  BUSINESS_ADD_CAMPAIGN_FIELD: 'BUSINESS_ADD_CAMPAIGN_FIELD',
  BUSINESS_ADD_CAMPAIGN_FIELD_START: 'BUSINESS_ADD_CAMPAIGN_FIELD_START',
  BUSINESS_ADD_CAMPAIGN_FIELD_FINISHED: 'BUSINESS_ADD_CAMPAIGN_FIELD_FINISHED',
  BUSINESS_ADD_CAMPAIGN_FIELD_ERROR: 'BUSINESS_ADD_CAMPAIGN_FIELD_ERROR',

  BUSINESS_REMOVE_CAMPAIGN_FIELD: 'BUSINESS_REMOVE_CAMPAIGN_FIELD',
  BUSINESS_REMOVE_CAMPAIGN_FIELD_START: 'BUSINESS_REMOVE_CAMPAIGN_FIELD_START',
  BUSINESS_REMOVE_CAMPAIGN_FIELD_FINISHED: 'BUSINESS_REMOVE_CAMPAIGN_FIELD_FINISHED',
  BUSINESS_REMOVE_CAMPAIGN_FIELD_ERROR: 'BUSINESS_REMOVE_CAMPAIGN_FIELD_ERROR',

  BUSINESS_UPDATE_CAMPAIGN_FIELD: 'BUSINESS_UPDATE_CAMPAIGN_FIELD',
  BUSINESS_UPDATE_CAMPAIGN_FIELD_START: 'BUSINESS_UPDATE_CAMPAIGN_FIELD_START',
  BUSINESS_UPDATE_CAMPAIGN_FIELD_FINISHED: 'BUSINESS_UPDATE_CAMPAIGN_FIELD_FINISHED',
  BUSINESS_UPDATE_CAMPAIGN_FIELD_ERROR: 'BUSINESS_UPDATE_CAMPAIGN_FIELD_ERROR',

  BUSINESS_GET_PRODUCTIVITY_LOGS: 'BUSINESS_GET_PRODUCTIVITY_LOGS',
  BUSINESS_GET_PRODUCTIVITY_LOGS_START: 'BUSINESS_GET_PRODUCTIVITY_LOGS_START',
  BUSINESS_GET_PRODUCTIVITY_LOGS_FINISHED: 'BUSINESS_GET_PRODUCTIVITY_LOGS_FINISHED',
  BUSINESS_GET_PRODUCTIVITY_LOGS_ERROR: 'BUSINESS_GET_PRODUCTIVITY_LOGS_ERROR',

  BUSINESS_SET_KYC_FORM: 'BUSINESS_SET_KYC_FORM',
  BUSINESS_CLEAR_KYC_FORM: 'BUSINESS_CLEAR_KYC_FORM',

  GET_ALL_BUSINESSES: 'GET_ALL_BUSINESSES',
  GET_ALL_BUSINESSES_START: 'GET_ALL_BUSINESSES_START',
  GET_ALL_BUSINESSES_FINISHED: 'GET_ALL_BUSINESSES_FINISHED',
  GET_ALL_BUSINESSES_ERROR: 'GET_ALL_BUSINESSES_ERROR',

  BUSINESS_SAVE_INFO: 'BUSINESS_SAVE_INFO',
  BUSINESS_SAVE_INFO_START: 'BUSINESS_SAVE_INFO_START',
  BUSINESS_SAVE_INFO_FINISHED: 'BUSINESS_SAVE_INFO_FINISHED',
  BUSINESS_SAVE_INFO_ERROR: 'BUSINESS_SAVE_INFO_ERROR',

  BUSINESS_GET: 'BUSINESS_GET',
  BUSINESS_GET_START: 'BUSINESS_GET_START',
  BUSINESS_GET_FINISHED: 'BUSINESS_GET_FINISHED',
  BUSINESS_GET_ERROR: 'BUSINESS_GET_ERROR',

  MAINSUMMARY_GET: 'MAINSUMMARY_GET',
  MAINSUMMARY_GET_START: 'MAINSUMMARY_GET_START',
  MAINSUMMARY_GET_FINISHED: 'MAINSUMMARY_GET_FINISHED',
  MAINSUMMARY_GET_ERROR: 'MAINSUMMARY_GET_ERROR',

  DAILERSUMMARY_GET: 'DAILERSUMMARY_GET',
  DAILERSUMMARY_GET_START: 'DAILERSUMMARY_GET_START',
  DAILERSUMMARY_GET_FINISHED: 'DAILERSUMMARY_GET_FINISHED',
  DAILERSUMMARY_GET_ERROR: 'DAILERSUMMARY_GET_ERROR',

  SLOTSUMMARY_GET: 'SLOTSUMMARY_GET',
  SLOTSUMMARY_GET_START: 'SLOTSUMMARY_GET_START',
  SLOTSUMMARY_GET_FINISHED: 'SLOTSUMMARY_GET_FINISHED',
  SLOTSUMMARY_GET_ERROR: 'SLOTSUMMARY_GET_ERROR',

  QUALITYMATRIX_GET: 'QUALITYMATRIX_GET',
  QUALITYMATRIX_GET_START: 'QUALITYMATRIX_GET_START',
  QUALITYMATRIX_GET_FINISHED: 'QUALITYMATRIX_GET_FINISHED',
  QUALITYMATRIX_GET_ERROR: 'QUALITYMATRIX_GET_ERROR',

  CSATSUMMARY_GET: 'CSATSUMMARY_GET',
  CSATSUMMARY_GET_START: 'CSATSUMMARY_GET_START',
  CSATSUMMARY_GET_FINISHED: 'CSATSUMMARY_GET_FINISHED',
  CSATSUMMARY_GET_ERROR: 'CSATSUMMARY_GET_ERROR',

  CSATDETAILS_GET: 'CSATDETAILS_GET',
  CSATDETAILS_GET_START: 'CSATDETAILS_GET_START',
  CSATDETAILS_GET_FINISHED: 'CSATDETAILS_GET_FINISHED',
  CSATDETAILS_GET_ERROR: 'CSATDETAILS_GET_ERROR',

  RRSUMMARY_GET: 'RRSUMMARY_GET',
  RRSUMMARY_GET_START: 'RRSUMMARY_GET_START',
  RRSUMMARY_GET_FINISHED: 'RRSUMMARY_GET_FINISHED',
  RRSUMMARY_GET_ERROR: 'RRSUMMARY_GET_ERROR',

  RRDETAILS_GET: 'RRDETAILS_GET',
  RRDETAILS_GET_START: 'RRDETAILS_GET_START',
  RRDETAILS_GET_FINISHED: 'RRDETAILS_GET_FINISHED',
  RRDETAILS_GET_ERROR: 'RRDETAILS_GET_ERROR',

  PCDSUMMARY_GET: 'PCDSUMMARY_GET',
  PCDSUMMARY_GET_START: 'PCDSUMMARY_GET_START',
  PCDSUMMARY_GET_FINISHED: 'PCDSUMMARY_GET_FINISHED',
  PCDSUMMARY_GET_ERROR: 'PCDSUMMARY_GET_ERROR',

  PCDDETAILS_GET: 'PCDDETAILS_GET',
  PCDDETAILS_GET_START: 'PCDDETAILS_GET_START',
  PCDDETAILS_GET_FINISHED: 'PCDDETAILS_GET_FINISHED',
  PCDDETAILS_GET_ERROR: 'PCDDETAILS_GET_ERROR',

  PRODUCTIVITYSUMMARY_GET: 'PRODUCTIVITYSUMMARY_GET',
  PRODUCTIVITYSUMMARY_GET_START: 'PRODUCTIVITYSUMMARY_GET_START',
  PRODUCTIVITYSUMMARY_GET_FINISHED: 'PRODUCTIVITYSUMMARY_GET_FINISHED',
  PRODUCTIVITYSUMMARY_GET_ERROR: 'PRODUCTIVITYSUMMARY_GET_ERROR',

  PRODUCTIONAGENTS_GET: 'PRODUCTIONAGENTS_GET',
  PRODUCTIONAGENTS_GET_START: 'PRODUCTIONAGENTS_GET_START',
  PRODUCTIONAGENTS_GET_FINISHED: 'PRODUCTIONAGENTS_GET_FINISHED',
  PRODUCTIONAGENTS_GET_ERROR: 'PRODUCTIONAGENTS_GET_ERROR',

  AGENTLOGINS_GET: 'AGENTLOGINS_GET',
  AGENTLOGINS_GET_START: 'AGENTLOGINS_GET_START',
  AGENTLOGINS_GET_FINISHED: 'AGENTLOGINS_GET_FINISHED',
  AGENTLOGINS_GET_ERROR: 'AGENTLOGINS_GET_ERROR',

  AGENTVIEW_GET: 'AGENTVIEW_GET',
  AGENTVIEW_GET_START: 'AGENTVIEW_GET_START',
  AGENTVIEW_GET_FINISHED: 'AGENTVIEW_GET_FINISHED',
  AGENTVIEW_GET_ERROR: 'AGENTVIEW_GET_ERROR',

  GET_BUSINESS_WALLET: 'GET_BUSINESS_WALLET',
  GET_BUSINESS_WALLET_START: 'GET_BUSINESS_WALLET_START',
  GET_BUSINESS_WALLET_FINISHED: 'GET_BUSINESS_WALLET_FINISHED',
  GET_BUSINESS_WALLET_ERROR: 'GET_BUSINESS_WALLET_ERROR',

  GET_BUSINESS_TRANSACTIONS: 'GET_BUSINESS_TRANSACTIONS',
  GET_BUSINESS_TRANSACTIONS_START: 'GET_BUSINESS_TRANSACTIONS_START',
  GET_BUSINESS_TRANSACTIONS_FINISHED: 'GET_BUSINESS_TRANSACTIONS_FINISHED',
  GET_BUSINESS_TRANSACTIONS_ERROR: 'GET_BUSINESS_TRANSACTIONS_ERROR',

  REQUEST_BUSINESS_INVITATION: 'REQUEST_BUSINESS_INVITATION',
  REQUEST_BUSINESS_INVITATION_START: 'REQUEST_BUSINESS_INVITATION_START',
  REQUEST_BUSINESS_INVITATION_FINISHED: 'REQUEST_BUSINESS_INVITATION_FINISHED',
  REQUEST_BUSINESS_INVITATION_ERROR: 'REQUEST_BUSINESS_INVITATION_ERROR',

  BUSINESS_INVITATION: 'BUSINESS_INVITATION',
  BUSINESS_INVITATION_START: 'BUSINESS_INVITATION_START',
  BUSINESS_INVITATION_FINISHED: 'BUSINESS_INVITATION_FINISHED',
  BUSINESS_INVITATION_ERROR: 'BUSINESS_INVITATION_ERROR',

  FETCH_OFFERLETTER_CONFIG: 'FETCH_OFFERLETTER_CONFIG',
  FETCH_OFFERLETTER_CONFIG_START: 'FETCH_OFFERLETTER_CONFIG_START',
  FETCH_OFFERLETTER_CONFIG_FINISHED: 'FETCH_OFFERLETTER_CONFIG_FINISHED',
  FETCH_OFFERLETTER_CONFIG_ERROR: 'FETCH_OFFERLETTER_CONFIG_ERROR',

  TOGGLE_ANNEXURE_STATE: 'TOGGLE_ANNEXURE_STATE',
  TOGGLE_ANNEXURE_STATE_START: 'TOGGLE_ANNEXURE_STATE_START',
  TOGGLE_ANNEXURE_STATE_FINISHED: 'TOGGLE_ANNEXURE_STATE_FINISHED',
  TOGGLE_ANNEXURE_STATE_ERROR: 'TOGGLE_ANNEXURE_STATE_ERROR',

  CONFIGURE_PROJECTS_FOR_ANNEXURE: 'CONFIGURE_PROJECTS_FOR_ANNEXURE',
  CONFIGURE_PROJECTS_FOR_ANNEXURE_START:
    'CONFIGURE_PROJECTS_FOR_ANNEXURE_START',
  CONFIGURE_PROJECTS_FOR_ANNEXURE_FINISHED:
    'CONFIGURE_PROJECTS_FOR_ANNEXURE_FINISHED',
  CONFIGURE_PROJECTS_FOR_ANNEXURE_ERROR:
    'CONFIGURE_PROJECTS_FOR_ANNEXURE_ERROR',

  CONFIGURE_OFFER_LETTER: 'CONFIGURE_OFFER_LETTER',
  CONFIGURE_OFFER_LETTER_START: 'CONFIGURE_OFFER_LETTER_START',
  CONFIGURE_OFFER_LETTER_FINISHED: 'CONFIGURE_OFFER_LETTER_FINISHED',
  CONFIGURE_OFFER_LETTER_ERROR: 'CONFIGURE_OFFER_LETTER_ERROR',

  GET_CAMPAIGNS: 'GET_CAMPAIGNS',
  GET_CAMPAIGNS_START: 'GET_CAMPAIGNS_START',
  GET_CAMPAIGNS_FINISHED: 'GET_CAMPAIGNS_FINISHED',
  GET_CAMPAIGNS_ERROR: 'GET_CAMPAIGNS_ERROR',

  UPDATE_EXTERNAL_CAMPAIGN: 'UPDATE_EXTERNAL_CAMPAIGN',
  UPDATE_EXTERNAL_CAMPAIGN_START: 'UPDATE_EXTERNAL_CAMPAIGN_START',
  UPDATE_EXTERNAL_CAMPAIGN_FINISHED: 'UPDATE_EXTERNAL_CAMPAIGN_FINISHED',
  UPDATE_EXTERNAL_CAMPAIGN_ERROR: 'UPDATE_EXTERNAL_CAMPAIGN_ERROR',

  ADD_CAMPAIGN: 'ADD_CAMPAIGN',
  ADD_CAMPAIGN_START: 'ADD_CAMPAIGN_START',
  ADD_CAMPAIGN_FINISHED: 'ADD_CAMPAIGN_FINISHED',
  ADD_CAMPAIGN_ERROR: 'ADD_CAMPAIGN_ERROR',

  SET_CAMPAIGN_OR_TEAM_MODAL_SHOWING: 'SET_CAMPAIGN_OR_TEAM_MODAL_SHOWING',
  TOGGLE_UPDATE_ADDRESS_MODAL: 'TOGGLE_UPDATE_ADDRESS_MODAL',
};
