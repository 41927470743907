
export default {
  RESET_ROLES: 'RESET_ROLES',

  LIST_ROLES: 'LIST_ROLES',
  LIST_ROLES_START: 'LIST_ROLES_START',
  LIST_ROLES_FINISHED: 'LIST_ROLES_FINISHED',
  LIST_ROLES_ERROR: 'LIST_ROLES_ERROR',

  GET_ROLE: 'GET_ROLE',
  GET_ROLE_START: 'GET_ROLE_START',
  GET_ROLE_FINISHED: 'GET_ROLE_FINISHED',
  GET_ROLE_ERROR: 'GET_ROLE_ERROR',

  ADD_ROLE: 'ADD_ROLE',
  ADD_ROLE_START: 'ADD_ROLE_START',
  ADD_ROLE_FINISHED: 'ADD_ROLE_FINISHED',
  ADD_ROLE_ERROR: 'ADD_ROLE_ERROR',

  UPDATE_ROLE: 'UPDATE_ROLE',
  UPDATE_ROLE_START: 'UPDATE_ROLE_START',
  UPDATE_ROLE_FINISHED: 'UPDATE_ROLE_FINISHED',
  UPDATE_ROLE_ERROR: 'UPDATE_ROLE_ERROR',

  REMOVE_ROLE: 'REMOVE_ROLE',
  REMOVE_ROLE_START: 'REMOVE_ROLE_START',
  REMOVE_ROLE_FINISHED: 'REMOVE_ROLE_FINISHED',
  REMOVE_ROLE_ERROR: 'REMOVE_ROLE_ERROR',

  LIST_PERMISSIONS: 'LIST_PERMISSIONS',
  LIST_PERMISSIONS_START: 'LIST_PERMISSIONS_START',
  LIST_PERMISSIONS_FINISHED: 'LIST_PERMISSIONS_FINISHED',
  LIST_PERMISSIONS_ERROR: 'LIST_PERMISSIONS_ERROR',

  RESET_ADD_TEAM: 'RESET_ADD_TEAM',

  LIST_TEAMS: 'LIST_TEAMS',
  LIST_TEAMS_START: 'LIST_TEAMS_START',
  LIST_TEAMS_FINISHED: 'LIST_TEAMS_FINISHED',
  LIST_TEAMS_ERROR: 'LIST_TEAMS_ERROR',

  ADD_TEAM: 'ADD_TEAM',
  ADD_TEAM_START: 'ADD_TEAM_START',
  ADD_TEAM_FINISHED: 'ADD_TEAM_FINISHED',
  ADD_TEAM_ERROR: 'ADD_TEAM_ERROR',

  GET_TEAM: 'GET_TEAM',
  GET_TEAM_START: 'GET_TEAM_START',
  GET_TEAM_FINISHED: 'GET_TEAM_FINISHED',
  GET_TEAM_ERROR: 'GET_TEAM_ERROR',

  DELETE_TEAM: 'DELETE_TEAM',
  DELETE_TEAM_START: 'DELETE_TEAM_START',
  DELETE_TEAM_FINISHED: 'DELETE_TEAM_FINISHED',
  DELETE_TEAM_ERROR: 'DELETE_TEAM_ERROR',

  UPDATE_TEAM: 'UPDATE_TEAM',
  UPDATE_TEAM_START: 'UPDATE_TEAM_START',
  UPDATE_TEAM_FINISHED: 'UPDATE_TEAM_FINISHED',
  UPDATE_TEAM_ERROR: 'UPDATE_TEAM_ERROR',

  ADD_MEMBER: 'ADD_MEMBER',
  ADD_MEMBER_START: 'ADD_MEMBER_START',
  ADD_MEMBER_FINISHED: 'ADD_MEMBER_FINISHED',
  ADD_MEMBER_ERROR: 'ADD_MEMBER_ERROR',

  EDIT_MEMBER: 'EDIT_MEMBER',
  EDIT_MEMBER_START: 'EDIT_MEMBER_START',
  EDIT_MEMBER_FINISHED: 'EDIT_MEMBER_FINISHED',
  EDIT_MEMBER_ERROR: 'EDIT_MEMBER_ERROR',

  ADD_MEMBER_TO_TEAM: 'ADD_MEMBER_TO_TEAM',
  ADD_MEMBER_TO_TEAM_START: 'ADD_MEMBER_TO_TEAM_START',
  ADD_MEMBER_TO_TEAM_FINISHED: 'ADD_MEMBER_TO_TEAM_FINISHED',
  ADD_MEMBER_TO_TEAM_ERROR: 'ADD_MEMBER_TO_TEAM_ERROR',

  REMOVE_MEMBER_FROM_TEAM: 'REMOVE_MEMBER_FROM_TEAM',
  REMOVE_MEMBER_FROM_TEAM_START: 'REMOVE_MEMBER_FROM_TEAM_START',
  REMOVE_MEMBER_FROM_TEAM_FINISHED: 'REMOVE_MEMBER_FROM_TEAM_FINISHED',
  REMOVE_MEMBER_FROM_TEAM_ERROR: 'REMOVE_MEMBER_FROM_TEAM_ERROR',

  UPDATE_TEAM_USER: 'UPDATE_TEAM_USER',
  UPDATE_TEAM_USER_START: 'UPDATE_TEAM_USER_START',
  UPDATE_TEAM_USER_FINISHED: 'UPDATE_TEAM_USER_FINISHED',
  UPDATE_TEAM_USER_ERROR: 'UPDATE_TEAM_USER_ERROR',

  GET_TEAM_LABELS: 'GET_TEAM_LABELS',
  GET_TEAM_LABELS_START: 'GET_TEAM_LABELS_START',
  GET_TEAM_LABELS_FINISHED: 'GET_TEAM_LABELS_FINISHED',
  GET_TEAM_LABELS_ERROR: 'GET_TEAM_LABELS_ERROR',

  RESET_ADD_MEMBER: 'RESET_ADD_MEMBER',
  SET_SELECTED_TEAM: 'SET_SELECTED_TEAM',
};
