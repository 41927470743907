/* eslint-disable func-style */
import projectTypes from './project.types';
import ProjectActionTypes from './project.types';

export function clearCreatedProject() {
  return {
    type: ProjectActionTypes.CLEAR_CREATE_PROJECT,
  };
}

export function listProjects(filters) {
  return {
    type: ProjectActionTypes.LIST_PROJECTS,
    payload: filters,
  };
}

export function createProject(project) {
  return {
    type: ProjectActionTypes.CREATE_PROJECT,
    payload: { project },
  };
}

export function setProjectDetails(payload) {
  return {
    type: ProjectActionTypes.SET_PROJECT_DETAILS,
    payload,
  };
}

export function getProjectDetails(projectId, access) {
  return {
    type: ProjectActionTypes.PROJECT_DETAIL,
    payload: { projectId, access },
  };
}

export function getProjectApplications(
  projectId,
  applicationStatus,
  filters,
  page
) {
  return {
    type: ProjectActionTypes.PROJECT_APPLICANTS,
    payload: {
      projectId,
      applicationStatus,
      ...(filters || {}),
      page,
    },
  };
}

export function removeApplicant(applicant) {
  return {
    type: ProjectActionTypes.REMOVE_APPLICANT,
    payload: {
      applicant,
    },
  };
}

export function moveApplicantToRejected(applicant) {
  return {
    type: ProjectActionTypes.MOVE_APPLICANT_TO_REJECTED,
    payload: {
      applicant,
    },
  };
}

export function getProjectApplicationsV2(projectId, bucket, filters) {
  return {
    type: ProjectActionTypes.PROJECT_APPLICANTS_V2,
    payload: {
      projectId,
      bucket,
      filters,
    },
  };
}

export function getProjectApplicationsWithSubStatus(
  projectId,
  applicationStatus,
  applicationSubStatus,
  filters,
  page
) {
  return {
    type: ProjectActionTypes.PROJECT_APPLICANTS_SUBSTAT,
    payload: {
      projectId,
      applicationStatus,
      applicationSubStatus,
      ...(filters || {}),
      page,
    },
  };
}

export function freezeHiringForTimeSlot(projectId, timeSlotId) {
  return {
    type: ProjectActionTypes.FREEZE_PROJECT_TIMESLOT,
    payload: { projectId, timeSlotId },
  };
}

export function unfreezeHiringForTimeSlot(projectId, timeSlotId) {
  return {
    type: ProjectActionTypes.UNFREEZE_PROJECT_TIMESLOT,
    payload: { projectId, timeSlotId },
  };
}

export function updateProjectDetails(projectId, details) {
  return {
    type: ProjectActionTypes.UPDATE_PROJECT,
    payload: {
      updateType: 'basicDetails',
      projectId,
      ...details,
    },
  };
}

export function updateProjectPeopleRequirement(projectId, details) {
  return {
    type: ProjectActionTypes.UPDATE_PROJECT,
    payload: {
      updateType: 'peopleRequirement',
      projectId,
      ...details,
    },
  };
}

export function updateProjectSchedule(projectId, details) {
  return {
    type: ProjectActionTypes.UPDATE_PROJECT,
    payload: {
      updateType: 'schedule',
      projectId,
      ...details,
    },
  };
}

export function getProjectIntegrations(projectId) {
  return {
    type: ProjectActionTypes.PROJECT_INTEGRATIONS,
    payload: { projectId },
  };
}

export function updateProjectIntegration(projectId, integrationId, object) {
  return {
    type: ProjectActionTypes.PROJECT_UPDATE_INTEGRATION,
    payload: { projectId, integrationId, ...object },
  };
}

export function clearPager() {
  return { type: ProjectActionTypes.CLEAR_PAGER };
}

export function approveWorkReport(payload) {
  return {
    type: ProjectActionTypes.APPROVE_WORK_REPORT,
    payload,
  };
}

export function addCrm(payload) {
  return {
    type: ProjectActionTypes.ADD_CRM,
    payload,
  };
}

export function setWindowLocation(payload) {
  return {
    type: projectTypes.SET_WINDOW_LOCATION,
    payload,
  };
}

export function incrementUnreadChatCount(chatId) {
  return {
    type: projectTypes.INCREMENT_UNREAD_CHAT_COUNT,
    payload: { chatId },
  };
}

export function clearUnreadChatCount(chatId) {
  return {
    type: projectTypes.CLEAR_UNREAD_CHAT_COUNT,
    payload: { chatId },
  };
}

export function getPricingEstimate(payload) {
  return {
    type: ProjectActionTypes.GET_PRICING_ESTIMATE,
    payload,
  };
}
