/* eslint-disable default-param-last */
import _ from 'lodash';
import BusinessActionTypes from './business.types';

const INITIAL_STATE = {
  loading: false,
  errors: null,
  business: {},
  projects: [],
  businessKycForm: {},
  allBusinesses: null,
  loginData: {},
  ldLoading: false,
  avloading: false,
  allAgents: [],
  project: {},
  productivityLogs: null,
  productivityLogsError: null,

  payments: {
    walletBalance: null,
    transactions: [],
    paymentAuthorizations: [],
    loading: false,
    loadingTransactions: false,
  },
  offerLetter: {
    loading: false,
    annexures: [],
    templates: [],
    applicantOfferConfig: null,
  },
  campaigns: null,

  campaignFields: {},
  campaignFieldsLoading: false,
  campaignFieldLoadErrors: null,

  externalCampaigns: null,
  campaignsLoading: false,
  campaignOrTeamModalShowing: false,
  updateAddressModalOpen: false,
};

export default function businessReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BusinessActionTypes.BUSINESS_ADD_CAMPAIGN_FIELD_START:
    case BusinessActionTypes.BUSINESS_REMOVE_CAMPAIGN_FIELD_START:
    case BusinessActionTypes.BUSINESS_UPDATE_CAMPAIGN_FIELD_START:
      return {
        ...state,
        campaignFieldsLoading: true,
      };
    case BusinessActionTypes.BUSINESS_ADD_CAMPAIGN_FIELD_FINISHED:
    case BusinessActionTypes.BUSINESS_REMOVE_CAMPAIGN_FIELD_FINISHED:
    case BusinessActionTypes.BUSINESS_UPDATE_CAMPAIGN_FIELD_FINISHED:
      return {
        ...state,
        campaignFieldsLoading: false,
        campaignFields: {
          ...state.campaignFields,
          [action.originalPayload.campaignId]: action.payload.fields,
        },
      };
    case BusinessActionTypes.BUSINESS_ADD_CAMPAIGN_FIELD_ERROR:
    case BusinessActionTypes.BUSINESS_REMOVE_CAMPAIGN_FIELD_ERROR:
    case BusinessActionTypes.BUSINESS_UPDATE_CAMPAIGN_FIELD_ERROR:
      // Ignore errors quietly
      return {
        ...state,
        campaignFieldsLoading: false,
      };
    case BusinessActionTypes.BUSINESS_GET_CAMPAIGN_FIELDS_START:
      return {
        ...state,
        campaignFieldsLoading: true,
        campaignFieldLoadErrors: null,
        campaignFields: {
          ...state.campaignFields,
          [action.payload.campaignId]: null,
        },
      };
    case BusinessActionTypes.BUSINESS_GET_CAMPAIGN_FIELDS_FINISHED:
      return {
        ...state,
        campaignFieldsLoading: false,
        campaignFieldLoadErrors: null,
        campaignFields: {
          ...state.campaignFields,
          [action.originalPayload.campaignId]: action.payload.fields,
        },
      };
    case BusinessActionTypes.BUSINESS_GET_CAMPAIGN_FIELDS_ERROR:
      return {
        ...state,
        campaignFieldsLoading: false,
        campaignFieldLoadErrors: action.payload.errors,
        campaignFields: {
          ...state.campaignFields,
          [action.payload.campaignId]: null,
        },
      };
    case BusinessActionTypes.BUSINESS_GET_PRODUCTIVITY_LOGS_START:
      return {
        ...state,
        loading: true,
        productivityLogs: null,
        productivityLogsError: null,
      };
    case BusinessActionTypes.BUSINESS_GET_PRODUCTIVITY_LOGS_FINISHED:
      return {
        ...state,
        loading: false,
        productivityLogs: action.payload.productivityLogs,
        productivityLogsError: null,
      };
    case BusinessActionTypes.BUSINESS_GET_PRODUCTIVITY_LOGS_ERROR:
      return {
        ...state,
        loading: false,
        productivityLogs: null,
        productivityError: action.payload.errors,
      };
    case BusinessActionTypes.GET_ALL_BUSINESSES_START:
      return {
        ...state,
        loading: true,
        businesses: null,
      };
    case BusinessActionTypes.GET_ALL_BUSINESSES_FINISHED:
      return {
        ...state,
        loading: false,
        businesses: action.payload.businesses,
      };
    case BusinessActionTypes.GET_ALL_BUSINESSES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BusinessActionTypes.BUSINESS_SET_KYC_FORM:
      return {
        ...state,
        business: action.payload.business,
        businessKycForm: action.payload,
      };
    case BusinessActionTypes.BUSINESS_CLEAR_KYC_FORM:
      return {
        ...state,
        businessKycForm: {},
      };
    case BusinessActionTypes.BUSINESS_GET_FINISHED:
      return {
        ...state,
        loading: false,
        business: action.payload.business,
      };
    case BusinessActionTypes.BUSINESS_SAVE_INFO_FINISHED:
      return {
        ...state,
        allAgents: action.payload.allAgents,
        business: action.payload.business,
        saved: true,
        loading: false,
        errors: null,
        isExistingAccount: null,
      };
    case BusinessActionTypes.BUSINESS_GET_ERROR:
    case BusinessActionTypes.BUSINESS_SAVE_INFO_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload?.error || action.payload,
        saved: false,
        isExistingAccount:
          action.payload &&
          Object.entries(action.payload).find((a) =>
            _.endsWith(_.first(a[1] || []), 'already exists!')
          )?.length,
      };

    case BusinessActionTypes.BUSINESS_GET_START:
    case BusinessActionTypes.BUSINESS_SAVE_INFO_START:
      return {
        ...state,
        loading: true,
        errors: null,
        saved: null,
        isExistingAccount: null,
      };
    case BusinessActionTypes.GET_BUSINESS_TRANSACTIONS_START:
      return {
        ...state,
        loading: true,
        payments: { ...state.payments, loadingTransactions: true },
      };

    case BusinessActionTypes.GET_BUSINESS_WALLET_START:
      return {
        ...state,
        payments: { ...state.payments, loading: true },
      };

    case BusinessActionTypes.GET_BUSINESS_TRANSACTIONS_FINISHED:
      return {
        ...state,
        payments: {
          ...state.payments,
          transactions: action.payload.transactions,
          paymentAuthorizations: action.payload.paymentAuthorizations,
          loadingTransactions: false,
        },
      };
    case BusinessActionTypes.GET_BUSINESS_WALLET_FINISHED:
      return {
        ...state,
        payments: {
          ...state.payments,
          walletBalance: action.payload.balance,
          loading: false,
        },
      };

    case BusinessActionTypes.REQUEST_BUSINESS_INVITATION_START:
      return {
        ...state,
        loading: true,
      };

    case BusinessActionTypes.REQUEST_BUSINESS_INVITATION_FINISHED:
      return {
        ...state,
        loading: false,
        business: action.payload.business,
      };
    case BusinessActionTypes.REQUEST_BUSINESS_INVITATION_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BusinessActionTypes.BUSINESS_INVITATION_START:
      return {
        ...state,
        loading: true,
      };
    case BusinessActionTypes.BUSINESS_INVITATION_FINISHED:
      return {
        ...state,
        loading: false,
        requestedUser: action.payload.requestedUser,
        existingBusiness: action.payload.existingBusiness,
        inviteSuccess: !action.payload.existingBusiness,
      };
    case BusinessActionTypes.BUSINESS_INVITATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case BusinessActionTypes.FETCH_OFFERLETTER_CONFIG_START:
    case BusinessActionTypes.CONFIGURE_OFFER_LETTER_START:
      return {
        ...state,
        offerLetter: {
          ...state.offerLetter,
          loading: true,
        },
      };
    case BusinessActionTypes.FETCH_OFFERLETTER_CONFIG_FINISHED:
    case BusinessActionTypes.CONFIGURE_OFFER_LETTER_FINISHED:
    case BusinessActionTypes.CONFIGURE_PROJECTS_FOR_ANNEXURE_FINISHED:
      return {
        ...state,
        offerLetter: {
          ...state.offerLetter,
          loading: false,
          templates:
            action.payload.businessOfferLetterTemplates ||
            state.offerLetter.templates,
          annexures:
            action.payload.businessOfferAnnexures ||
            state.offerLetter.annexures,
          applicantOfferConfig: action.payload.applicantOfferConfig,
        },
      };

    case BusinessActionTypes.GET_CAMPAIGNS_START:
    case BusinessActionTypes.ADD_CAMPAIGN_START:
    case BusinessActionTypes.UPDATE_EXTERNAL_CAMPAIGN_START:
      return {
        ...state,
        campaignsLoading: true,
      };
    case BusinessActionTypes.GET_CAMPAIGNS_FINISHED:
    case BusinessActionTypes.ADD_CAMPAIGN_FINISHED:
    case BusinessActionTypes.UPDATE_EXTERNAL_CAMPAIGN_FINISHED:
      return {
        ...state,
        campaignsLoading: false,
        campaigns: action.payload.campaigns,
        externalCampaigns: action.payload.externalCampaigns,
      };
    case BusinessActionTypes.SET_CAMPAIGN_OR_TEAM_MODAL_SHOWING:
      return {
        ...state,
        campaignOrTeamModalShowing: action.payload.showing,
      };
      case BusinessActionTypes.TOGGLE_UPDATE_ADDRESS_MODAL:
        return {
          ...state,
          updateAddressModalOpen: !state?.updateAddressModalOpen,
        };
    default:
      return state;
  }
}
