/* eslint-disable import/prefer-default-export */
/* eslint-disable func-style */
import { takeLatest } from 'redux-saga/effects';
import ActionTypes from './workSessions.types';
import { commonAjaxHandler } from '../../url';

const getUrl = (ep) => `/jobs/workSession/${ep || ''}`;

const handleGetSessionDetails = commonAjaxHandler((p) => getUrl(p.endPoint), {
  method: 'POST',
  start: ActionTypes.SESSION_DETAILS_START,
  success: ActionTypes.SESSION_DETAILS_FINISHED,
  error: ActionTypes.SESSION_DETAILS_ERROR,
  payloadKeys: ['sessionDetails'],
});

const handleStartWork = commonAjaxHandler((p) => getUrl(p.endPoint), {
  method: 'POST',
  start: ActionTypes.START_WORK_START,
  success: ActionTypes.START_WORK_FINISHED,
  error: ActionTypes.START_WORK_ERROR,
  payloadKeys: ['sessionDetails'],
});

const handleEndWork = commonAjaxHandler((p) => getUrl(p.endPoint), {
  method: 'POST',
  start: ActionTypes.END_WORK_START,
  success: ActionTypes.END_WORK_FINISHED,
  error: ActionTypes.END_WORK_ERROR,
  payloadKeys: ['sessionDetails'],
});

const handleTakeABreak = commonAjaxHandler((p) => getUrl(p.endPoint), {
  method: 'POST',
  start: ActionTypes.TAKE_A_BREAK_START,
  success: ActionTypes.TAKE_A_BREAK_FINISHED,
  error: ActionTypes.TAKE_A_BREAK_ERROR,
  payloadKeys: ['sessionDetails'],
});

const handleEndBreak = commonAjaxHandler((p) => getUrl(p.endPoint), {
  method: 'POST',
  start: ActionTypes.END_BREAK_START,
  success: ActionTypes.END_BREAK_FINISHED,
  error: ActionTypes.END_BREAK_ERROR,
  payloadKeys: ['sessionDetails'],
});

export function* getSessionDetails() {
  yield takeLatest(ActionTypes.SESSION_DETAILS, handleGetSessionDetails);
}

export function* startWork() {
  yield takeLatest(ActionTypes.START_WORK, handleStartWork);
}

export function* endWork() {
  yield takeLatest(ActionTypes.END_WORK, handleEndWork);
}

export function* takeABreak() {
  yield takeLatest(ActionTypes.TAKE_A_BREAK, handleTakeABreak);
}

export function* endBreak() {
  yield takeLatest(ActionTypes.END_BREAK, handleEndBreak);
}
