import PaymentActionTypes from './payment.types';

const INITIAL_STATE = {
    loading: false,
    updateSuccess: null,
    updateErrors: null,
    create: {
        name: null,
        title: null,
        description: null,
        projectTypeId: null,
    },
    createErrors: null
};

export default function paymentReducer(state=INITIAL_STATE, action) {
    switch(action.type) {
        case PaymentActionTypes.PAYMENT_START:
            return {
                ...state,
                loading: true
            };
        case PaymentActionTypes.PAYMENT_ERROR:
            return {
                ...state,
                loading: false,
                createErrors: action.payload,
            };
        case PaymentActionTypes.PAYMENT_FINISHED:
            return {
                ...state,
                loading: false,
                createErrors: null,
                create: { },
                created: action.payload
            };
        default:
            return state

    }
}
