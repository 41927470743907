import React from 'react';
import { Modal } from 'react-bootstrap';

export default ({ isModalShowing, setModalShowing }) => {
  const clickedOk = () => {
    localStorage.setItem('disclaimerShown', true);
    setModalShowing(false);
  };

  return (
    <Modal
      centered
      keyboard={false}
      show={isModalShowing}
      onHide={() => setModalShowing(false)}
    >
      <div
        className="text-justify"
        style={{ padding: '20px', backgroundColor: '#fff' }}
      >
        <strong> Please Note: </strong>
        VOIZ is a marketplace for freelance work. All work opportunities that
        you find on VOIZ will purely be on a contractual basis and not on
        employment basis. By agreeing to this message, you understand that VOIZ
        is only an intermediary platform for you to find part time work and VOIZ
        is in no way offering you any kind of direct or indirect full time
        employment.
        <div className="py-2"></div>
        <div className="float-right">
          <button onClick={clickedOk} className="btn btn-blue-filled">
            OK
          </button>
        </div>
        <div className="clearfix"></div>
      </div>
    </Modal>
  );
};
