/* eslint-disable import/prefer-default-export */
/* eslint-disable func-style */
import { takeLatest } from 'redux-saga/effects';
import DataActionTypes from './kyc.types';
import { commonAjaxHandler } from '../../url';

const getAadhaarSessionHandler = commonAjaxHandler(
  (p) => `/kyc/aadhaar/session?aadhaar=${p.aadhaar}`,
  {
    method: 'get',
    start: DataActionTypes.GET_AADHAAR_SESSION_STARTED,
    success: DataActionTypes.GET_AADHAAR_SESSION_FINISHED,
    error: DataActionTypes.GET_AADHAAR_SESSION_ERROR,
    payloadKeys: ['session'],
  }
);

const verifyAadhaarOtpHandler = commonAjaxHandler('/kyc/aadhaar/verify', {
  method: 'post',
  start: DataActionTypes.VERIFY_AADHAAR_OTP_STARTED,
  success: DataActionTypes.VERIFY_AADHAAR_OTP_FINISHED,
  error: DataActionTypes.VERIFY_AADHAAR_OTP_ERROR,
  payloadKeys: ['error'],
});

export function* getAadhaarSession() {
  yield takeLatest(
    DataActionTypes.GET_AADHAAR_SESSION,
    getAadhaarSessionHandler
  );
}

export function* verifyAadhaarOtp() {
  yield takeLatest(DataActionTypes.VERIFY_AADHAAR_OTP, verifyAadhaarOtpHandler);
}
