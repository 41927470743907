import ActionTypes from './candidates.types';
import {takeLatest, call, put} from 'redux-saga/effects';
import {urlFor, fetch, commonAjaxHandler} from '../../url';

const handleFetchCandidateSearch = commonAjaxHandler(
  '/candidates',
  {
    method: 'POST',
    start: ActionTypes.SEARCH_CANDIDATES_FETCH_START,
    success: ActionTypes.SEARCH_CANDIDATES_FETCH_FINISHED,
    error: ActionTypes.SEARCH_CANDIDATES_FETCH_ERROR,
    payloadKeys: [ 'candidates', 'pager', 'businessProfiles' ],
  }
);

const handleInviteCandidate = commonAjaxHandler(
  '/candidates/invite',
  {
    method: 'POST',
    start: ActionTypes.INVITE_CANDIDATE_START,
    success: ActionTypes.INVITE_CANDIDATE_FINISHED,
    error: ActionTypes.INVITE_CANDIDATE_ERROR,
    payloadKeys: [],
  }
);

export function* candidateSearch() {
  yield takeLatest(
    ActionTypes.SEARCH_CANDIDATES,
    handleFetchCandidateSearch,
  );
}

export function* inviteCandidate() {
  yield takeLatest(
    ActionTypes.INVITE_CANDIDATE,
    handleInviteCandidate,
  );
}

