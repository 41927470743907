import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'video-react/dist/video-react.css';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import store from './redux/store';
import Toaster from './components/Toaster/Container';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-step-progress-bar/styles.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-range-slider-input/dist/style.css';

import config from './utils/config.json';

const AppErrorFallback = () => (
  <div className="bg-white page-404">
    <div>
      <i className="vz-icons ico-404" />
    </div>
    <div className="d-flex flex-column">
      <h1>Oopss...</h1>
      <h1 className="mb-3">Something just broke.</h1>
      <p className="mb-0">
        Don't worry! We have your back. We are looking into this right now.
      </p>
      <p>
        Please check back in sometime, we promise that we will iron this out....
      </p>
    </div>
  </div>
);

if (parseInt(window?.process?.env?.REACT_APP_ENABLE_SENTRY)) {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Sentry.ErrorBoundary fallback={AppErrorFallback}>
          <App />
          <Toaster />
        </Sentry.ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);
