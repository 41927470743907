import ActionTypes from './applicants.types';
import * as _ from "lodash";

const INITIAL_STATE = {
    search: {
        hasError: false,
        errorMessage: null,
        loading: false,
        filters: { },
        results: [],
        pager: null
    },

    alreadyApplied: { }
}

export default function applicantsReducer(state=INITIAL_STATE, action) {
    const {type, payload, originalPayload} = action;
    switch (type) {
      case ActionTypes.SEARCH_APPLICANTS_FETCH_START:
        return {
          ...state,
          search: {
            ...state.applicants,
            results: [...state.search.results],
            loading: _.isNull(state.search.pager),
            hasError: false,
            errorMessage: null,
            filters: payload,
          },
        };
      case ActionTypes.SEARCH_APPLICANTS_FETCH_FINISHED:
        return {
          ...state,
          search: {
            ...state.applicants,
            loading: false,
            results: payload.pager.isFirstFetch
              ? payload.applicants
              : [...state.search.results, ...payload.applicants],
              pager: payload.pager,
            },
            alreadyApplied: payload.alreadyApplied,
        };
      case ActionTypes.SEARCH_APPLICANTS_FETCH_ERROR:
        return {
          ...state,
          search: {
            ...state.applicants,
            loading: false,
            hasError: true,
            errorMessage: payload,
          },
        };
      default:
        return state;
    }
}