/* eslint-disable func-style */
import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import * as _ from 'lodash';
import { safelyRun } from '../utils/utils';

export const BASE_URL = window.process.env.REACT_APP_API_URL;
export const APP_URL = window.process.env.REACT_APP_URL;

export function serverUrlFor(path) {
  return urlFor(path).replace('/api/v1', '');
}

export function urlFor(path) {
  return BASE_URL + path;
}

export function appUrl(path) {
  return APP_URL + path;
}

export function getHeaders() {
  const token = localStorage.getItem('token');
  const finalHeaders = {};

  if (token) {
    finalHeaders.Authorization = token;
  }

  return finalHeaders;
}
export async function fetch(method = 'GET', url, parameters) {
  const response = await axios.request({
    method,
    url,
    data: parameters,
    headers: getHeaders(),
  });

  const { data } = response;

  if (!data.success && String(data.errors).match(/requires a valid user/i)) {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('rolesAndPermissions');
    localStorage.removeItem('isAdminLogin');
    window.location.reload();
  }

  return response;
}

export async function request(path, options) {
  safelyRun(options?.before);

  const response = await fetch(
    options.method || 'GET',
    urlFor(path),
    options.parameters
  );

  const { data } = response;

  if (data.success) safelyRun(options?.success, data, response);
  if (!data.success) safelyRun(options?.failure, data, response);

  safelyRun(options?.after);
}

export async function fetchCoordinatesOfIP() {
  const response = await fetch('GET', 'https://ipapi.co/json/');

  const { data } = response;

  return data || {};
}

export async function uploadFormData(path, options, editKey) {
  safelyRun(options?.before);

  const formData = new FormData();

  safelyRun(options?.appendFormData, formData);

  const response = await axios.post(
    `${urlFor(path)}${editKey ? '?key=' + editKey : ''}`,
    formData,
    {
      headers: getHeaders(),
      onUploadProgress: (progressEvent) => {
        safelyRun(options?.onUploadProgress, progressEvent);
      },
    }
  );

  const { data } = response;

  if (data.success) safelyRun(options?.success, data, response);
  if (!data.success) safelyRun(options?.failure, data, response);

  safelyRun(options?.after);
}

export function fetchBlob(url, options = {}) {
  return axios.request({
    method: options.method || 'GET',
    url,
    data: options.parameters || {},
    headers: getHeaders(),
    responseType: 'blob',
  });
}

export async function deleteDocument(documentId, options, editKey) {
  if (!documentId) return;

  safelyRun(options?.before);
  const response = await axios.delete(
    `${urlFor(`/document/${documentId}/delete`)}${
      editKey ? '?key=' + editKey : ''
    }`,
    {
      headers: getHeaders(),
    }
  );
  const { data } = response;

  if (data.success) safelyRun(options?.success, data, response);
  if (!data.success) safelyRun(options?.failure, data, response);

  safelyRun(options?.after);
}

const extractPayload = (data, options) => {
  const data_ = _.isObject(data.data) ? data.data : data;
  return _.fromPairs(_.map(options.payloadKeys, (key) => [key, data_[key]]));
};

const callItem = (options, key, ...args) => {
  if (options && options[key] && _.isFunction(options[key])) {
    return options[key](...args);
  }
};

export function commonAjaxHandler(path, options) {
  return function* (action) {
    try {
      const path_ = _.isFunction(path) ? path(action.payload) : path;

      yield call(callItem, options, 'beforeStart');
      yield put({
        type: options.start,
        payload: action.payload,
      });
      yield call(callItem, options, 'beforeCall');

      const { data } = yield call(
        fetch,
        options.method || 'GET',
        urlFor(path_),
        action.payload,
        options.headers
      );

      if (data.success) {
        yield put({
          type: options.success,
          payload: extractPayload(data, options),
          originalPayload: action.payload,
        });

        yield call(callItem, options, 'onSuccess', data, action.payload);
      } else {
        yield put({
          type: options.error,
          payload: data.errors || data.error,
          originalPayload: action.payload,
        });
      }

      yield call(callItem, options, 'onFinished', data);
    } catch (e) {
      console.trace(e);

      yield put({
        type: options.error,
        payload: e.message,
        originalPayload: action.originalPayload,
      });
    }
  };
}

export const getUriParams = (obj) =>
  Object.entries(obj)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');
