/* eslint-disable func-style */
import UploadTypes from './uploads.types';

const INITIAL_STATE = {
  loading: true,
  errors: null,

  idProofItem: null,
  kycDocuments: null,
  educationalDocuments: null,
  employmentDocuments: null,
  projectUploads: {},
  hadUploadedAllKycDocuments: false,
};

function uploadsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UploadTypes.GET_PROJECT_UPLOAD_ITEMS_START:
    case UploadTypes.GET_KYC_DOCUMENTS_ITEM_START:
      return {
        ...state,
        loading: false,
        idProofItem: action.payload.fileObject,
      };
    case UploadTypes.GET_KYC_DOCUMENTS_ITEM_FINISHED:
      return {
        ...state,
        loading: false,
        kycDocuments: action.payload.documents,
        hadUploadedAllKycDocuments: action.payload.hadUploadedAllKycDocuments,
      };

    case UploadTypes.GET_PROJECT_UPLOAD_ITEMS_FINISHED:
      return {
        ...state,
        loading: false,
        projectUploads: {
          ...state.projectUploads,
          [action.originalPayload.projectId]: action.payload.documents,
        },
      };
    default:
      return state;
  }
}

export default uploadsReducer;
