export const KycActionType = {
  GET_AADHAAR_SESSION: 'GET_AADHAAR_SESSION',
  GET_AADHAAR_SESSION_STARTED: 'GET_AADHAAR_SESSION_STARTED',
  GET_AADHAAR_SESSION_FINISHED: 'GET_AADHAAR_SESSION_FINISHED',
  GET_AADHAAR_SESSION_ERROR: 'GET_AADHAAR_SESSION_ERROR',

  VERIFY_AADHAAR_OTP: 'VERIFY_AADHAAR_OTP',
  VERIFY_AADHAAR_OTP_STARTED: 'VERIFY_AADHAAR_OTP_STARTED',
  VERIFY_AADHAAR_OTP_FINISHED: 'VERIFY_AADHAAR_OTP_FINISHED',
  VERIFY_AADHAAR_OTP_ERROR: 'VERIFY_AADHAAR_OTP_ERROR',
};

export default KycActionType;
