/* eslint-disable no-duplicate-case */
import ApplicationsActionTypes from './applications.types';

const INITIAL_STATE = {
  loading: false,
  applications: {},
  applicationReviews: {},
  applicationProductivity: {},
};

export default function applicationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ApplicationsActionTypes.APPLICATION_CHANGE_STATE_START:
      return {
        ...state,
        applications: {
          ...state.applications,
          [action.payload.applicationId]: {
            ...state.applications[action.payload.applicationId],
            stateChanging: true,
            stateChangeFailure: null,
            stateChangeSuccess: null,
          },
        },
      };

    case ApplicationsActionTypes.APPLICATION_CHANGE_STATE_FINISHED:
      return {
        ...state,
        applications: {
          ...state.applications,
          [action.originalPayload.applicationId]: {
            ...state.applications[action.originalPayload.applicationId],
            stateChanging: false,
            stateChangeSuccess: true,
            stateChangeFailure: false,
          },
        },
      };
    case ApplicationsActionTypes.APPLICATION_CHANGE_STATE_ERROR:
      return {
        ...state,
        applications: {
          ...state.applications,
          [action.originalPayload.applicationId]: {
            ...state.applications[action.originalPayload.applicationId],
            stateChanging: false,
            stateChangeFailure: action.payload,
            stateChangeSuccess: false,
          },
        },
      };
    case ApplicationsActionTypes.SET_APPLICATION_REVIEW:
      return {
        ...state,
        applicationReviews: {
          [action.payload.applicationId]: action.payload.review,
        },
      };
    case ApplicationsActionTypes.APPLICATION_PRODUCTIVITY_START:
      return {
        ...state,
        applicationProductivity: null,
        loading: true,
      };
    case ApplicationsActionTypes.APPLICATION_PRODUCTIVITY_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ApplicationsActionTypes.APPLICATION_PRODUCTIVITY_FINISHED:
      return {
        ...state,
        applicationProductivity: action.payload.appProductivity,
        loading: false,
      };
    case ApplicationsActionTypes.APPLICATION_PRODUCTIVITY_CALENDAR_START:
      return {
        ...state,
        applicationProductivity: null,
        loading: true,
      };
    case ApplicationsActionTypes.APPLICATION_PRODUCTIVITY_CALENDAR_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ApplicationsActionTypes.APPLICATION_PRODUCTIVITY_CALENDAR_FINISHED:
      return {
        ...state,
        applicationProductivity: {
          ...state.applicationProductivity,
          ...action.payload.appProductivity.calendar,
        },
        loading: false,
      };
    case ApplicationsActionTypes.APPLICATION_PRODUCTIVITY_REPORT_START:
      return {
        ...state,
        loading: true,
      };
    case ApplicationsActionTypes.APPLICATION_PRODUCTIVITY_REPORT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ApplicationsActionTypes.APPLICATION_PRODUCTIVITY_REPORT_FINISHED:
      return {
        ...state,
        applicationProductivity: {
          ...state.applicationProductivity,
          ...action.payload.appProductivity,
        },
        loading: false,
      };
    case ApplicationsActionTypes.APPLICATION_PRODUCTIVITY_DETAILS_START:
      return {
        ...state,
        loading: true,
      };
    case ApplicationsActionTypes.APPLICATION_PRODUCTIVITY_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ApplicationsActionTypes.APPLICATION_PRODUCTIVITY_DETAILS_FINISHED:
      return {
        ...state,
        applicationProductivity: {
          ...state.applicationProductivity,
          ...action.payload.appProductivity,
        },
        loading: false,
      };
    case ApplicationsActionTypes.GET_APPLICANT_RESUME_START:
      return {
        ...state,
        applications: {
          ...state.applications,
          [action.payload.applicationId]: {
            ...state.applications[action.payload.applicationId],
            resumeLoading: true,
            resume: null,
          },
        },
      };

    case ApplicationsActionTypes.GET_APPLICANT_RESUME_FINISHED:
      return {
        ...state,
        applications: {
          ...state.applications,
          [action.originalPayload.applicationId]: {
            ...state.applications[action.originalPayload.applicationId],
            resumeLoading: false,
            resume: action.payload.resume,
          },
        },
      };
    case ApplicationsActionTypes.GET_APPLICANT_RESUME_ERROR:
      return {
        ...state,
        applications: {
          ...state.applications,
          [action.originalPayload.applicationId]: {
            ...state.applications[action.originalPayload.applicationId],
            resumeLoading: false,
            resume: null,
          },
        },
      };
    default:
      return state;
  }
}
