/* eslint-disable func-style */
import JobActionTypes from './jobs.types';
import * as _ from 'lodash';

const INITIAL_STATE = {
  loading: false,
  jobs: [],
  pager: null,
  workspace: null,
  work: [],
  jobApplicationStatus: {},
  jobApplications: [],
  status: null,
  navLoading: true,
  workReportLoading: false,
  job: null,
};

function jobReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case JobActionTypes.GET_APPLICATIONS_START:
      return {
        ...state,
        loading: _.isNull(state.pager),
      };
    case JobActionTypes.GET_APPLICATIONS_FINISHED:
      return {
        ...state,
        loading: false,
        jobApplications:
          (action.payload &&
            action.payload.pager &&
            action.payload.pager.isFirstFetch) ||
          state.status !== action.payload.status ||
          !state.status
            ? action.payload.applications
            : [...state.jobApplications, ...action.payload.applications],
        statusCounts: action.payload.statusCounts,
        labelMapping: action.payload.labelMapping,
        pager: action.payload.pager,
        status: action.payload.status,
        navLoading: false,
      };
    case JobActionTypes.GET_APPLICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        jobApplications: null,
        pager: null,
      };
    case JobActionTypes.APPLY_FOR_JOB_START: {
      const projectId = action.payload.projectId;
      return {
        ...state,
        jobApplicationStatus: {
          ...state.jobApplicationStatus,
          [projectId]: {
            loading: true,
            applied: false,
          },
        },
      };
    }

    case JobActionTypes.APPLY_FOR_JOB_FINISHED: {
      const projectId = action.originalPayload.projectId;
      return {
        ...state,
        jobApplicationStatus: {
          ...state.jobApplicationStatus,
          [projectId]: {
            loading: false,
            applied: true,
          },
        },
      };
    }

    case JobActionTypes.APPLY_FOR_JOB_ERROR: {
      const { projectId } = action.originalPayload;
      return {
        ...state,
        jobApplicationStatus: {
          ...state.jobApplicationStatus,
          [projectId]: {
            loading: false,
            applied: false,
          },
        },
      };
    }

    case JobActionTypes.SEARCH_JOBS_START:
      return {
        ...state,
        loading: _.isNull(state.pager),
        jobs: state.jobs,
      };
    case JobActionTypes.SEARCH_JOBS_FINISHED: {
      const { jobs, pager } = action.payload;
      return {
        ...state,
        loading: false,
        jobs: pager?.isFirstFetch ? jobs : [...state.jobs, ...jobs],
        pager,
      };
    }
    case JobActionTypes.SEARCH_JOBS_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    case JobActionTypes.GET_WORK_START:
      return {
        ...state,
        loading: true,
      };
    case JobActionTypes.GET_WORK_FINISHED:
      return {
        ...state,
        loading: false,
        work: action.payload.work || [],
      };
    case JobActionTypes.GET_WORK_ERROR:
      return {
        ...state,
        loading: false,
      };
    case JobActionTypes.APPLY_LEAVE_START:
    case JobActionTypes.UPDATE_WORK_REPORT_TIMER_START:
      return {
        ...state,
        loading: true,
      };
    case JobActionTypes.UPDATE_WORK_REPORT_TIMER_FINISHED:
      return {
        ...state,
        loading: false,
        work: action.payload.work || [],
      };
    case JobActionTypes.UPDATE_WORK_REPORT_TIMER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case JobActionTypes.SAVE_WORK_REPORT_START:
      return {
        ...state,
        workReportLoading: true,
      };
    case JobActionTypes.SAVE_WORK_REPORT_FINISHED:
    case JobActionTypes.SAVE_WORK_REPORT_ERROR:
      return {
        ...state,
        workReportLoading: false,
      };
    case JobActionTypes.APPLY_LEAVE_FINISHED:
    case JobActionTypes.APPLY_LEAVE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case JobActionTypes.GET_JOB_DETAILS_START:
      return {
        ...state,
        loading: true,
      };
    case JobActionTypes.GET_JOB_DETAILS_FINISHED:
      return {
        ...state,
        job: action.payload.job,
        loading: false,
      };
    case JobActionTypes.GET_JOB_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default jobReducer;
