import React from 'react';
import { Helmet } from 'react-helmet';

const PageTitle = (props) => (
  <Helmet>
    <title>{props.title}</title>
    <meta name="title" content={props.title} />
    <meta
      name="description"
      content=" Customer Support and Telesales Marketplace."
    />

    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.voizworks.com/" />
    <meta property="og:title" content={props.title} />
    <meta
      property="og:description"
      content=" Customer Support and Telesales Marketplace."
    />
  </Helmet>
);

export default PageTitle;
