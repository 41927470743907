import _ from 'lodash';
import ActionTypes from './assessments.types';
import { useHistory } from 'react-router';

const INITIAL_STATE = {
  loading: false,
  windowLocation: '',
  assessments: [],
  totalProgress: [],
  questionDetail: null,
  uuid: null,
  attemptStats: null,
  isAttemptingAssessment: false,
  assessmentCompleted: false,
  userJobFunctionPreferences: [],
  selectedOption: null,
  assessmentProgress: {
    progress: 0,
    selectedAssessment: null,
    seenInstructions: false,
  },
  jobFunctionsProgress: null,
  voizFAQAssessment: null,
};

export default function assessmentsReducer(state = INITIAL_STATE, action) {
  const { type, payload, originalPayload } = action;

  switch (type) {
    case ActionTypes.GET_USER_ASSESSMENTS_START:
      return {
        ...state,
        loading: true,
        assessments: [],
        voizFAQAssessment: null,
      };

    case ActionTypes.GET_USER_ASSESSMENTS_FINISHED:
      if (payload.uuid) localStorage.setItem('assessmentUuid', payload.uuid);
      return {
        ...state,
        isAttemptingAssessment: !!payload.uuid,
        questionDetail: {
          loading: false,
          ...(_.isObject(payload.questionDetail) ? payload.questionDetail : {}),
        },
        assessmentProgress: {
          ...state.selectedAssessment,
          selectedAssessment: payload?.assessment,
          seenInstructions: !!payload?.assessment,
        },
        uuid: payload.uuid,
        loading: false,
        jobFunctionsProgress: payload.jobFunctionsProgress,
        voizFAQAssessment: payload.voizFAQAssessment,
        jobFunctionProgress: payload.jobFunctionProgress,
      };

    case ActionTypes.GET_USER_ASSESSMENTS_ERROR:
      return {
        ...state,
        assessments: [],
        totalProgress: [],
        loading: false,
        voizFAQAssessment: null,
      };

    case ActionTypes.SET_SELECTED_ASSESSMENT:
      localStorage.removeItem('assessmentUuid');
      return {
        ...state,
        questionDetail: null,
        attemptStats: null,
        isAttemptingAssessment: false,
        assessmentCompleted: false,
        uuid: null,
        assessmentProgress: {
          ...state.selectedAssessment,
          selectedAssessment: payload?.assessment,
          seenInstructions: false,
        },
        loading: false,
      };

    case ActionTypes.RESET_SELECTED_ASSESSMENT:
      return {
        ...state,
        assessmentCompleted: false,
        assessmentProgress: {
          ...state.selectedAssessment,
          selectedAssessment: payload?.assessment,
          seenInstructions: true,
        },
        loading: false,
      };

    case ActionTypes.GET_NEXT_QUESTION_START:
    case ActionTypes.GET_PREV_QUESTION_START:
      return {
        ...state,
        questionDetail: {
          loading: true,
        },
        selectedOption: null,
      };

    case ActionTypes.GET_NEXT_QUESTION_FINISHED:
    case ActionTypes.GET_PREV_QUESTION_FINISHED:
      return {
        ...state,
        questionDetail: {
          loading: false,
          ...(_.isObject(payload.questionDetail) ? payload.questionDetail : {}),
        },
        attemptStats: payload.attemptStats,
        assessmentCompleted: !!payload.attemptStats,
        isAttemptingAssessment: !payload.attemptStats,
        selectedOption: payload.selectedOption,
      };

    case ActionTypes.START_ASSESSMENT_START:
      localStorage.removeItem('assessmentToken');
      return {
        ...state,
        isAttemptingAssessment: false,
        questionDetail: {
          loading: true,
        },
        assessmentCompleted: false,
      };

    case ActionTypes.START_ASSESSMENT_FINISHED:
      if (payload.uuid) localStorage.setItem('assessmentUuid', payload.uuid);

      return {
        ...state,
        isAttemptingAssessment: true,
        questionDetail: {
          loading: false,
          ...(_.isObject(payload.questionDetail) ? payload.questionDetail : {}),
        },
        uuid: payload.uuid,
        attemptStats: null,
        linkUrl: payload.assessmentLink,
      };

    case ActionTypes.START_ASSESSMENT_ERROR:
    case ActionTypes.GET_NEXT_QUESTION_ERROR:
      return {
        ...state,
        questionDetail: { loading: false },
        linkUrl: null,
      };

    case ActionTypes.SUBMIT_TYPING_ASSESSMENT_START:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.SUBMIT_TYPING_ASSESSMENT_FINISHED:
    case ActionTypes.SUBMIT_TYPING_ASSESSMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.SET_WINDOW_LOCATION:
      return {
        ...state,
        windowLocation: action.payload,
      };

    default:
      return state;
  }
}
