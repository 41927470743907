/* eslint-disable radix */
/* eslint-disable wrap-regex */
/* eslint-disable func-style */
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import { urlFor, fetchBlob, request } from '../redux/url';
import { ToastIcon } from './styling';

const KB = 1024;
const MB = 1024 * KB;
const GB = 1024 * MB;

let rolesAndPermissions = null;

function setupRolesAndPermissions() {
  if (!rolesAndPermissions) {
    try {
      rolesAndPermissions = JSON.parse(
        localStorage.getItem('rolesAndPermissions')
      );
    } catch {}
    if (!rolesAndPermissions) {
      rolesAndPermissions = -1;
    }
  }
}

export function filterSubStatuses(subStatuses, user, projectId) {
  if (!subStatuses) {
    return subStatuses;
  }

  if (user.isVoizAdministrator || user.isBusinessAdmin) {
    return subStatuses;
  }

  setupRolesAndPermissions();

  if (rolesAndPermissions === -1) {
    return subStatuses;
  }

  for (const r of rolesAndPermissions) {
    const hasProject =
      projectId === null ? true : r.projectIds.includes(projectId);

    if (!hasProject) {
      continue;
    }

    const map = Object.fromEntries(
      r.role.permissions
        .filter((f) => f.name.match(/manage_application_status_/))
        .map((f) => [parseInt(f.name.split('_')[4]), true])
    );

    return subStatuses.filter((v) => map[v.id]);
  }

  return null;
}

export function hasPermission(user, permission) {
  if (!user) return false;

  const isProjectAdmin = localStorage.getItem('voizUser');

  if (
    user.isVoizAdministrator ||
    user.isBusinessAdmin ||
    isProjectAdmin ||
    user?.userRole === 'business_admin'
  ) {
    return true;
  }

  if (_.isArray(user?.permissions) && user?.permissions?.includes(permission))
    return true;

  const isVoizAdmin =
    (user.isBusinessAdmin || user?.userRole === 'business_admin') &&
    user.businessId === 216;
  if (isVoizAdmin) return true;

  if (!user?.userRole) return false;

  const permissions = {
    recruiter: ['manage_hiring'],
    hiring_manager: ['manage_hiring', 'add_job', 'add_instance'],
    campaign_manager: [
      'add_campaign',
      'add_team',
      'teams',
      'campaigns',
      'add_instance',
      'team_attendance',
    ],
    team_lead: ['campaigns', 'team_attendance'],
    trainer: ['campaigns'],
    quality_analyst: ['campaigns'],
    finance_manager: ['attendance_master', 'teams', 'team_attendance'],
  };

  return permissions[user?.userRole]?.includes(permission) || false;
}

export function limitText(text, size = 200) {
  if (!text) return '';
  if (text.length <= size) return text;

  return text.substr(0, size) + '...';
}

export async function downloadFileObject(fileObject, key) {
  const response = await fetchBlob(
    urlFor(
      `/document/${fileObject.fileObjectId || fileObject.id}/download?key=${
        key || ''
      }`
    )
  );

  const url = window.URL.createObjectURL(new Blob([response.data]));

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileObject.fileName);
  document.body.appendChild(link);
  link.click();
}

export function parseSize(size) {
  if (_.isNull(size) || _.isUndefined(size)) {
    return Number.MAX_VALUE;
  }

  if (_.isNumber(size)) {
    return size;
  }

  if (size.match(/kb/i)) {
    return parseInt(size.replace(/kb/i, '').trim()) * KB;
  }

  if (size.match(/mb/i)) {
    return parseInt(size.replace(/mb/i, '').trim()) * MB;
  }

  if (size.match(/gb/i)) {
    return parseInt(size.replace(/gb/i, '').trim()) * GB;
  }

  return Number.MAX_VALUE;
}

export function humanizeSize(value) {
  if (!value || value === 0) {
    return '0';
  }

  const value_ = parseInt(value);

  if (value_ > GB) {
    return `${(value_ / GB).toFixed(1)}GB`;
  }
  if (value_ > MB) {
    return `${(value_ / MB).toFixed(1)}MB`;
  }
  if (value_ > KB) {
    return `${(value_ / KB).toFixed(1)}KB`;
  }
  return `${value_} bytes`;
}

export function hasUserLoggedIn(userState) {
  return !!userState.token && !!userState.user;
}

const sanitizeInput = (input) => {
  // Remove script tags
  const sanitizedInput = input.replace(
    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
    ''
  );

  // Remove HTML tags
  const strippedInput = sanitizedInput.replace(/(<([^>]+)>)/gi, '');

  return strippedInput;
};

export function setItemHelper(state, setState) {
  const setItem = (key) => (value) => {
    setState({
      ...state,
      [key]: _.isString(value) ? sanitizeInput(value) : value,
    });
  };

  return setItem;
}

export function safelyRun(cb, ...args) {
  if (cb && _.isFunction(cb)) {
    try {
      return cb(...args);
    } catch (e) {
      console.trace(e);
    }
  }
}

export function isValidPager(pager) {
  if (!_.isObject(pager)) {
    return false;
  }

  const pagerKeys = [
    'currentPage',
    'totalPages',
    'entriesPerPage',
    'totalEntries',
    'entriesInCurrentPage',
  ];

  return _.every(pagerKeys, (v) => pager[v] !== undefined);
}

export function toLabel(str) {
  if (!str) return '';
  return _.capitalize(str.split(/-/).join(' ')).replaceAll('_', ' ');
}

export const finalizeParams = (searchQuery, params) => {
  const finalParams = new URLSearchParams(searchQuery);
  Object.keys(params).forEach((k) => {
    finalParams.set(k, params[k]);
  });
  return finalParams;
};

export const searchParamsToObject = (searchParams) => {
  const result = {};
  searchParams.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

const precision = 100;

export function convert(val) {
  return Math.round(val * precision);
}

export function toSeconds(val) {
  if (val === 0) {
    return 0;
  }

  return val / precision;
}

export function format(val) {
  return toSeconds(val).toFixed(2);
}

export const setTypingSession = (sessionToken) => {
  localStorage.setItem('sessionToken', sessionToken);
};

export const getTypingSession = () => localStorage.getItem('sessionToken');

export const WEEKDAYS = 'Sun Mon Tue Wed Thu Fri Sat'
  .split(' ')
  .map((value, i) => ({ id: i, label: value, name: value, value: i }));
export const LANGUAGES =
  'English Hindi Kannada Telugu Tamil Bengali Gujarati Malayalam Marathi'
    .split(' ')
    .map((value, i) => ({ id: i, label: value, name: value, value: i }));

export const JobLocation = 'Remote,On-Premises,Remote | On-Premisis'
  .split(',')
  .map((value, i) => ({ id: i + 1, label: value, name: value, value: i + 1 }));

export const HomeOfficeSetup =
  'Basic(smartphone, internet headphone) & Advanced(laptop, smartphone, internet, headphone, power backup)'
    .split(' & ')
    .map((value, i) => ({
      id: i + 1,
      label: value,
      name: value,
      value: i + 1,
    }));

export const CertificationLevels = 'Expert & Intermediate & Beginner'
  .split(' & ')
  .map((value, i) => ({ id: i + 1, label: value, name: value, value }));

export const GetFourCFilters = 'Expert,Intermediate,Novice'
  .split(',')
  .map((value, i) => ({
    id: i + 1,
    label: value,
    name: value.toLowerCase(),
    value: i + 1,
  }));

export const languageProficiencies = 'Expert,Advanced,Intermediate,Novice'
  .split(',')
  .map((value, i) => ({
    id: i + 1,
    label: value,
    name: value.toLowerCase(),
    value: i + 1,
  }));

export const mergeWithObjectFromLocalStorage = (key, addedObject) => {
  const currentObject = JSON.parse(localStorage.getItem(key) || '{}');
  const newObject = {
    ...currentObject,
    ...addedObject,
  };
  localStorage.setItem(key, JSON.stringify(newObject));
};

export const DEBUG = {
  _log(label, color, ...args) {
    console.log(
      `%cVOIZ %c${label}`,
      'background-color: #84cc16; color: #fff; padding: 10px; border-raidus: 10px ',
      `background-color: ${color}; color: #fff; padding: 10px; border-raidus: 10px`,
      ...args
    );
  },

  log(...args) {
    this._log('DEBUG', 'blue', ...args);
  },

  error(...args) {
    this._log('ERROR', 'red', ...args);
  },

  warn(...args) {
    this._log('ERROR', 'orange', ...args);
  },

  info(...args) {
    this._log('ERROR', 'indigo', ...args);
  },

  trace(...args) {
    console.trace(...args);
  },
};

export const getTitleForEnv = (title) => {
  const env = window.process.env.REACT_APP_NODE_ENV;
  if (env === 'production') {
    return title;
  }

  title = 'VOIZ';

  return title;
};

export const differencebWYears = (start, end) => {
  if (!start || !end) {
    return '';
  }
  return `${end.diff(start, 'years')} Years`;
};

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / 1024 ** i, 2) + ' ' + sizes[i];
};

export const toastOptions = {
  position: 'top-center',
  autoClose: 5000,
  pauseOnHover: true,
  icon: ToastIcon,
};

export const alertToast = (
  type = 'error',
  message = 'Something Went Wrong!'
) => {
  try {
    if (!toast.isActive(JSON.stringify(message))) {
      toast.dismiss();
    }
  } catch (err) {
    DEBUG._log(err);
  }
  toast[type](message, { ...toastOptions, toastId: JSON.stringify(message) });
};

export const padNumberTo2Digits = (number) => (number < 10 ? '0' : '') + number;

export const selectorSchema = (str) => ({
  id: str,
  label: str,
  name: str?.toString()?.toLowerCase()?.replaceAll(' ', '-'),
  value: str,
});

const addUrlParams = (url, params) => {
  if (!params || Object.keys(params).length === 0) {
    return url;
  }

  const _url = new URL(url);
  const query = new URLSearchParams(_url.search);

  // Set all the keys
  Object.keys(params).forEach((key) => {
    if (params[key]) query.set(key, params[key]);
  });

  // Set the query params
  _url.search = query.toString();

  return _url.toString();
};

export const visitVoizCrm = async ({
  project,
  applicationId,
  beforeLoad,
  afterLoad,
  projectId,
  redirect,
  campaignId,
  credilio,
}) =>
  await request(
  `/project/${project?.id || projectId || 0}/generateCrmToken?applicationId=${
    applicationId || ''
  }&credilio=${credilio ? 1 : 0}`,
  {
    method: 'POST',
    before: beforeLoad,
    success: (data) => {
      if (data.success && data.url) {
        const finalUrl = addUrlParams(data.url, { redirect, campaignId });
        window.location.href = finalUrl;
      }
    },
    after: afterLoad,
  }
);

export function getEduCertExpMeta(data, isFor) {
  if (!_.isObject(data)) return;

  const term = [];

  if (isFor === 'CERTIFICATION') {
    const validThru = [];
    const meta = [];
    if (data.issuedBy) meta.push(data.issuedBy);

    if (data.certificationLevel?.label) {
      meta.push(`${data.certificationLevel?.label} Level`);
    }

    if (moment(data.issuedDate).isValid()) {
      validThru.push(`Issued ${moment(data.issuedDate).format('MMM YYYY')}`);
    }

    if (moment(data.expirationDate).isValid() && !data.doesNotExpire) {
      validThru.push(
        `Expires ${moment(data.expirationDate).format('MMM YYYY')}`
      );
    } else {
      validThru.push('No Expiration Date');
    }

    return { meta: meta.join(' | '), validThru: validThru.join(' | ') };
  }

  if (moment(data.startDate).isValid()) {
    term.push(moment(data.startDate).format('MMM YYYY'));
  }

  if (moment(data.endDate).isValid()) {
    term.push(moment(data.endDate).format('MMM YYYY'));
  }

  const conditionForOnGng =
    isFor === 'EXPERIENCE' ? data.isCurrentlyWorking : data.onGoing;

  if (conditionForOnGng || !data.endDate || !moment(data.endDate).isValid()) {
    term.push('Present');
  }

  if (moment(data.startDate).isValid()) {
    const duration = (
      moment(data.endDate).isValid() ? moment(data.endDate) : moment()
    ).diff(moment(data.startDate), 'months');
    if (duration < 12) {
      term.push(duration === 0 ? '1 Month' : `${duration} Months`);
    } else {
      term.push(`${Math.round(duration / 12)} Years`);
    }
  }

  if (isFor === 'EXPERIENCE') {
    const meta = [];
    meta.push(data?.company?.label || data.companyName);

    if (data?.employmentType?.label) meta.push(data?.employmentType?.label);

    if (data?.city?.label) meta.push(data?.city?.label);

    return { meta: meta.join('  |  '), term: term.join(' - ') };
  }

  const courseAndFieldOfStudy = [];
  if (data.stream?.label) {
    courseAndFieldOfStudy.push(_.capitalize(data.stream?.label));
  }

  if (data.course?.label) courseAndFieldOfStudy.push(data.course?.label);

  if (data.industry?.label) {
    courseAndFieldOfStudy.push(data.industry?.label);
  }

  let location = '';
  if (data.city?.label) location = data.city?.label;
  return {
    meta: `${courseAndFieldOfStudy.join(' - ')} | ${location}`,
    term: term.join(' - '),
  };
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function getHMSfromSecs(totalSecs) {
  const h = _.padStart(Math.floor(totalSecs / 3600), 2, '0');
  const m = _.padStart(Math.floor((totalSecs % 3600) / 60), 2, '0');
  const s = _.padStart(Math.floor((totalSecs % 3600) % 60), 2, '0');
  return `${h}:${m}:${s} `;
}

export function getTimeRanges(interval = 30) {
  const ranges = [];
  const date = new Date();
  const fmt = {
    hour: 'numeric',
    minute: 'numeric',
  };

  for (let minutes = 0; minutes < 24 * 60; minutes += interval) {
    date.setHours(0);
    date.setMinutes(minutes);
    ranges.push(date.toLocaleTimeString('ru', fmt));
  }
  return ranges;
}

export function userInitials(user) {
  const [firstName, lastName] = (user.fullName || user.userName)
    .toString()
    .split(/\s+/, 2);
  return lastName
    ? [firstName[0], lastName[0]].join('').toUpperCase()
    : firstName.substring(0, 2).toUpperCase();
}

const { floor } = Math;
const { abs } = Math;
const { log } = Math;
const { round } = Math;
const { min } = Math;
const abbrev = ['K', 'Mil', 'Bil']; // abbreviations in steps of 1000x; extensible if need to edit

function rnd(n, precision) {
  const prec = 10 ** precision;
  return round(n * prec) / prec;
}

export function abbrivatePricing(n) {
  let base = floor(log(abs(n)) / log(1000));
  const suffix = abbrev[min(abbrev.length - 1, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix ? rnd(n / 1000 ** base, 2) + suffix : String(n);
}

export function canAddBusinessRoles(teams, role) {
  return !teams?.find((a) => a.role === role);
}

export function handleFaceIoError(errCode, faceio) {
  let error = null;

  const fioErrCode = faceio.fetchAllErrorCodes();

  switch (errCode) {
    case fioErrCode.PERMISSION_REFUSED:
      error = 'Access to the Camera stream was denied by the end user';
      break;
    case fioErrCode.NO_FACES_DETECTED:
      error =
        'No faces were detected during the enroll or authentication process';
      break;
    case fioErrCode.UNRECOGNIZED_FACE:
      error = "Unrecognized face on this application's Facial Index";
      break;
    case fioErrCode.MANY_FACES:
      error = 'Two or more faces were detected during the scan process';
      break;
    case fioErrCode.FACE_DUPLICATION:
      error =
        'User enrolled previously (facial features already recorded). Cannot enroll again!';
      break;
    case fioErrCode.MINORS_NOT_ALLOWED:
      error = 'Minors are not allowed to enroll on this application!';
      break;
    case fioErrCode.PAD_ATTACK:
      error =
        'Presentation (Spoof) Attack (PAD) detected during the scan process';
      break;
    case fioErrCode.FACE_MISMATCH:
      error =
        'Calculated Facial Vectors of the user being enrolled do not matches';
      break;
    case fioErrCode.WRONG_PIN_CODE:
      error = 'Wrong PIN code supplied by the user being authenticated';
      break;
    case fioErrCode.PROCESSING_ERR:
      error = 'Server side error';
      break;
    case fioErrCode.UNAUTHORIZED:
      error =
        'Your application is not allowed to perform the requested operation (eg. Invalid ID, Blocked, Paused, etc.). Refer to the FACEIO Console for additional information';
      break;
    case fioErrCode.TERMS_NOT_ACCEPTED:
      error =
        'Terms & Conditions set out by FACEIO/host application rejected by the end user';
      break;
    case fioErrCode.UI_NOT_READY:
      error =
        'The FACEIO Widget could not be (or is being) injected onto the client DOM';
      break;
    case fioErrCode.SESSION_EXPIRED:
      error =
        'Client session expired. The first promise was already fulfilled but the host application failed to act accordingly';
      break;
    case fioErrCode.TIMEOUT:
      error =
        'Ongoing operation timed out (eg, Camera access permission, ToS accept delay, Face not yet detected, Server Reply, etc.)';
      break;
    case fioErrCode.TOO_MANY_REQUESTS:
      error =
        'Widget instantiation requests exceeded for freemium applications. Does not apply for upgraded applications';
      break;
    case fioErrCode.EMPTY_ORIGIN:
      error = 'Origin or Referer HTTP request header is empty or missing';
      break;
    case fioErrCode.FORBIDDDEN_ORIGIN:
      error = 'Domain origin is forbidden from instantiating fio.js';
      break;
    case fioErrCode.FORBIDDDEN_COUNTRY:
      error = 'Country ISO-3166-1 Code is forbidden from instantiating fio.js';
      break;
    case fioErrCode.SESSION_IN_PROGRESS:
      error = 'Another authentication or enrollment session is in progress';
      break;
    case fioErrCode.NETWORK_IO:
    default:
      error =
        'Error while establishing network connection with the target FACEIO processing node';
      break;
  }
  alertToast('error', error);
}

export function secondsToHMS(totalSeconds) {
  if (!totalSeconds) return '00:00:00';
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);
  let seconds = Math.floor(totalSeconds % 60);

  hours = (hours < 10 ? '0' : '') + hours;
  minutes = (minutes < 10 ? '0' : '') + minutes;
  seconds = (seconds < 10 ? '0' : '') + seconds;

  return hours + ':' + minutes + ':' + seconds;
}

export function detectOS() {
  const { userAgent } = window.navigator;
  const { platform } = window.navigator;
  let os = null;

  if (/Windows/.test(platform)) {
    if (/Windows NT 11.0/.test(userAgent)) {
      os = 'Windows 11';
    } else if (/Windows NT 10.0/.test(userAgent)) {
      os = 'Windows 10';
    } else if (/Windows NT 6.3/.test(userAgent)) {
      os = 'Windows 8.1';
    } else if (/Windows NT 6.2/.test(userAgent)) {
      os = 'Windows 8';
    } else if (/Windows NT 6.1/.test(userAgent)) {
      os = 'Windows 7';
    } else if (/Windows NT 6.0/.test(userAgent)) {
      os = 'Windows Vista';
    } else if (/Windows NT 5.1/.test(userAgent)) {
      os = 'Windows XP';
    }
  } else if (/Mac/.test(platform)) {
    os = 'Mac OS X';
    const match = userAgent.match(/Mac OS X (\d+[._]\d+(?:[._]\d+)?)/);
    if (match) {
      os += ' ' + match[1].replace(/_/g, '.');
    }
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

export function checkPlatformAccessiblity() {
  const detectedOS = detectOS();
  if (detectedOS && detectedOS.startsWith('Windows')) {
    const version = detectedOS.split(' ')[1];
    if (version) {
      const majorVersion = parseInt(version.split('.')[0]);
      return {
        isDeprecatedWindowsVersion: majorVersion < 10,
        detectedOS,
      };
    }
  }
  return {
    isDeprecatedWindowsVersion: false,
    detectedOS,
  };
}
