/* eslint-disable func-style */
import DataActionTypes from './data.types';

export function getPricingTypes() {
  return { type: DataActionTypes.FETCH_PRICING_TYPES };
}

export function getLanguages() {
  return { type: DataActionTypes.FETCH_LANGUAGES };
}

export function getProjectTypes() {
  return { type: DataActionTypes.FETCH_PROJECT_TYPES };
}

export function getSubProjectTypes(projectTypeId) {
  return {
    type: DataActionTypes.FETCH_SUB_PROJECT_TYPES,
    payload: { projectTypeId },
  };
}

export function getIndustries() {
  return { type: DataActionTypes.FETCH_INDUSTRIES };
}

export function getTimeSlots() {
  return { type: DataActionTypes.FETCH_TIME_SLOTS };
}

export function getApplicationStatuses() {
  return { type: DataActionTypes.FETCH_APPLICATION_STATUSES };
}

export function getExpertiseLevels() {
  return { type: DataActionTypes.FETCH_EXPERTISE_LEVELS };
}

export function getQualifications() {
  return { type: DataActionTypes.FETCH_QUALIFICATIONS };
}

export function getCourses(qualificationId) {
  return { type: DataActionTypes.FETCH_COURSES, payload: { qualificationId } };
}

export function getCrms() {
  return { type: DataActionTypes.FETCH_CRMS };
}

export function getAssessmentTypes() {
  return { type: DataActionTypes.FETCH_ASSESSMENT_TYPES };
}

export function getTypingAssessmentTypes() {
  return { type: DataActionTypes.FETCH_TYPINGASSESSMENTTYPE_TYPES };
}

export function getJobLocationTypes() {
  return { type: DataActionTypes.FETCH_JOBLOCATION_TYPES };
}

export function getWorkPreferenceTypes() {
  return { type: DataActionTypes.FETCH_WORKPREFERENCE_TYPES };
}

export function getFeatureFlags() {
  return { type: DataActionTypes.FETCH_FEATURE_FLAGS };
}

export function getSkills(query, excludeUserSkills = 0) {
  return {
    type: DataActionTypes.FETCH_SKILLS,
    payload: { query },
  };
}

export function fetchAddressOfGeoLocation(coordinates) {
  return {
    type: DataActionTypes.FETCH_ADDRESS_OF_GEOLOCATION,
    payload: { coordinates },
  };
}

export function getDetailsOfIfsc(ifscCode) {
  return {
    type: DataActionTypes.FETCH_BANK_WITH_IFSC,
    payload: { ifscCode },
  };
}

export function resetDetailsOfIfsc() {
  return {
    type: DataActionTypes.RESET_DETAILS_OF_IFSC,
  };
}

export function getWorkSpaceSetup() {
  return {
    type: DataActionTypes.FETCH_WORK_SPACE_SETUP,
  };
}

export function getLeaveCategories() {
  return {
    type: DataActionTypes.FETCH_LEAVE_CATEGORIES,
  };
}

export function getCompanyTypes() {
  return {
    type: DataActionTypes.FETCH_COMPANY_TYPES,
  };
}
export function getRejectReasons() {
  return { type: DataActionTypes.FETCH_REJECT_REASONS };
}

export function getRankings() {
  return { type: DataActionTypes.FETCH_RANKINGS };
}
