export default {

    NOTIFICATION_GET_UNREAD_COUNT:  'NOTIFICATION_GET_UNREAD_COUNT',
    NOTIFICATION_GET_UNREAD_COUNT_START:  'NOTIFICATION_GET_UNREAD_COUNT_START',
    NOTIFICATION_GET_UNREAD_COUNT_FINISHED:  'NOTIFICATION_GET_UNREAD_COUNT_FINISHED',
    NOTIFICATION_GET_UNREAD_COUNT_ERROR:  'NOTIFICATION_GET_UNREAD_COUNT_ERROR',

    NOTIFICATION_MARK_AS_READ: 'NOTIFICATION_MARK_AS_READ',
    NOTIFICATION_MARK_AS_READ_START: 'NOTIFICATION_MARK_AS_READ_START',
    NOTIFICATION_MARK_AS_READ_FINISHED: 'NOTIFICATION_MARK_AS_READ_FINISHED',
    NOTIFICATION_MARK_AS_READ_ERROR: 'NOTIFICATION_MARK_AS_READ_ERROR',

    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    GET_NOTIFICATIONS_START: 'GET_NOTIFICATIONS_START',
    GET_NOTIFICATIONS_FINISHED: 'GET_NOTIFICATIONS_FINISHED',
    GET_NOTIFICATIONS_ERROR: 'GET_NOTIFICATIONS_ERROR',

    GET_NOTIFICATION_SNAPSHOT: 'GET_NOTIFICATION_SNAPSHOT',
    GET_NOTIFICATION_SNAPSHOT_START: 'GET_NOTIFICATION_SNAPSHOT_START',
    GET_NOTIFICATION_SNAPSHOT_FINISHED: 'GET_NOTIFICATION_SNAPSHOT_FINISHED',
    GET_NOTIFICATION_SNAPSHOT_ERROR: 'GET_NOTIFICATION_SNAPSHOT_ERROR',

    CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
    CLEAR_NOTIFICATIONS_START: 'CLEAR_NOTIFICATIONS_START',
    CLEAR_NOTIFICATIONS_FINISHED: 'CLEAR_NOTIFICATIONS_FINISHED',
    CLEAR_NOTIFICATIONS_ERROR: 'CLEAR_NOTIFICATIONS_ERROR',

    REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
    REMOVE_NOTIFICATION_START: 'REMOVE_NOTIFICATION_START',
    REMOVE_NOTIFICATION_FINISHED: 'REMOVE_NOTIFICATION_FINISHED',
    REMOVE_NOTIFICATION_ERROR: 'REMOVE_NOTIFICATION_ERROR',
}
