/* eslint-disable no-nested-ternary */
/* eslint-disable func-style */
import React, { Suspense, useState, useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import ReactGA from 'react-ga4';
import Pages from './pages/all';
import {
  initializeChat,
  setUserAndToken,
  signoutUser,
  setUnreadChatCount,
  loginReset,
} from './redux/reducers/user/user.actions';

import { getFeatureFlags } from './redux/reducers/data/data.actions';

import {
  DEBUG,
  getTitleForEnv,
  hasPermission,
  hasUserLoggedIn,
} from './utils/utils';
import LoadingIndicator from './components/LoadingIndicator';
import AgentDisclaimerModal from './components/Modals/AgentDisclaimerModal';
import { BASE_URL } from './redux/url';

import './App.scss';
import PageTitle from './components/PageTitle';

import { BusinessType } from './utils/data';
import { setWindowLocation } from './redux/reducers/assessments/assessments.actions';
import { getBusiness } from './redux/reducers/business/business.actions';
import { setProjectDetails } from './redux/reducers/project/project.actions';
import { Helmet } from 'react-helmet';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if ('REACT_APP_GA_TRACKING_ID' in window.process.env) {
      ReactGA.initialize(window.process.env.REACT_APP_GA_TRACKING_ID);
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    }
  }, [location]);
};

let socket;

socket = io(BASE_URL.replace('/api/v1', ''), {
  transports: ['websocket'],
  reconnectionAttempts: 100,
  reconnectionDelay: 3000,
});

socket.connect();

socket.on('connect', () => {
  console.log('connected...');
  socket.emit('SET_TOKEN', localStorage.getItem('token') || 'foobar');
});

socket.on('disconnect', () => {
  console.log('disconnected');
});

socket.on('reconnect_attempt', () => {
  console.log('trying to reconnect...');
});

export const SocketContext = React.createContext({ socket });

const Loader = <LoadingIndicator isLoading />;

const agentRoutes = (
  <Switch>
    <Route path="/" exact component={Pages.find} />
    <Route path="/comeBackLater" exact component={Pages.comeBackLater} />
    <Route exact path="/features" component={Pages.featureFlags} />
    <Route path="/profile/audio" component={Pages.profileAudio} />
    <Route path="/profile/video" exact component={Pages.viewProfileVideo} />
    <Route path="/profile/video/take" exact component={Pages.profileVideo} />
    <Route path="/find" component={Pages.find} />
    <Route path="/offers" component={Pages.offers} />
    <Route
      path="/public/profile/:fullName/:userId"
      exact
      component={Pages.publicProfile}
    />
    <Route
      path="/:projectId/video/interview"
      component={Pages.videointerview}
    />
    <Route
      path="/business/:userId/:firstName"
      exact
      component={Pages.businessProfile}
    />
    <Route path="/my/jobs" exact component={Pages.myJobs} />
    <Route path="/my/favorites" exact component={Pages.myJobs} />
    <Route path="/assessments" exact component={Pages.agentAssessments} />
    <Route
      path="/assessments/:jobFunctionId"
      exact
      component={Pages.agentAssessments}
    />
    <Route
      path="/interview-responses/:applicationId/:projectId"
      exact
      component={Pages.interviewResponses}
    />
    <Route path="/settings/face" exact component={Pages.manageFace} />
    <Route
      path="/settings/notification-preferences"
      exact
      component={Pages.manageUserNotifications}
    />
    <Route path="/chat" exact component={Pages.chat} />
    <Route path="/my/profile" exact component={Pages.profileWizard} />
    <Route path="/notifications" exact component={Pages.notifications} />
    <Route path="/settings" exact component={Pages.settings} />
    <Route path="/" exact component={Pages.jobSearch} />
    <Route path="/job/search" component={Pages.jobSearch} />
    <Route path="/job/:id/:jobTitle?" component={Pages.jobPosting} />
    <Route path="/application/:applicationId?" component={Pages.application} />
    <Route path="/quickapply/:id" component={Pages.quickApply} />
    <Route exact path="/apply/:id" component={Pages.applyForJob} />
    <Route path="/redirect" component={Pages.redirect} />
    <Route path="/notifications" exact component={Pages.notifications} />
    <Route path="/settings" exact component={Pages.myInformation} />
    <Route path="/features" component={Pages.featureFlags} />
    <Route path="/completeKyc" component={Pages.kycWizard} />
    <Route path="/kycfinished" component={Pages.finalPageKyc} />
    <Route
      path="/applicantInvite/:projectId/:projectTitle"
      exact
      component={Pages.applicantInvitation}
    />
    <Route path="/home" exact component={Pages.homeSection} />
    <Route path="/my/productivity" exact component={Pages.productivity} />
    <Route path="*">
      <Redirect to="/find?view=jobs" />
    </Route>
  </Switch>
);

const demandSideRoutes = (
  <Switch>
    <Route path="/terms" component={Pages.terms} />
    <Route
      path="/manage/job/:projectId/details"
      exact
      component={Pages.projectManage}
    />
    <Route
      path="/candidates/communication"
      exact
      component={Pages.candidateSearch}
    />
    <Route path="/candidates/search" exact component={Pages.candidateSearch} />
    <Route
      path="/candidates/quickhire"
      exact
      component={Pages.candidateSearch}
    />
    <Route path="/campaigns" exact component={Pages.productivity} />
    <Route path="/job/create" exact component={Pages.projectCreate} />
    <Route
      path="/public/profile/:fullName/:userId"
      exact
      component={Pages.publicProfile}
    />
    <Route
      path="/applicant/profile/:key/:editType"
      exact
      component={Pages.myProfile}
    />
    <Route
      path="/applicant/application/:key"
      exact
      component={Pages.jobPosting}
    />
    <Route
      path="/interview-responses/:applicationId/:projectId"
      exact
      component={Pages.interviewResponses}
    />
    <Route
      path="/business/:userId/:firstName"
      exact
      component={Pages.businessProfile}
    />
    <Route path="/jobs" component={Pages.productivity} />
    <Route
      path="/project/hiring/:projectId/:applicationStatus"
      component={Pages.productivity}
    />
    <Route
      path="/settings/notifications"
      component={Pages.manageNotifications}
    />
    <Route path="/business/settings" component={Pages.businessSettings} exact />
    <Route path="/settings" component={Pages.settings} exact />
    <Route
      path="/settings/documents"
      component={Pages.businessDocuments}
      exact
    />
    <Route path="/settings/payments" component={Pages.businessPayments} exact />
    <Route path="/notifications" exact component={Pages.notifications} />
    <Route path="/candidates/search" exact component={Pages.candidateSearch} />
    <Route path="/job/:id/:jobTitle?" component={Pages.jobPosting} />
    <Route
      path="/hiring/application/:applicationId"
      component={Pages.application}
    />
    <Route path="/quickapply/:id" component={Pages.quickApply} />
    <Route exact path="/apply/:id" component={Pages.applyForJob} />
    <Route path="/redirect" component={Pages.redirect} />
    <Route
      path="/project/:id/candidates/search"
      exact
      component={Pages.projectInvites}
    />
    <Route path="/settings" exact component={Pages.settings} />
    <Route path="/settings/face" exact component={Pages.manageFace} />
    <Route path="/teams" exact component={Pages.teams} />
    <Route path="/features" component={Pages.featureFlags} />
    <Route path="*">
      <Redirect to="/jobs" />
    </Route>
  </Switch>
);

const nonLoggedInRoutes = (
  <Switch>
    <Route path="/" exact component={Pages.startSignup} />
    <Route path="/:userType/signup" exact component={Pages.clientSignIn} />
    <Route path="/login" exact component={Pages.login} />
    <Route path="/icons" exact component={Pages.icons} />
    <Route
      path="/business/invitation/:token"
      exact
      component={Pages.businessInvitation}
    />
    <Route path="/forgot-password" exact component={Pages.forgotPassword} />
    <Route
      path="/public/candidates/search"
      exact
      component={Pages.candidateSearch}
    />
    <Route
      path="/applicantInvite/:projectId/:projectTitle"
      exact
      component={Pages.applicantInvitation}
    />
    <Route
      path="/reset-password"
      exact
      component={Pages.preLoginResetPassword}
    />
    <Route path="/invitation" exact component={Pages.invitation} />
    <Route path="/deleted" exact component={Pages.deleted} />
    <Route
      path="/public/profile/:fullName/:userId"
      exact
      component={Pages.publicProfile}
    />
    <Route path="/candidates/search" exact component={Pages.candidateSearch} />
    <Route path="/job/:id/:jobTitle?" component={Pages.jobPosting} />
    <Route path="/quickapply/:id" component={Pages.quickApply} />
    <Route exact path="/apply/:id" component={Pages.applyForJob} />
    <Route path="/redirect" component={Pages.redirect} />
    <Route path="/features" component={Pages.featureFlags} />
    <Route path="/document/invite/:token" component={Pages.documentSignature} />
    <Route path="/adminBypass/:token" component={Pages.adminBypass} />
    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
);

function App() {
  const { windowLocation, isAttemptingAssessment } = useSelector(
    (sl) => sl.assessments
  );

  const [isInitialized, setIsInitialized] = useState(false);
  const [modalShowing, setModalShowing] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const [init, setInit] = useState(false);
  const [permissionsChanged, setPermissionsChanged] = useState(false);

  usePageTracking();

  useEffect(() => {
    let timer;

    socket.off('INITIALIZE');

    socket.on('INITIALIZE', (args) => {
      if (!init) {
        dispatch(initializeChat(args));
        setInit(true);
      }
    });

    const f = () => {
      socket.emit('GET_UNREAD_CHAT_COUNT', (count) => {
        dispatch(setUnreadChatCount(count));
      });

      timer = setTimeout(f, 5000);
    };

    if (userState?.user?.businessId === 1) {
      timer = setTimeout(f, 1000);
    }

    if (query.get('ref')) {
      localStorage.setItem('ref', query.get('ref'));
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    let timer;
    if (
      token &&
      user &&
      user.businessType &&
      ['DEMAND'].includes(user.businessType) &&
      !user.isBusinessAdmin
    ) {
      const f = () => {
        socket.emit('HAS_PERMISSIONS_CHANGED', (changedOrNot) => {
          setPermissionsChanged(changedOrNot);
          DEBUG.log(changedOrNot);
        });
      };

      setTimeout(f, 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    try {
      const token = localStorage.getItem('token');
      let user = localStorage.getItem('user');
      let project = localStorage.getItem('project');
      let voizUser = localStorage.getItem('voizUser');
      user = user && user !== 'undefined' && JSON.parse(user);
      project = project && project !== 'undefined' && JSON.parse(project);
      voizUser = voizUser && voizUser !== 'undefined' && JSON.parse(voizUser);

      if (token && user) {
        dispatch(setUserAndToken({ user, token, voizUser }));
        dispatch(setProjectDetails(project));
        setIsInitialized(true);
      } else if (token && !user) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('rolesAndPermissions');
        localStorage.removeItem('backdoor');
        localStorage.removeItem('project');
        localStorage.removeItem('isAdminLogin');
        localStorage.removeItem('voizUser');
        dispatch(setWindowLocation('/'));
        dispatch(signoutUser());
        setIsInitialized(true);
      } else {
        setIsInitialized(true);
      }
    } catch (err) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('isAdminLogin');
      localStorage.removeItem('rolesAndPermissions');
      localStorage.removeItem('backdoor');
      localStorage.removeItem('voizUser');
      dispatch(setWindowLocation('/'));
      dispatch(signoutUser());
      setIsInitialized(true);
    }
  }, []);

  useEffect(() => {
    dispatch(getFeatureFlags());
  }, []);

  useEffect(() => {
    if (!isAttemptingAssessment) {
      if (!windowLocation) return;
      if (windowLocation?.path) {
        if (windowLocation?.samePage) {
          window.location.href = windowLocation?.path;
          return;
        }
        window.open(windowLocation?.path, '_blank');

        dispatch(setWindowLocation(''));
        return;
      }
      dispatch(setWindowLocation(''));
      history.push(windowLocation);
    }
  }, [windowLocation]);

  const userState = useSelector((state) => state.user) || {};
  const { onBoarding, skippedKyc } = userState?.user || {};
  const dataState = useSelector((state) => state.data.featureFlags);
  const accountDeleted = userState?.user?.accountDeleted;
  const isLoggedIn = hasUserLoggedIn(userState);
  const userBusinessType = isLoggedIn && userState.user.businessType;

  useEffect(() => {
    if (userState.login.errors || userState.login.forgotPasswordLinkSent) {
      dispatch(loginReset());
    }
  }, [location]);
  let title;

  if (userBusinessType === 'DEMAND') {
    title = 'Business Dashboard';
  } else {
    title = 'Agent Dashboard';
  }

  title = getTitleForEnv(title);

  const isAgent = userBusinessType === BusinessType.AGENT;
  const isBusiness = userBusinessType === BusinessType.DEMAND;

  const hasVerifiedEmailAndMobile =
    isLoggedIn &&
    userState.user.businessType &&
    userState.user.hasVerifiedEmail &&
    userState.user.hasVerifiedPhone;

  const isByotUser = isLoggedIn && userState.user.scopeId;

  let agentSideRoutes;

  if (userBusinessType === 'NONE') {
    agentSideRoutes = agentRoutes;
  }

  const loggedInRoutes =
    userBusinessType === 'DEMAND'
      ? demandSideRoutes
      : userBusinessType === 'NONE'
      ? agentSideRoutes
      : null;

  const { loading } = dataState;

  useEffect(() => {
    if (!isAgent && isLoggedIn) {
      dispatch(getBusiness());
    }
  }, [userBusinessType]);

  let routes;

  const hasCompletedOnBording =
    ((onBoarding?.meta?.completed?.percent === 100 ||
      onBoarding?.meta?.skipped === true) &&
      !onBoarding?.meta?.isInitialUser &&
      hasVerifiedEmailAndMobile) ||
    onBoarding?.meta?.isCrmSpecificUser;

  if (isInitialized && !loading) {
    if (isLoggedIn && (!hasCompletedOnBording || isByotUser) && isAgent) {
      routes = (
        <Switch>
          <Route
            path="/agent/completeprofile"
            component={Pages.completeProfile}
          />
          <Route path="/features" component={Pages.featureFlags} />
          <Route path="*">
            <Redirect to="/agent/completeprofile" />
          </Route>
        </Switch>
      );
    }

    if (isLoggedIn && isBusiness) {
      const { kycForm, filledPostSignUpForm } = onBoarding || {};

      if (!filledPostSignUpForm) {
        routes = (
          <Switch>
            <Route path="/onboarding" component={Pages.onBoarding} />
            <Route path="/features" component={Pages.featureFlags} />
            <Route path="*">
              <Redirect to="/onboarding" />
            </Route>
          </Switch>
        );
      }

      if (
        filledPostSignUpForm &&
        ['GSTIN&PAN', 'AADHAAR'].includes(kycForm.step) &&
        !skippedKyc &&
        hasPermission(userState?.user, 'kyc')
      ) {
        routes = (
          <Switch>
            <Route path="/completeKyc" component={Pages.clientKyc} />
            <Route path="/features" component={Pages.featureFlags} />
            <Route path="*">
              <Redirect to="/completeKyc" />
            </Route>
          </Switch>
        );
      }

      if (
        kycForm?.progress === 100 ||
        skippedKyc ||
        !hasPermission(userState?.user, 'kyc')
      ) {
        routes = loggedInRoutes;
      }
    }

    if (isLoggedIn && (hasCompletedOnBording || isByotUser) && isAgent) {
      routes = loggedInRoutes;
    }

    if (!isLoggedIn || userState.login.loading) {
      routes = nonLoggedInRoutes;
    }
  } else {
    routes = <LoadingIndicator isLoading={loading} />;
  }

  const hasModalBeenShown = localStorage.getItem('disclaimerShown');
  const modalContent = userBusinessType === 'NONE' && !hasModalBeenShown && (
    <AgentDisclaimerModal
      setModalShowing={setModalShowing}
      isModalShowing={modalShowing}
    />
  );

  const showJob = localStorage.getItem('showJob');

  if (
    userBusinessType === 'NONE' &&
    showJob &&
    hasVerifiedEmailAndMobile &&
    hasCompletedOnBording
  ) {
    const path = `/job/${showJob}`;
    if (location.pathname !== path) {
      return <Redirect to={`/job/${showJob}`} />;
    }
  }

  const redirectUrl = localStorage.getItem('redirectUrl');
  if (redirectUrl && hasCompletedOnBording) {
    const url = redirectUrl;
    localStorage.removeItem('redirectUrl');
    return <Redirect to={url} />;
  }

  if (accountDeleted) {
    routes = (
      <Switch>
        <Route path="/deactivated" component={Pages.deactivated} />
        <Route path="*">
          <Redirect to="/deactivated" />
        </Route>
      </Switch>
    );
  }

  return (
    <SocketContext.Provider value={socket}>
      <div className="maincontainer" style={{ position: 'relative' }}>
        {isLoggedIn && (
          <Helmet>
            <script>
              {`
                  function initFreshChat() {
                    window.fcWidget.init({
                      token: "0a98d53d-7930-4aab-acd5-a7ff15ec03c0",
                      host: "https://voizworks.freshchat.com",
                      config: {
                       headerProperty: {
                         hideChatButton: true
                       }
                     }
                    });
                    window.fcWidget.setExternalId("${
                      isAgent
                        ? userState?.user?.email
                        : userState?.user?.email + ' Business'
                    }");
                   window.fcWidget.user.setEmail("${userState?.user?.email}");
       
                   window.fcWidget.user.setFirstName("${
                     userState?.user?.fullName
                   }");
                   window.fcWidget.user.setPhone("${userState?.user?.phone}");
                  }
       
                  function loadFreshchatScript() {
                    var script = document.createElement("script");
                    script.src = "https://voizworks.freshchat.com/js/widget.js";
                    script.async = true;
                    script.onload = initFreshChat;
                    document.head.appendChild(script);
                  }
       
                  function initiateCall() {
                    if (document.readyState === 'complete') {
                      loadFreshchatScript();
                    } else {
                      if (window.addEventListener) {
                        window.addEventListener("load", loadFreshchatScript, false);
                      } else if (window.attachEvent) {
                        window.attachEvent("onload", loadFreshchatScript);
                      }
                    }
                  }
       
                  initiateCall();
                `}
            </script>
          </Helmet>
        )}
        <PageTitle
          title={
            hasVerifiedEmailAndMobile ? title : getTitleForEnv('VOIZ login')
          }
        />
        <Suspense fallback={Loader}>{routes}</Suspense>
        {modalContent}
      </div>
    </SocketContext.Provider>
  );
}

export default App;
