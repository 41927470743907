import NotificationActionTypes from './notifications.types';

const INITIAL_STATE = {
  loadError: null,
  loading: false,
  unreadCount: 0,
  totalCount: 0,
  notifications: [],
  snapshot: [],
  pager: null,
  markAllAsReadLoading: false,
};

export default function notificationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NotificationActionTypes.REMOVE_NOTIFICATION_START:
      return {
        ...state,
      };
    case NotificationActionTypes.REMOVE_NOTIFICATION_FINISHED:
      return {
        ...state,
        notifications: action.payload.notifications,
        pager: action.payload.pager,
      };

    case NotificationActionTypes.REMOVE_NOTIFICATION_ERROR:
      return state;
    case NotificationActionTypes.CLEAR_NOTIFICATIONS_START:
      return {
        ...state,
        loading: true,
      };
    case NotificationActionTypes.CLEAR_NOTIFICATIONS_FINISHED:
      return {
        ...state,
        loading: false,
        notifications: [],
        pager: action.payload.pager,
      };
    case NotificationActionTypes.CLEAR_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case NotificationActionTypes.NOTIFICATION_GET_UNREAD_COUNT_START:
      return state;
    case NotificationActionTypes.NOTIFICATION_GET_UNREAD_COUNT_FINISHED:
      return {
        ...state,
        unreadCount: action.payload.unreadCount,
        totalCount: action.payload.totalCount,
      };
    case NotificationActionTypes.NOTIFICATION_GET_UNREAD_COUNT_ERROR:
      return {
        ...state,
        unreadCount: 0,
        totalCount: 0,
      };

    case NotificationActionTypes.NOTIFICATION_MARK_AS_READ_START:
      return {
        ...state,
        markAllAsReadLoading: true,
      };

    case NotificationActionTypes.NOTIFICATION_MARK_AS_READ_FINISHED:
    case NotificationActionTypes.NOTIFICATION_MARK_AS_READ_ERROR:
      return {
        ...state,
        markAllAsReadLoading: false,
      };

    case NotificationActionTypes.GET_NOTIFICATION_SNAPSHOT_START:
      return {
        ...state,
        loading: true,
      };
    case NotificationActionTypes.GET_NOTIFICATION_SNAPSHOT_FINISHED:
      return {
        ...state,
        loading: false,
        snapshot: action.payload.notifications,
      };

    case NotificationActionTypes.GET_NOTIFICATION_SNAPSHOT_ERROR:
      return {
        ...state,
        loading: false,
        loadError: true,
      };
    case NotificationActionTypes.GET_NOTIFICATIONS_START:
      return {
        ...state,
        loading: true,
        notifications: [],
        pager: null,
      };
    case NotificationActionTypes.GET_NOTIFICATIONS_FINISHED:
      return {
        ...state,
        loading: false,
        notifications: action.payload.notifications,
        pager: action.payload.pager,
      };
    case NotificationActionTypes.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        loadError: action.payload,
      };
    default:
      return state;
  }
}
