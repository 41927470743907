import { takeLatest } from 'redux-saga/effects';
import UserActionType from './user.types';
import { commonAjaxHandler } from '../../url';
import {
  alertToast,
  mergeWithObjectFromLocalStorage,
} from '../../../utils/utils';

import tracker from '../../../utils/webengage';

const successHandlerForSignupAndLogin = (data) => {
  if (data) {
    const { user, token, rolesAndPermissions } = data;
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem(
      'rolesAndPermissions',
      JSON.stringify(rolesAndPermissions)
    );
    localStorage.removeItem('backdoor');

    // Track with webengage
    tracker.login(user.id);
  }
};

const handleUserSignup = commonAjaxHandler('/user/create', {
  method: 'post',
  start: UserActionType.USER_SIGNUP_STARTED,
  success: UserActionType.USER_SIGNUP_FINISHED,
  error: UserActionType.USER_SIGNUP_ERROR,
  payloadKeys: ['user', 'token'],
  onSuccess: successHandlerForSignupAndLogin,
});

const handleUserLogin = commonAjaxHandler('/user/login', {
  method: 'post',
  start: UserActionType.USER_LOGIN_STARTED,
  success: UserActionType.USER_LOGIN_FINISHED,
  error: UserActionType.USER_LOGIN_ERROR,
  payloadKeys: ['user', 'token', 'rolesAndPermissions', 'captchaRequired'],
  onSuccess: successHandlerForSignupAndLogin,
});

const handleOtpVerify = commonAjaxHandler('/user/verifyOtp', {
  method: 'post',
  start: UserActionType.OTP_VERIFY_STARTED,
  success: UserActionType.OTP_VERIFY_FINISHED,
  error: UserActionType.OTP_VERIFY_ERROR,
  payloadKeys: ['user', 'message', 'success'],
  onSuccess: (data) => {
    const { user } = data;
    localStorage.setItem('user', JSON.stringify(user));
    location.reload();
  },
});

const handleOtp = commonAjaxHandler(
  (p) =>
    p.otpFor === 'login'
      ? '/user/sendLoginOtp'
      : `/user/resendOtp?otpFor=${p.otpFor || 'email'}`,
  {
    method: 'post',
    start: UserActionType.OTP_SEND_STARTED,
    success: UserActionType.OTP_SEND_FINISHED,
    error: UserActionType.OTP_SEND_ERROR,
    payloadKeys: ['user', 'message', 'success'],
  }
);

const handleBypassOtp = commonAjaxHandler(
  (p) => `/user/resendBypassOtp/${p?.userId}/${p?.adminId}`,
  {
    method: 'get',
    start: UserActionType.BYPASS_OTP_SEND_STARTED,
    success: UserActionType.BYPASS_OTP_SEND_FINISHED,
    error: UserActionType.BYPASS_OTP_SEND_ERROR,
    payloadKeys: ['message', 'success'],
  }
);

const handleVerifyBypassOtp = commonAjaxHandler(
  (p) => `/user/verifyBypassOtp/${p?.userId}`,
  {
    method: 'post',
    start: UserActionType.VERIFY_BYPASS_OTP_START,
    success: UserActionType.VERIFY_BYPASS_OTP_FINISHED,
    error: UserActionType.VERIFY_BYPASS_OTP_ERROR,
    payloadKeys: ['message', 'success'],
    onSuccess: successHandlerForSignupAndLogin,
  }
);

const handleSignout = commonAjaxHandler('/user/signout', {
  method: 'post',
  start: UserActionType.SIGNOUT_START,
  success: UserActionType.SIGNOUT_FINISHED,
  error: UserActionType.SIGNOUT_FINISHED,
  payloadKeys: [],
  onFinished: () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('rolesAndPermissions');
    localStorage.removeItem('isAdminLogin');
    tracker.logout();
  },
});

const handleForgotPassword = commonAjaxHandler('/user/forgotPassword', {
  method: 'post',
  start: UserActionType.FORGOT_PASSWORD_STARTED,
  success: UserActionType.FORGOT_PASSWORD_FINISHED,
  error: UserActionType.FORGOT_PASSWORD_ERROR,
  payloadKeys: [],
});

const handleResetTokenVerification = commonAjaxHandler(
  '/user/validateResetPasswordToken',
  {
    method: 'post',
    start: UserActionType.VALIDATE_PASSWORD_RESET_TOKEN_STARTED,
    success: UserActionType.VALIDATE_PASSWORD_RESET_TOKEN_FINISHED,
    error: UserActionType.VALIDATE_PASSWORD_RESET_TOKEN_ERROR,
    payloadKeys: [],
  }
);

const handleResetPassword = commonAjaxHandler('/user/resetPassword', {
  method: 'post',
  start: UserActionType.RESET_PASSWORD_STARTED,
  success: UserActionType.RESET_PASSWORD_FINISHED,
  error: UserActionType.RESET_PASSWORD_ERROR,
  payloadKeys: [],
});

const handleUpdateUser = commonAjaxHandler('/user/update', {
  method: 'post',
  start: UserActionType.UPDATE_USER_START,
  success: UserActionType.UPDATE_USER_FINISHED,
  error: UserActionType.UPDATE_USER_ERROR,
  payloadKeys: ['user'],
  onSuccess: (data) => {
    localStorage.setItem('user', JSON.stringify(data.user));
  },
});

const handleChangePassword = commonAjaxHandler('/user/changePassword', {
  method: 'post',
  start: UserActionType.CHANGE_PASSWORD_START,
  success: UserActionType.CHANGE_PASSWORD_FINISHED,
  error: UserActionType.CHANGE_PASSWORD_ERROR,
  payloadKeys: [],
});

const handleDeactivateUser = commonAjaxHandler('/user/delete', {
  method: 'post',
  start: UserActionType.DEACTIVATE_USER_START,
  success: UserActionType.DEACTIVATE_USER_FINISHED,
  error: UserActionType.DEACTIVATE_USER_ERROR,
  payloadKeys: ['user', 'errors'],
  onFinished: (data) => {
    if (!data.user) {
      alertToast(
        'error',
        <>
          <p className="mb-0 font-weight-bold small">{data?.errors?.title}</p>
          <p className="mb-0 small">{data?.errors?.description}</p>
        </>
      );
      return;
    }
    localStorage.setItem('user', JSON.stringify(data.user));
  },
});

const handleDeleteUser = commonAjaxHandler('/user/destroy', {
  method: 'post',
  start: UserActionType.DELETE_USER_START,
  success: UserActionType.DELETE_USER_FINISHED,
  error: UserActionType.DELETE_USER_ERROR,
  payloadKeys: [],
  onFinished: () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('rolesAndPermissions');
    localStorage.removeItem('isAdminLogin');
    localStorage.removeItem('voizUser');
  },
});

const handleSetFeatureFlag = commonAjaxHandler(
  (p) => `/user/featureFlag/${p.flag}/${p.state}`,
  {
    method: 'post',
    start: UserActionType.SET_FEATURE_FLAG_START,
    success: UserActionType.SET_FEATURE_FLAG_FINISHED,
    error: UserActionType.SET_FEATURE_FLAG_ERROR,
    payloadKeys: ['featureFlags'],
    onFinished: (data) => {
      mergeWithObjectFromLocalStorage('user', data);
    },
  }
);

const handleSetOnboardingMeta = commonAjaxHandler('/user/onBoardingData', {
  method: 'post',
  start: UserActionType.SET_ONBOARDING_DATA_START,
  success: UserActionType.SET_ONBOARDING_DATA_FINISHED,
  error: UserActionType.SET_ONBOARDING_DATA_ERROR,
  payloadKeys: ['meta'],
});

const handlegetAdminBypassDetails = commonAjaxHandler(
  (p) => `/user/adminBypass/${p.token}`,
  {
    method: 'GET',
    start: UserActionType.GET_ADMINBYPASS_DETAILS_START,
    success: UserActionType.GET_ADMINBYPASS_DETAILS_FINISHED,
    error: UserActionType.GET_ADMINBYPASS_DETAILS_ERROR,
    payloadKeys: ['adminUser', 'user'],
  }
);

const handleReportAbuse = commonAjaxHandler('/user/reportAbuse', {
  method: 'POST',
  start: UserActionType.REPORT_ABUSE_START,
  success: UserActionType.REPORT_ABUSE_FINISHED,
  error: UserActionType.REPORT_ABUSE_ERROR,
  payloadKeys: [],
});

const handleFetchUserFavorites = commonAjaxHandler('/user/favorites', {
  method: 'GET',
  start: UserActionType.GET_USER_FAVORITES_START,
  success: UserActionType.GET_USER_FAVORITES_FINISHED,
  error: UserActionType.GET_USER_FAVORITES_ERROR,
  payloadKeys: ['favorites'],
});

const handleAddToUserFavorites = commonAjaxHandler(
  (p) => `/user/${p.projectId}/favorites/add`,
  {
    method: 'GET',
    start: UserActionType.ADD_TO_FAVORITES_START,
    success: UserActionType.ADD_TO_FAVORITES_FINISHED,
    error: UserActionType.ADD_TO_FAVORITES_ERROR,
    payloadKeys: ['favorites'],
  }
);

export function* userSignup() {
  yield takeLatest(UserActionType.USER_SIGNUP, handleUserSignup);
}

export function* getFavorites() {
  yield takeLatest(UserActionType.GET_USER_FAVORITES, handleFetchUserFavorites);
}

export function* addToFavorites() {
  yield takeLatest(UserActionType.ADD_TO_FAVORITES, handleAddToUserFavorites);
}

export function* userLogin() {
  yield takeLatest(UserActionType.USER_LOGIN, handleUserLogin);
}

export function* verifyOtp() {
  yield takeLatest(UserActionType.OTP_VERIFY, handleOtpVerify);
}

export function* verifyBypassOtp() {
  yield takeLatest(UserActionType.VERIFY_BYPASS_OTP, handleVerifyBypassOtp);
}

export function* resendOtp() {
  yield takeLatest(UserActionType.OTP_SEND, handleOtp);
}

export function* resendBypassOtp() {
  yield takeLatest(UserActionType.BYPASS_OTP_SEND, handleBypassOtp);
}

export function* signout() {
  yield takeLatest(UserActionType.SIGNOUT, handleSignout);
}

export function* forgotPassword() {
  yield takeLatest(UserActionType.FORGOT_PASSWORD, handleForgotPassword);
}

export function* validateResetPasswordToken() {
  yield takeLatest(
    UserActionType.VALIDATE_PASSWORD_RESET_TOKEN,
    handleResetTokenVerification
  );
}

export function* resetPassword() {
  yield takeLatest(UserActionType.RESET_PASSWORD, handleResetPassword);
}

export function* updateUser() {
  yield takeLatest(UserActionType.UPDATE_USER, handleUpdateUser);
}

export function* changePassword() {
  yield takeLatest(UserActionType.CHANGE_PASSWORD, handleChangePassword);
}

export function* deactivateUser() {
  yield takeLatest(UserActionType.DEACTIVATE_USER, handleDeactivateUser);
}

export function* deleteUser() {
  yield takeLatest(UserActionType.DELETE_USER, handleDeleteUser);
}

export function* setFeatureFlag() {
  yield takeLatest(UserActionType.SET_FEATURE_FLAG, handleSetFeatureFlag);
}

export function* setOnboadingData() {
  yield takeLatest(UserActionType.SET_ONBOARDING_DATA, handleSetOnboardingMeta);
}

export function* getAdminBypassDetails() {
  yield takeLatest(
    UserActionType.GET_ADMINBYPASS_DETAILS,
    handlegetAdminBypassDetails
  );
}

export function* reportAbuse() {
  yield takeLatest(UserActionType.REPORT_ABUSE, handleReportAbuse);
}
