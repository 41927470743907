export default {
  'GET_KYC_DOCUMENTS_ITEM': 'GET_KYC_DOCUMENTS_ITEM',
  'GET_KYC_DOCUMENTS_ITEM_START': 'GET_KYC_DOCUMENTS_ITEM_START',
  'GET_KYC_DOCUMENTS_ITEM_FINISHED': 'GET_KYC_DOCUMENTS_ITEM_FINISHED',
  'GET_KYC_DOCUMENTS_ITEM_ERROR': 'GET_KYC_DOCUMENTS_ITEM_ERROR',

  'GET_PROJECT_UPLOAD_ITEMS': 'GET_PROJECT_UPLOAD_ITEMS',
  'GET_PROJECT_UPLOAD_ITEMS_START': 'GET_PROJECT_UPLOAD_ITEMS_START',
  'GET_PROJECT_UPLOAD_ITEMS_FINISHED': 'GET_PROJECT_UPLOAD_ITEMS_FINISHED',
  'GET_PROJECT_UPLOAD_ITEMS_ERROR': 'GET_PROJECT_UPLOAD_ITEMS_ERROR',
};
