/* eslint-disable comma-dangle */
/* eslint-disable func-style */
import { takeLatest, put, call } from 'redux-saga/effects';
import BusinessActionTypes from './business.types';
import { commonAjaxHandler } from '../../url';

const handleGetAllBusinesses = commonAjaxHandler('/business/list', {
  method: 'get',
  start: BusinessActionTypes.GET_ALL_BUSINESSES_START,
  success: BusinessActionTypes.GET_ALL_BUSINESSES_FINISHED,
  error: BusinessActionTypes.GET_ALL_BUSINESSES_ERROR,
  payloadKeys: ['businesses'],
});

const handleUpdateBusiness = commonAjaxHandler('/business', {
  method: 'post',
  start: BusinessActionTypes.BUSINESS_SAVE_INFO_START,
  success: BusinessActionTypes.BUSINESS_SAVE_INFO_FINISHED,
  error: BusinessActionTypes.BUSINESS_SAVE_INFO_ERROR,
  payloadKeys: ['business', 'isExistingAccount'],
});

const handleFetchProductivityLogs = commonAjaxHandler('/business/productivity', {
  method: 'post',
  start: BusinessActionTypes.BUSINESS_GET_PRODUCTIVITY_LOGS_START,
  success: BusinessActionTypes.BUSINESS_GET_PRODUCTIVITY_LOGS_FINISHED,
  error: BusinessActionTypes.BUSINESS_GET_PRODUCTIVITY_LOGS_ERROR,
  payloadKeys: ['productivityLogs'],
});

const handleGetBusiness = commonAjaxHandler('/business', {
  method: 'get',
  start: BusinessActionTypes.BUSINESS_GET_START,
  success: BusinessActionTypes.BUSINESS_GET_FINISHED,
  error: BusinessActionTypes.BUSINESS_GET_ERROR,
  payloadKeys: ['business'],
});

const handleGetMainsummary = commonAjaxHandler((p) => '/reports/mainSummary/', {
  method: 'post',
  start: BusinessActionTypes.MAINSUMMARY_GET_START,
  success: BusinessActionTypes.MAINSUMMARY_GET_FINISHED,
  error: BusinessActionTypes.MAINSUMMARY_GET_ERROR,
  payloadKeys: ['statItems', 'projects'],
});

const handlegetDailerSummary = commonAjaxHandler(
  (p) => `/reports/dailerSummary/${p.projectId}`,
  {
    method: 'post',
    start: BusinessActionTypes.DAILERSUMMARY_GET_START,
    success: BusinessActionTypes.DAILERSUMMARY_GET_FINISHED,
    error: BusinessActionTypes.DAILERSUMMARY_GET_ERROR,
    payloadKeys: ['summary'],
  }
);

const handlegetSlotSummary = commonAjaxHandler(
  (p) => `/reports/slotSummary/${p.projectId}`,
  {
    method: 'post',
    start: BusinessActionTypes.SLOTSUMMARY_GET_START,
    success: BusinessActionTypes.SLOTSUMMARY_GET_FINISHED,
    error: BusinessActionTypes.SLOTSUMMARY_GET_ERROR,
    payloadKeys: ['slotSummary'],
  }
);

const handlegetqualityMetrics = commonAjaxHandler(
  (p) => `/reports/qualityMetrics/${p.projectId}`,
  {
    method: 'post',
    start: BusinessActionTypes.QUALITYMATRIX_GET_START,
    success: BusinessActionTypes.QUALITYMATRIX_GET_FINISHED,
    error: BusinessActionTypes.QUALITYMATRIX_GET_ERROR,
    payloadKeys: ['qualityStats'],
  }
);

const handlegetcSatSummary = commonAjaxHandler(
  (p) => `/reports/cSatSummary/${p.projectId}`,
  {
    method: 'post',
    start: BusinessActionTypes.CSATSUMMARY_GET_START,
    success: BusinessActionTypes.CSATSUMMARY_GET_FINISHED,
    error: BusinessActionTypes.CSATSUMMARY_GET_ERROR,
    payloadKeys: ['csatDetails'],
  }
);

const handlegetcSatDetails = commonAjaxHandler(
  (p) => `/reports/cSatDetails/${p.projectId}/${p.segment}/${p.days}`,
  {
    method: 'post',
    start: BusinessActionTypes.CSATDETAILS_GET_START,
    success: BusinessActionTypes.CSATDETAILS_GET_FINISHED,
    error: BusinessActionTypes.CSATDETAILS_GET_ERROR,
    payloadKeys: ['csatSubDetails'],
  }
);

const handlegetRRsummary = commonAjaxHandler(
  (p) => `/reports/rrSummary/${p.projectId}`,
  {
    method: 'post',
    start: BusinessActionTypes.RRSUMMARY_GET_START,
    success: BusinessActionTypes.RRSUMMARY_GET_FINISHED,
    error: BusinessActionTypes.RRDETAILS_GET_ERROR,
    payloadKeys: [],
  }
);

const handlegetRRdetails = commonAjaxHandler(
  (p) => `/reports/rrDetails/${p.projectId}/${p.segment}/${p.days}`,
  {
    method: 'post',
    start: BusinessActionTypes.RRDETAILS_GET_START,
    success: BusinessActionTypes.RRDETAILS_GET_FINISHED,
    error: BusinessActionTypes.RRDETAILS_GET_ERROR,
    payloadKeys: [],
  }
);

const handlegetpcdSummary = commonAjaxHandler(
  (p) => `/reports/pcdSummary/${p.projectId}`,
  {
    method: 'post',
    start: BusinessActionTypes.PCDSUMMARY_GET_START,
    success: BusinessActionTypes.PCDSUMMARY_GET_FINISHED,
    error: BusinessActionTypes.PCDSUMMARY_GET_ERROR,
    payloadKeys: ['pcdDetails'],
  }
);

const handlegetpcdDetails = commonAjaxHandler(
  (p) => `/reports/pcdDetails/${p.projectId}/${p.segment}/${p.days}`,
  {
    method: 'post',
    start: BusinessActionTypes.PCDDETAILS_GET_START,
    success: BusinessActionTypes.PCDDETAILS_GET_FINISHED,
    error: BusinessActionTypes.PCDDETAILS_GET_ERROR,
    payloadKeys: ['pcdSubDetails'],
  }
);

const handleproductivitySummary = commonAjaxHandler(
  (p) => `/reports/productivitySummary/${p.projectId}`,
  {
    method: 'post',
    start: BusinessActionTypes.PRODUCTIVITYSUMMARY_GET_START,
    success: BusinessActionTypes.PRODUCTIVITYSUMMARY_GET_FINISHED,
    error: BusinessActionTypes.PRODUCTIVITYSUMMARY_GET_ERROR,
    payloadKeys: ['prdSummary', 'stats'],
  }
);

const handleproductionAgents = commonAjaxHandler(
  (p) => `/reports/productionAgents/${p.projectId}`,
  {
    method: 'post',
    start: BusinessActionTypes.PRODUCTIONAGENTS_GET_START,
    success: BusinessActionTypes.PRODUCTIONAGENTS_GET_FINISHED,
    error: BusinessActionTypes.PRODUCTIONAGENTS_GET_ERROR,
    payloadKeys: ['allAgents', 'project'],
  }
);

const handlegetAgentLogins = commonAjaxHandler(
  (p) => `/reports/agentLogins/${p.projectId}`,
  {
    method: 'post',
    start: BusinessActionTypes.AGENTLOGINS_GET_START,
    success: BusinessActionTypes.AGENTLOGINS_GET_FINISHED,
    error: BusinessActionTypes.AGENTLOGINS_GET_ERROR,
    payloadKeys: ['loginDays', 'loginHours', 'activeAgents'],
  }
);

const handlegetAgentView = commonAjaxHandler(
  (p) => `/reports/agentView/${p.userId}/${p.projectId}`,
  {
    method: 'post',
    start: BusinessActionTypes.AGENTVIEW_GET_START,
    success: BusinessActionTypes.AGENTVIEW_GET_FINISHED,
    error: BusinessActionTypes.AGENTVIEW_GET_ERROR,
    payloadKeys: ['agentSummary', 'agentRow', 'cdrs', 'logins', 'project'],
  }
);

const handleGetBusinessWallet = commonAjaxHandler((p) => '/business/wallet', {
  method: 'get',
  start: BusinessActionTypes.GET_BUSINESS_WALLET_START,
  success: BusinessActionTypes.GET_BUSINESS_WALLET_FINISHED,
  error: BusinessActionTypes.GET_BUSINESS_WALLET_ERROR,
  payloadKeys: ['balance'],
});

const handleGetBusinessPayments = commonAjaxHandler(
  (p) => '/business/transactions',
  {
    method: 'get',
    start: BusinessActionTypes.GET_BUSINESS_TRANSACTIONS_START,
    success: BusinessActionTypes.GET_BUSINESS_TRANSACTIONS_FINISHED,
    error: BusinessActionTypes.GET_BUSINESS_TRANSACTIONS_ERROR,
    payloadKeys: ['transactions', 'paymentAuthorizations'],
  }
);

const handleBusinessInvitation = commonAjaxHandler(
  (p) => `/user/invitation/${p.action}?token=${p.token}`,
  {
    method: 'get',
    start: BusinessActionTypes.BUSINESS_INVITATION_START,
    success: BusinessActionTypes.BUSINESS_INVITATION_FINISHED,
    error: BusinessActionTypes.BUSINESS_INVITATION_ERROR,
    payloadKeys: ['requestedUser', 'existingBusiness'],
  }
);

const handleRequestBusinessInvitation = commonAjaxHandler(
  (p) => `/business/invitation/request?query=${p.query}`,
  {
    method: 'get',
    start: BusinessActionTypes.REQUEST_BUSINESS_INVITATION_START,
    success: BusinessActionTypes.REQUEST_BUSINESS_INVITATION_FINISHED,
    error: BusinessActionTypes.REQUEST_BUSINESS_INVITATION_ERROR,
    payloadKeys: ['business'],
  }
);

const handleFetchOfferLetterConfig = commonAjaxHandler(
  (p) =>
    p?.applicationId
      ? `/jobs/offerLetterConfig/${p.applicationId}`
      : `/business/offerLetterConfigs`,
  {
    method: 'GET',
    start: BusinessActionTypes.FETCH_OFFERLETTER_CONFIG_START,
    success: BusinessActionTypes.FETCH_OFFERLETTER_CONFIG_FINISHED,
    error: BusinessActionTypes.FETCH_OFFERLETTER_CONFIG_ERROR,
    payloadKeys: [
      'businessOfferLetterTemplates',
      'businessOfferAnnexures',
      'applicantOfferConfig',
    ],
  }
);

const handleToggleAnnexure = commonAjaxHandler(
  (p) => `/business/updateAnnexure/${p.annexureId}`,
  {
    method: 'POST',
    start: BusinessActionTypes.TOGGLE_ANNEXURE_STATE_START,
    success: BusinessActionTypes.TOGGLE_ANNEXURE_STATE_FINISHED,
    error: BusinessActionTypes.TOGGLE_ANNEXURE_STATE_ERROR,
  }
);

const handleConfigureProjectsForAnnexure = commonAjaxHandler(
  '/business/offerAnnexure/templates',
  {
    method: 'POST',
    start: BusinessActionTypes.CONFIGURE_PROJECTS_FOR_ANNEXURE_START,
    success: BusinessActionTypes.CONFIGURE_PROJECTS_FOR_ANNEXURE_FINISHED,
    error: BusinessActionTypes.CONFIGURE_PROJECTS_FOR_ANNEXURE_ERROR,
  }
);

const handleConfigureOfferLetter = commonAjaxHandler(
  '/business/offerLetterTemplate',
  {
    method: 'POST',
    start: BusinessActionTypes.CONFIGURE_OFFER_LETTER_START,
    success: BusinessActionTypes.CONFIGURE_OFFER_LETTER_FINISHED,
    error: BusinessActionTypes.CONFIGURE_OFFER_LETTER_ERROR,
    payloadKeys: ['businessOfferLetterTemplates'],
  }
);

const handleAddCampaign = commonAjaxHandler((p) => '/campaigns', {
  method: 'POST',
  start: BusinessActionTypes.ADD_CAMPAIGN_START,
  success: BusinessActionTypes.ADD_CAMPAIGN_FINISHED,
  error: BusinessActionTypes.ADD_CAMPAIGN_ERROR,
  payloadKeys: ['campaigns'],
});

const handleGetCampaigns = commonAjaxHandler((p) => '/campaigns', {
  method: 'GET',
  start: BusinessActionTypes.GET_CAMPAIGNS_START,
  success: BusinessActionTypes.GET_CAMPAIGNS_FINISHED,
  error: BusinessActionTypes.GET_CAMPAIGNS_ERROR,
  payloadKeys: ['campaigns', 'externalCampaigns'],
});

const handleGetCampaignFields = commonAjaxHandler(
  (p) => `/campaigns/${p.campaignId}/fields`,
  {
    method: 'GET',
    start: BusinessActionTypes.BUSINESS_GET_CAMPAIGN_FIELDS_START,
    success: BusinessActionTypes.BUSINESS_GET_CAMPAIGN_FIELDS_FINISHED,
    error: BusinessActionTypes.BUSINESS_GET_CAMPAIGN_FIELDS_ERROR,
    payloadKeys: ['fields'],
  }
);

const handleAddCampaignField = commonAjaxHandler(
  (p) => `/campaigns/${p.campaignId}/fields`,
  {
    method: 'POST',
    start: BusinessActionTypes.BUSINESS_ADD_CAMPAIGN_FIELD_START,
    success: BusinessActionTypes.BUSINESS_ADD_CAMPAIGN_FIELD_FINISHED,
    error: BusinessActionTypes.BUSINESS_ADD_CAMPAIGN_FIELD_ERROR,
    payloadKeys: ['fields'],
  }
)

const handleRemoveCampaignField = commonAjaxHandler(
  (p) => `/campaigns/${p.campaignId}/fields/${p.fieldId}/remove`,
  {
    method: 'POST',
    start: BusinessActionTypes.BUSINESS_REMOVE_CAMPAIGN_FIELD_START,
    success: BusinessActionTypes.BUSINESS_REMOVE_CAMPAIGN_FIELD_FINISHED,
    error: BusinessActionTypes.BUSINESS_REMOVE_CAMPAIGN_FIELD_ERROR,
    payloadKeys: ['fields'],
  }
);

const handleUpdateCampaignField = commonAjaxHandler(
  (p) => `/campaigns/${p.campaignId}/fields/${p.fieldId}/update`,
  {
    method: 'POST',
    start: BusinessActionTypes.BUSINESS_UPDATE_CAMPAIGN_FIELD_START,
    success: BusinessActionTypes.BUSINESS_UPDATE_CAMPAIGN_FIELD_FINISHED,
    error: BusinessActionTypes.BUSINESS_REMOVE_CAMPAIGN_FIELD_ERROR,
    payloadKeys: ['fields'],
  }
);

const handleUpdateExternalCampaign = commonAjaxHandler(
  (p) => `/campaigns/${p.externalCampaignId}/update`,
  {
    method: 'POST',
    start: BusinessActionTypes.UPDATE_EXTERNAL_CAMPAIGN_START,
    success: BusinessActionTypes.UPDATE_EXTERNAL_CAMPAIGN_FINISHED,
    error: BusinessActionTypes.UPDATE_EXTERNAL_CAMPAIGN_ERROR,
    payloadKeys: ['campaigns', 'externalCampaigns'],
  }
);

export function* getCampaigns() {
  yield takeLatest(
    BusinessActionTypes.GET_CAMPAIGNS,
    handleGetCampaigns
  );
}

export function* addCampaign() {
  yield takeLatest(
    BusinessActionTypes.ADD_CAMPAIGN,
    handleAddCampaign
  );
}

export function* toggleAnnexure() {
  yield takeLatest(
    BusinessActionTypes.TOGGLE_ANNEXURE_STATE,
    handleToggleAnnexure
  );
}

export function* configureOfferLetter() {
  yield takeLatest(
    BusinessActionTypes.CONFIGURE_OFFER_LETTER,
    handleConfigureOfferLetter
  );
}

export function* agentView() {
  yield takeLatest(BusinessActionTypes.AGENTVIEW_GET, handlegetAgentView);
}

export function* getAgentLogins() {
  yield takeLatest(BusinessActionTypes.AGENTLOGINS_GET, handlegetAgentLogins);
}

export function* updateBusiness() {
  yield takeLatest(
    BusinessActionTypes.BUSINESS_SAVE_INFO,
    handleUpdateBusiness
  );
}

export function* getBusiness() {
  yield takeLatest(BusinessActionTypes.BUSINESS_GET, handleGetBusiness);
}

export function* getMainsummary() {
  yield takeLatest(BusinessActionTypes.MAINSUMMARY_GET, handleGetMainsummary);
}

export function* getDailerSummary() {
  yield takeLatest(
    BusinessActionTypes.DAILERSUMMARY_GET,
    handlegetDailerSummary
  );
}

export function* getSlotSummary() {
  yield takeLatest(BusinessActionTypes.SLOTSUMMARY_GET, handlegetSlotSummary);
}

export function* getqualityMetrics() {
  yield takeLatest(
    BusinessActionTypes.QUALITYMATRIX_GET,
    handlegetqualityMetrics
  );
}

export function* getcSatSummary() {
  yield takeLatest(BusinessActionTypes.CSATSUMMARY_GET, handlegetcSatSummary);
}

export function* getcSatDetails() {
  yield takeLatest(BusinessActionTypes.CSATDETAILS_GET, handlegetcSatDetails);
}

export function* getrrSummary() {
  yield takeLatest(BusinessActionTypes.RRSUMMARY_GET, handlegetRRsummary);
}

export function* getrrDetails() {
  yield takeLatest(BusinessActionTypes.RRDETAILS_GET, handlegetRRdetails);
}

export function* getpcdSummary() {
  yield takeLatest(BusinessActionTypes.PCDSUMMARY_GET, handlegetpcdSummary);
}

export function* getpcdDetails() {
  yield takeLatest(BusinessActionTypes.PCDDETAILS_GET, handlegetpcdDetails);
}

export function* getproductivitySummary() {
  yield takeLatest(
    BusinessActionTypes.PRODUCTIVITYSUMMARY_GET,
    handleproductivitySummary
  );
}

export function* getproductionAgents() {
  yield takeLatest(
    BusinessActionTypes.PRODUCTIONAGENTS_GET,
    handleproductionAgents
  );
}

export function* getAllBusinesses() {
  yield takeLatest(
    BusinessActionTypes.GET_ALL_BUSINESSES,
    handleGetAllBusinesses
  );
}

export function* getBusinessWallet() {
  yield takeLatest(
    BusinessActionTypes.GET_BUSINESS_WALLET,
    handleGetBusinessWallet
  );
}

export function* getBusinessTransactions() {
  yield takeLatest(
    BusinessActionTypes.GET_BUSINESS_TRANSACTIONS,
    handleGetBusinessPayments
  );
}

export function* requestBusinessInvitation() {
  yield takeLatest(
    BusinessActionTypes.REQUEST_BUSINESS_INVITATION,
    handleRequestBusinessInvitation
  );
}

export function* businessInvitation() {
  yield takeLatest(
    BusinessActionTypes.BUSINESS_INVITATION,
    handleBusinessInvitation
  );
}

export function* fetchOfferLetterConfig() {
  yield takeLatest(
    BusinessActionTypes.FETCH_OFFERLETTER_CONFIG,
    handleFetchOfferLetterConfig
  );
}

export function* configureProjectsForAnnexure() {
  yield takeLatest(
    BusinessActionTypes.CONFIGURE_PROJECTS_FOR_ANNEXURE,
    handleConfigureProjectsForAnnexure
  );
}

export function* getProductivityLogs() {
  yield takeLatest(
    BusinessActionTypes.BUSINESS_GET_PRODUCTIVITY_LOGS,
    handleFetchProductivityLogs,
  );
}

export function* getCampaignFields() {
  yield takeLatest(
    BusinessActionTypes.BUSINESS_GET_CAMPAIGN_FIELDS,
    handleGetCampaignFields,
  );
}

export function* addCampaignField() {
  yield takeLatest(
    BusinessActionTypes.BUSINESS_ADD_CAMPAIGN_FIELD,
    handleAddCampaignField,
  );
}

export function* removeCampaignField() {
  yield takeLatest(
    BusinessActionTypes.BUSINESS_REMOVE_CAMPAIGN_FIELD,
    handleRemoveCampaignField,
  );
}

export function* updateCampaignField() {
  yield takeLatest(
    BusinessActionTypes.BUSINESS_UPDATE_CAMPAIGN_FIELD,
    handleUpdateCampaignField,
  );
}

export function* updateExternalCampaign() {
  yield takeLatest(
    BusinessActionTypes.UPDATE_EXTERNAL_CAMPAIGN,
    handleUpdateExternalCampaign,
  );
}
