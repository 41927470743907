import { lazy } from 'react';

const urls = [
  // Non-Logged-In pages
  ['adminBypass', 'PreLogin/AdminBypass'],
  ['startSignup', 'PreLogin/StartSignup'],

  ['login', 'PreLogin/Login'],
  ['forgotPassword', 'PreLogin/ForgotPassword'],
  ['preLoginResetPassword', 'PreLogin/ResetPassword'],
  ['comeBackLater', 'ComeBackLater'],
  ['clientSignIn', 'Demand/SignUp'],
  ['profileVideo', 'Agent/ProfileVideo'],
  ['viewProfileVideo', 'Agent/ProfileVideo/View'],
  ['profileAudio', 'Agent/ProfileAudio'],
  ['interviewResponses', 'Public/InterviewResponses'],
  ['agentOTPLogin', 'Agent/AgentOTPLogin'],
  ['featureFlags', 'Public/FeatureFlags'],

  // Non-logged in
  ['invitation', 'Demand/Invitation'],
  ['applicantInvitation', 'Public/ApplicantInvitation'],

  // Agent side
  ['userSignUp', 'Agent/UserSignUp'],
  ['completeProfile', 'Agent/Profile/CompleteProfile'],
  ['jobSearch', 'Agent/FindJobs'],
  ['jobInvitations', 'Agent/JobInvitations'],
  ['AgentReport', 'Agent/AgentReport'],
  ['myJobs', 'Agent/MyJobs'],
  ['myProfile', 'Agent/Profile/ProfileManager'],
  ['deleted', 'Agent/Deleted'],
  ['jobPosting', 'Agent/JobPosting'],
  ['quickApply', 'Agent/QuickApply'],
  ['videointerview', 'Agent/AgentInterview'],
  ['kycWizard', 'Agent/Profile/KycWizard'],
  ['finalPageKyc', 'Agent/Profile/FinalPageKyc'],
  ['profileWizard', 'Agent/Profile/ProfileWizard'],
  ['agentAssessments', 'Agent/Assessments'],
  ['homeSection', 'Agent/HomeSection'],
  ['productivity', 'Agent/Productivity'],
  ['find', 'Agent/Find'],
  ['offers', 'Agent/Offers'],

  // Candidates
  ['candidateSearch', 'Candidates/Search'],
  ['projectInvites', 'Candidates/ProjectInvites'],

  // Demand Side Pages
  ['projectCreate', 'Demand/ProjectCreate'],
  ['projectManage', 'Project/Manage'],
  ['jobList', 'Project/Hiring/Jobs'],
  ['campaigns', 'Demand/Campaigns'],
  // New hiring flow
  ['projectHiringV2', 'Project/HiringV2'],
  ['businessProfile', 'Demand/BusinessProfile'],
  ['businessInvitation', 'Public/BusinessInvitation'],
  ['onBoarding', 'Demand/Onboarding'],
  ['clientKyc', 'Demand/Onboarding/Kyc'],

  // Demand Settings Pages
  ['businessDocuments', 'Demand/Settings/BusinessDocuments'],
  ['manageNotifications', 'Demand/Settings/Notifications'],
  ['businessPayments', 'Demand/Settings/Payment'],
  ['businessSettings', 'Demand/Settings'],
  ['teams', 'Teams'],

  // Notifications
  ['notifications', 'Notifications'],
  ['chat', 'Chat'],
  ['deactivated', 'Public/Deactivated'],

  //  COMMON
  ['publicProfile', 'Public/Profile'],
  ['redirect', 'Public/Redirect'],
  ['applyForJob', 'Public/ApplyForJob'],

  // APPLICATIONS
  ['application', 'Applications/Application'],

  // Settings
  ['settings', 'Settings'],
  ['manageUserNotifications', 'Settings/NotificationPreferences'],
  ['manageFace', 'Settings/ManageFace'],
  ['icons', 'Public/Icons'],
  ['documentSignature', 'Public/DocumentSignature'],
  ['terms', 'Demand/Terms'],
];

const pages = {};

urls.forEach((value) => {
  pages[value[0]] = lazy(() => import('./' + value[1]));
});

export default pages;
