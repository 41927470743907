import { alertToast } from '../../../utils/utils';
import { UserActionType } from './user.types';
import { login, logout } from '../../../utils/webengage';

const INITIAL_STATE = {
  loading: false,
  user: null,
  voizUser: null,
  token: null,
  rolesAndPermissions: null,
  chats: null,
  chatOrder: null,
  unreadChatCount: 0,
  chatMessages: {},
  currentSelectedChat: null,
  deletedOrDeactivated: false,
  backroute: '',
  dates: null,
  adminBypass: null,
  favorites: [],
  update: {
    loading: false,
    errors: null,
  },

  signup: {
    businessType: null,

    project: {
      name: null,
      noOfAgents: null,
      processType: null,
      languages: null,
    },

    signupErrors: {},
    loading: false,
  },

  otp: {
    loading: false,
    isError: false,
    resent: false,
  },

  otpModal: {
    for: null,
    show: false,
  },

  resetPassword: {
    loading: false,
    hasValidatedToken: false,
    isValidToken: false,
    hasResetPassword: false,
    errors: null,
  },

  login: {
    loading: false,
    errors: null,
    forgotPasswordLinkSent: false,
    captchaRequired: false,
  },
};

export default function UserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UserActionType.INCREMENT_UNREAD_CHAT_COUNT: {
      const { chatId } = action.payload;
      return {
        ...state,
        chats: {
          ...(state.chats || {}),
          [chatId]: {
            ...((state.chats && state.chats[chatId]) || {}),
            unread: ((state.chats && state.chats[chatId].unread) || 0) + 1,
          },
        },
      };
    }
    case UserActionType.CLEAR_UNREAD_CHAT_COUNT: {
      const { chatId } = action.payload;
      return {
        ...state,
        chats: {
          ...(state.chats || {}),
          [chatId]: {
            ...((state.chats && state.chats[chatId]) || {}),
            unread: 0,
          },
        },
      };
    }
    case UserActionType.UNREAD_CHAT_COUNT: {
      return {
        ...state,
        unreadChatCount: parseInt(action.payload),
      };
    }
    case UserActionType.APPEND_MESSAGE: {
      const { profileId, message } = action.payload;
      console.log(final);
      const final = state.chatMessages[profileId]
        ? [...state.chatMessages[profileId], message]
        : [message];
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          [profileId]: final,
        },
      };
    }
    case UserActionType.SET_MESSAGES:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          [action.payload.profileId]: action.payload.messages,
        },
      };
    case UserActionType.INITIALIZE_CHAT:
      // Why would this ever happen?
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        chats: action.payload.chats,
        chatOrder: action.payload.chatOrder,
      };
    case UserActionType.CURRENT_SELECTED_CHAT:
      return {
        ...state,
        currentSelectedChat: action.payload,
      };

    case UserActionType.SET_BACK_LINK:
      return {
        ...state,
        backroute: action.payload,
      };
    case UserActionType.DEACTIVATE_USER_START:
    case UserActionType.DELETE_USER_START:
      return {
        ...state,
        loading: true,
      };

    case UserActionType.DEACTIVATE_USER_FINISHED:
    case UserActionType.DELETE_USER_FINISHED:
      return {
        ...state,
        loading: false,
        deletedOrDeactivated: true,
        user: action.payload.user,
      };

    case UserActionType.DEACTIVATE_USER_ERROR:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        update: {
          loading: false,
          errors: action.payload.errors,
        },
      };

    case UserActionType.LOGIN_RESET:
      return {
        ...state,
        login: {
          loading: false,
          errors: null,
          forgotPasswordLinkSent: false,
        },
      };
    case UserActionType.SIGNUP_RESET:
      return {
        ...state,
        signup: {
          businessType: null,

          project: {
            name: null,
            noOfAgents: null,
            processType: null,
            languages: null,
          },

          signupErrors: {},
          loading: false,
        },
      };

    case UserActionType.CHANGE_PASSWORD_FINISHED:
      return {
        ...state,
        update: {
          loading: false,
          success: true,
          errors: null,
        },
      };

    case UserActionType.UPDATE_USER_START:
    case UserActionType.CHANGE_PASSWORD_START:
      return {
        ...state,
        update: {
          loading: true,
          errors: null,
        },
      };

    case UserActionType.UPDATE_USER_FINISHED:
      return {
        ...state,
        user: action.payload.user,
        update: {
          loading: false,
          success: true,
          errors: null,
        },
      };

    case UserActionType.UPDATE_USER_ERROR:
    case UserActionType.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        update: {
          loading: false,
          errors: action.payload,
        },
      };

    case UserActionType.VALIDATE_PASSWORD_RESET_TOKEN_STARTED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: true,
        },
      };

    case UserActionType.VALIDATE_PASSWORD_RESET_TOKEN_FINISHED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          hasValidatedToken: true,
          isValidToken: true,
        },
      };

    case UserActionType.VALIDATE_PASSWORD_RESET_TOKEN_ERROR:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          hasValidatedToken: true,
          isValidToken: false,
        },
      };

    case UserActionType.RESET_PASSWORD_STARTED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: true,
        },
      };
    case UserActionType.RESET_PASSWORD_FINISHED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          hasResetPassword: true,
        },
      };

    case UserActionType.RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: false,
          hasResetPassword: false,
          errors: action.payload || 'Error resetting the password',
        },
      };

    case UserActionType.FORGOT_PASSWORD_STARTED:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
        },
      };

    case UserActionType.FORGOT_PASSWORD_FINISHED:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          forgotPasswordLinkSent: true,
        },
      };

    case UserActionType.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          forgotPasswordLinkSent: false,
          errors: action.payload || 'Can not find email',
        },
      };

    case UserActionType.SIGNOUT_START:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
        },
      };

    case UserActionType.SIGNOUT_FINISHED:
    case UserActionType.SIGNOUT_ERROR:
      return {
        ...state,
        token: null,
        user: null,
        login: {
          ...state.login,
          loading: false,
        },
      };

    case UserActionType.SET_USER_AND_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        voizUser: action.payload.voizUser,
      };

    case UserActionType.USER_LOGIN_STARTED:
    case UserActionType.VERIFY_BYPASS_OTP_START:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
        },
      };

    case UserActionType.USER_SET: {
      const { payload } = action;
      return {
        ...state,
        user: payload.user,
      };
    }

    case UserActionType.VOIZ_USER_SET: {
      const { payload } = action;
      return {
        ...state,
        voizUser: payload.user,
      };
    }

    case UserActionType.USER_LOGIN_FINISHED:
    case UserActionType.VERIFY_BYPASS_OTP_FINISHED:
      const { payload } = action;

      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          finished: true,
        },

        user: {
          ...payload.user,
        },

        token: payload.token,
      };
    case UserActionType.VERIFY_BYPASS_OTP_ERROR:
      alertToast(
        'error',
        <>
          <p className="mb-0 font-weight-bold small">
            {action?.payload?.title || 'Something went wrong.'}
          </p>
          <p className="mb-0 small">
            {action?.payload?.text || 'Please Try Again!'}
          </p>
        </>
      );
      return {
        ...state,
      };
    case UserActionType.USER_LOGIN_ERROR:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          errors: action?.payload?.message || action.payload || 'login error',
          captchaRequired: action?.payload?.captchaRequired,
        },
        user: null,
        token: null,
      };

    case UserActionType.SIGNUP_SELECT_BUSINESS_TYPE:
      return {
        ...state,
        signup: {
          ...state.signup,
          businessType: action.payload,
        },
      };

    case UserActionType.USER_SIGNUP_STARTED:
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: true,
        },
        user: null,
      };

    case UserActionType.USER_SIGNUP_FINISHED:
      const { user, token } = action.payload;

      localStorage.removeItem('ref');

      return {
        ...state,
        signup: {
          ...state.signup,
          loading: false,
          finished: true,
        },
        user,
        token,
      };

    case UserActionType.OTP_VERIFY_STARTED:
    case UserActionType.VERIFY_BYPASS_OTP_START:
      return {
        ...state,
        otp: {
          ...state.otp,
          loading: true,
          isError: false,
        },
      };

    case UserActionType.OTP_VERIFY_FINISHED: {
      if (action?.payload?.success) {
        alertToast(
          'success',
          <>
            <p className="mb-0 font-weight-bold small">
              {action?.payload?.message?.title}
            </p>
            <p className="mb-0 small">{action?.payload?.message?.text}</p>
          </>
        );
        return {
          ...state,
          otp: {
            ...state.otp,
            loading: false,
            verified: true,
          },
          otpModal: {
            for: null,
            show: false,
          },
          user: action.payload.user,
        };
      }
    }

    case UserActionType.OTP_VERIFY_ERROR:
      if (!action?.payload?.success) {
        alertToast(
          'error',
          <>
            <p className="mb-0 font-weight-bold small">
              {action?.payload?.message?.title}
            </p>
            <p className="mb-0 small">{action?.payload?.message?.text}</p>
          </>
        );
      }
      return {
        ...state,
        otp: {
          ...state.otp,
          loading: false,
          isError: true,
        },
      };

    case UserActionType.OTP_SEND_STARTED:
      return {
        ...state,
        otp: {
          ...state.otp,
          loading: true,
        },
      };

    case UserActionType.OTP_SEND_FINISHED:
      if (action?.payload?.success) {
        alertToast(
          'success',
          <>
            <p className="mb-0 font-weight-bold small">
              {action?.payload?.message?.title}
            </p>
            <p className="mb-0 small">{action?.payload?.message?.text}</p>
          </>
        );
        return {
          ...state,
          otp: {
            ...state.otp,
            loading: false,
          },
          user: {
            ...state.user,
            ...action.payload.user,
          },
        };
      }
    case UserActionType.OTP_SEND_ERROR:
      if (!action?.payload?.success) {
        alertToast(
          'error',
          <>
            <p className="mb-0 font-weight-bold small">
              {action?.payload?.message?.title}
            </p>
            <p className="mb-0 small">{action?.payload?.message?.text}</p>
          </>
        );
        return {
          ...state,
          otp: {
            ...state.otp,
            isError: true,
          },
          otpModal: {
            show: false,
          },
          error: action.payload,
        };
      }

    case UserActionType.USER_SIGNUP_ERROR:
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: false,
          signupErrors: action.payload,
        },
      };

    case UserActionType.SET_FEATURE_FLAG_START:
    case UserActionType.GET_ADMINBYPASS_DETAILS_START:
      return {
        ...state,
        loading: true,
      };
    case UserActionType.SET_FEATURE_FLAG_FINISHED:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          featureFlags: action.payload.featureFlags,
        },
      };
    case UserActionType.SET_FEATURE_FLAG_ERROR:
      return {
        ...state,
        loading: false,
      };
    case UserActionType.GET_ADMINBYPASS_DETAILS_FINISHED:
      return {
        ...state,
        loading: false,
        adminBypass: action.payload,
      };
    case UserActionType.SET_ONBOARDING_DATA_START:
      return {
        ...state,
        user: {
          ...state.user,
          onBoarding: {
            ...state.user.onBoarding,
            loading: true,
          },
        },
      };

    case UserActionType.SET_ONBOARDING_DATA_FINISHED:
      let userState = {
        ...state.user,
        onBoarding: {
          ...state.user.onBoarding,
          loading: false,
          meta: {
            ...(state?.user?.onBoarding?.meta || {}),
            ...action.payload?.meta,
          },
        },
      };
      localStorage.setItem('user', JSON.stringify(userState));
      return {
        ...state,
        user: {
          ...state.user,
          onBoarding: {
            ...state.user.onBoarding,
            loading: false,
            meta: {
              ...(state?.user?.onBoarding?.meta || {}),
              ...action.payload?.meta,
            },
          },
        },
      };

    case UserActionType.TOGGLE_OTP_MODAL:
      return {
        ...state,
        otpModal: {
          for: action.payload.field,
          show: !state.otpModal.show,
        },
      };

    case UserActionType.GET_USER_FAVORITES_FINISHED:
    case UserActionType.ADD_TO_FAVORITES_FINISHED:
      return {
        ...state,
        favorites: action.payload.favorites,
      };
    default:
      return state;
  }
}
