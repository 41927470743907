import { takeLatest } from 'redux-saga/effects';
import ActionTypes from './assessments.types';
import { commonAjaxHandler } from '../../url';

const getUrl = (ep) => `/assessments/${ep || ''}`;

const handleGetUserAssessments = commonAjaxHandler(
  (p) =>
    `${getUrl()}${p?.jobFunctionId ? `?jobFunctionId=${p.jobFunctionId}` : ''}${
      p.suggest ? '?suggest=true' : ''
    }`,
  {
    method: 'GET',
    start: ActionTypes.GET_USER_ASSESSMENTS_START,
    success: ActionTypes.GET_USER_ASSESSMENTS_FINISHED,
    error: ActionTypes.GET_USER_ASSESSMENTS_ERROR,
    payloadKeys: [
      'token',
      'uuid',
      'questionDetail',
      'assessment',
      'jobFunctionsProgress',
      'jobFunctionProgress',
      'voizFAQAssessment',
    ],
  }
);

const handleNextQuestion = commonAjaxHandler(getUrl('next'), {
  method: 'POST',
  start: ActionTypes.GET_NEXT_QUESTION_START,
  success: ActionTypes.GET_NEXT_QUESTION_FINISHED,
  error: ActionTypes.GET_NEXT_QUESTION_ERROR,
  payloadKeys: ['questionDetail', 'meta', 'attemptStats', 'selectedOption'],
});

const handlePrevQuestion = commonAjaxHandler(getUrl('prev'), {
  method: 'POST',
  start: ActionTypes.GET_PREV_QUESTION_START,
  success: ActionTypes.GET_PREV_QUESTION_FINISHED,
  error: ActionTypes.GET_PREV_QUESTION_ERROR,
  payloadKeys: ['questionDetail', 'meta', 'attemptStats', 'selectedOption'],
});

const handleStartAssessment = commonAjaxHandler(
  (p) =>
    `${getUrl('start')}/${
      p.jobFunctionAssessmentId ? p.jobFunctionAssessmentId : ''
    }`,
  {
    method: 'GET',
    start: ActionTypes.START_ASSESSMENT_START,
    success: ActionTypes.START_ASSESSMENT_FINISHED,
    error: ActionTypes.START_ASSESSMENT_ERROR,
    payloadKeys: ['uuid', 'questionDetail', 'assessmentLink'],
  }
);

const handleSubmitTypingAssessment = commonAjaxHandler(
  `${getUrl('typingAssessment/submit')}`,
  {
    method: 'POST',
    start: ActionTypes.SUBMIT_TYPING_ASSESSMENT_START,
    success: ActionTypes.SUBMIT_TYPING_ASSESSMENT_FINISHED,
    error: ActionTypes.SUBMIT_TYPING_ASSESSMENT_ERROR,
    payloadKeys: [],
  }
);

export function* getUserAssessments() {
  yield takeLatest(ActionTypes.GET_USER_ASSESSMENTS, handleGetUserAssessments);
}

export function* getNextQuestion() {
  yield takeLatest(ActionTypes.GET_NEXT_QUESTION, handleNextQuestion);
}

export function* getPrevQuestion() {
  yield takeLatest(ActionTypes.GET_PREV_QUESTION, handlePrevQuestion);
}

export function* startAssessment() {
  yield takeLatest(ActionTypes.START_ASSESSMENT, handleStartAssessment);
}

export function* submitTypingAssessment() {
  yield takeLatest(
    ActionTypes.SUBMIT_TYPING_ASSESSMENT,
    handleSubmitTypingAssessment
  );
}
