/* eslint-disable func-style */
import { takeLatest, call, put } from 'redux-saga/effects';
import ProfileActionTypes from './profile.types';
import { commonAjaxHandler } from '../../url';

const handleGetFullProfile = commonAjaxHandler(
  (p) => `/profile/full${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'GET',
    start: ProfileActionTypes.GET_FULL_PROFILE_START,
    success: ProfileActionTypes.GET_FULL_PROFILE_FINISHED,
    error: ProfileActionTypes.GET_FULL_PROFILE_ERROR,
    payloadKeys: ['profile'],
  }
);

const handleGetShortProfile = commonAjaxHandler(
  (p) =>
    p?.isPublicView
      ? `/public/profile/${p.userId}$${
          p?.isAdminLogin ? `?isAdminLogin=1` : ''
        }`
      : `/profile/short${p?.key ? `?key=${p?.key}` : ''}`,
  {
    method: 'GET',
    start: ProfileActionTypes.GET_SHORT_PROFILE_START,
    success: ProfileActionTypes.GET_SHORT_PROFILE_FINISHED,
    error: ProfileActionTypes.GET_SHORT_PROFILE_ERROR,
    payloadKeys: ['profile', 'qualifications', 'experiences', 'certifications'],
  }
);

const handleUpdateProfile = commonAjaxHandler(
  (p) => `/profile${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'POST',
    start: ProfileActionTypes.UPDATE_PROFILE_START,
    success: ProfileActionTypes.UPDATE_PROFILE_FINISHED,
    error: ProfileActionTypes.UPDATE_PROFILE_ERROR,
    payloadKeys: ['profile', 'user'],
  }
);

const handleGetExperiences = commonAjaxHandler(
  (p) => `/profile/experiences${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'GET',
    start: ProfileActionTypes.GET_EXPERIENCES_START,
    success: ProfileActionTypes.GET_EXPERIENCES_FINISHED,
    error: ProfileActionTypes.GET_EXPERIENCES_ERROR,
    payloadKeys: ['experiences'],
  }
);

const handleAddExperience = commonAjaxHandler(
  (p) => `/profile/experience/add${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'POST',
    start: ProfileActionTypes.ADD_EXPERIENCE_START,
    success: ProfileActionTypes.ADD_EXPERIENCE_FINISHED,
    error: ProfileActionTypes.ADD_EXPERIENCE_ERROR,
    payloadKeys: [],
  }
);

const handleGetBankDetails = commonAjaxHandler(
  (p) => `/profile/bankDetails${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'GET',
    start: ProfileActionTypes.GET_BANK_DETAILS_START,
    success: ProfileActionTypes.GET_BANK_DETAILS_FINISHED,
    error: ProfileActionTypes.GET_BANK_DETAILS_ERROR,
    payloadKeys: ['bank'],
  }
);

const handleUpdateBankDetails = commonAjaxHandler(
  (p) => `/profile/bankDetails${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'POST',
    start: ProfileActionTypes.UPDATE_BANK_DETAILS_START,
    success: ProfileActionTypes.UPDATE_BANK_DETAILS_FINISHED,
    error: ProfileActionTypes.UPDATE_BANK_DETAILS_ERROR,
    payloadKeys: ['bank', 'user'],
  }
);

const handleGetKyc = commonAjaxHandler((p) => '/profile/kyc', {
  method: 'GET',
  start: ProfileActionTypes.GET_KYC_START,
  success: ProfileActionTypes.GET_KYC_FINISHED,
  error: ProfileActionTypes.GET_KYC_ERROR,
  payloadKeys: ['kyc'],
});

const handleUpdateKyc = commonAjaxHandler((p) => '/profile/kyc', {
  method: 'POST',
  start: ProfileActionTypes.UPDATE_KYC_START,
  success: ProfileActionTypes.UPDATE_KYC_FINISHED,
  error: ProfileActionTypes.UPDATE_KYC_ERROR,
  payloadKeys: ['kyc'],
});

const handleGetQualification = commonAjaxHandler(
  (p) => `/profile/userEducation${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'GET',
    start: ProfileActionTypes.GET_QUALIFICATIONS_START,
    success: ProfileActionTypes.GET_QUALIFICATIONS_FINISHED,
    error: ProfileActionTypes.GET_QUALIFICATIONS_ERROR,
    payloadKeys: ['education'],
  }
);

const handleAddQualification = commonAjaxHandler(
  (p) => `/profile/userEducation/${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'POST',
    start: ProfileActionTypes.ADD_QUALIFICATION_START,
    success: ProfileActionTypes.ADD_QUALIFICATION_FINISHED,
    error: ProfileActionTypes.ADD_QUALIFICATION_ERROR,
    payloadKeys: [],
  }
);

const handleEditOrDeleteQualification = commonAjaxHandler(
  (p) =>
    `/profile/${p.id}/qualification/${p.action}/${
      p.key ? `?key=${p.key}` : ''
    }`,
  {
    method: 'POST',
    start: ProfileActionTypes.REMOVE_OR_UPDATE_QUALIFICATION_START,
    success: ProfileActionTypes.REMOVE_OR_UPDATE_QUALIFICATION_FINISHED,
    error: ProfileActionTypes.REMOVE_OR_UPDATE_QUALIFICATION_ERROR,
    payloadKeys: ['education'],
  }
);

const handleGetCertifications = commonAjaxHandler(
  (p) => `/profile/certifications${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'GET',
    start: ProfileActionTypes.GET_CERTIFICATIONS_START,
    success: ProfileActionTypes.GET_CERTIFICATIONS_FINISHED,
    error: ProfileActionTypes.GET_CERTIFICATIONS_ERROR,
    payloadKeys: ['certifications'],
  }
);

const handleAddCertification = commonAjaxHandler(
  (p) => `/profile/certificate/create${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'POST',
    start: ProfileActionTypes.ADD_CERTIFICATION_START,
    success: ProfileActionTypes.ADD_CERTIFICATION_FINISHED,
    error: ProfileActionTypes.ADD_CERTIFICATION_ERROR,
    payloadKeys: [],
  }
);

const handleUpdateRemoveCertification = commonAjaxHandler(
  (p) =>
    `/profile/${p.id}/certificate/${p.action}${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'POST',
    start: ProfileActionTypes.REMOVE_OR_UPDATE_CERTIFICATION_START,
    success: ProfileActionTypes.REMOVE_OR_UPDATE_CERTIFICATION_FINISHED,
    error: ProfileActionTypes.REMOVE_OR_UPDATE_CERTIFICATION_ERROR,
    payloadKeys: [],
  }
);

const handleRemoveOrUpdateExperience = commonAjaxHandler(
  (p) =>
    `/profile/${p.id}/experience/${p.action}${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'POST',
    start: ProfileActionTypes.REMOVE_OR_UPDATE_EXPERIENCE_START,
    success: ProfileActionTypes.REMOVE_OR_UPDATE_EXPERIENCE_FINISHED,
    error: ProfileActionTypes.REMOVE_OR_UPDATE_EXPERIENCE_ERROR,
    payloadKeys: ['experiences'],
  }
);

const handleUpdateJobFunctionForExperience = commonAjaxHandler(
  (p) =>
    `/profile/experience/updateJobFunctions/${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'POST',
    start: ProfileActionTypes.ADD_EXP_JOBFUNCTIONS_START,
    success: ProfileActionTypes.ADD_EXP_JOBFUNCTIONS_FINISHED,
    error: ProfileActionTypes.ADD_EXP_JOBFUNCTIONS_ERROR,
    payloadKeys: ['experiences'],
  }
);

const handledGetDocuments = commonAjaxHandler(
  (p) => `/document/agent/${p.id}/documents/${p.key ? `?key=${p.key}` : ''}`,
  {
    method: 'GET',
    start: ProfileActionTypes.GET_DOCUMENTS_START,
    success: ProfileActionTypes.GET_DOCUMENTS_FINISHED,
    error: ProfileActionTypes.GET_DOCUMENTS_ERROR,
    payloadKeys: ['documents'],
  }
);

const handleGetKycDetails = commonAjaxHandler((p) => '/profile/kycDetails', {
  method: 'GET',
  start: ProfileActionTypes.GET_KYC_DETAILS_START,
  success: ProfileActionTypes.GET_KYC_DETAILS_FINISHED,
  error: ProfileActionTypes.GET_KYC_DETAILS_ERROR,
  payloadKeys: ['details'],
});

const handleSaveKycDetails = commonAjaxHandler(
  (p) => `/profile/kycDetails?isFor=${p.isFor}`,
  {
    method: 'POST',
    start: ProfileActionTypes.SAVE_KYC_DETAILS_START,
    success: ProfileActionTypes.SAVE_KYC_DETAILS_FINISHED,
    error: ProfileActionTypes.SAVE_KYC_DETAILS_ERROR,
    payloadKeys: ['details'],
  }
);

const getNotificationPreferences = commonAjaxHandler(
  '/profile/notificationPreferences',
  {
    method: 'GET',
    start: ProfileActionTypes.GET_NOTIFICATION_PREFERENCES_START,
    success: ProfileActionTypes.GET_NOTIFICATION_PREFERENCES_FINISHED,
    error: ProfileActionTypes.GET_NOTIFICATION_PREFERENCES_ERROR,
    payloadKeys: ['notificationPreferences'],
  }
);

const handleToggleNotificationPreference = commonAjaxHandler(
  (p) => `/profile/notificationPreferences/toggle/${p.notificationSubTypeId}`,
  {
    method: 'GET',
    start: ProfileActionTypes.TOGGLE_NOTIFICATION_PREFERENCE_START,
    success: ProfileActionTypes.TOGGLE_NOTIFICATION_PREFERENCE_FINISHED,
    error: ProfileActionTypes.TOGGLE_NOTIFICATION_PREFERENCE_ERROR,
    payloadKeys: ['notificationPreferences'],
  }
);

const handleGetJobFunctionPriorities = commonAjaxHandler(
  '/profile/jobFunctionPreferences',
  {
    method: 'GET',
    start: ProfileActionTypes.GET_JOBFUNCTION_PRIORITIES_START,
    success: ProfileActionTypes.GET_JOBFUNCTION_PRIORITIES_FINISHED,
    error: ProfileActionTypes.GET_JOBFUNCTION_PRIORITIES_ERROR,
    payloadKeys: ['jobPreferences', 'allJobFunctions'],
  }
);

const handleManageJobFunctionPriority = commonAjaxHandler(
  '/profile/jobFunctionPreferences',
  {
    method: 'POST',
    start: ProfileActionTypes.MANAGE_JOBFUNCTION_PRIORITY_START,
    success: ProfileActionTypes.MANAGE_JOBFUNCTION_PRIORITY_FINISHED,
    error: ProfileActionTypes.MANAGE_JOBFUNCTION_PRIORITY_ERROR,
    payloadKeys: ['profile'],
  }
);

const handleFetchVoizResume = commonAjaxHandler(
  (p) => `/public/${p.userId}/resume`,
  {
    method: 'POST',
    start: ProfileActionTypes.GET_VOIZ_RESUME_START,
    success: ProfileActionTypes.GET_VOIZ_RESUME_FINISHED,
    error: ProfileActionTypes.GET_VOIZ_RESUME_ERROR,
    payloadKeys: ['resume'],
  }
);

const handleGenerateProfileVideo = commonAjaxHandler(
  '/profile/saveProfileVideo',
  {
    method: 'POST',
    start: ProfileActionTypes.GENERATE_PROFILE_VIDEO_START,
    success: ProfileActionTypes.GENERATE_PROFILE_VIDEO_FINISHED,
    error: ProfileActionTypes.GENERATE_PROFILE_VIDEO_ERROR,
    payloadKeys: ['fileName', 'profile'],
  }
);

const handleGetMockCallScenarios = commonAjaxHandler('/profile/mockCalls', {
  method: 'GET',
  start: ProfileActionTypes.GET_MOCK_CALL_SCENARIOS_START,
  success: ProfileActionTypes.GET_MOCK_CALL_SCENARIOS_FINISHED,
  error: ProfileActionTypes.GET_MOCK_CALL_SCENARIOS_ERROR,
  payloadKeys: ['mockCallScenarios'],
});

const handleGetMockCallSet = commonAjaxHandler(
  (p) => `/profile/mockCallSet?jobFunctionId=${p.jobFunctionId}`,
  {
    method: 'GET',
    start: ProfileActionTypes.GET_MOCK_CALL_SET_START,
    success: ProfileActionTypes.GET_MOCK_CALL_SET_FINISHED,
    error: ProfileActionTypes.GET_MOCK_CALL_SET_ERROR,
    payloadKeys: ['audioSet'],
  }
);

const handleGenerateProfileAudio = commonAjaxHandler(
  '/profile/saveProfileAudio',
  {
    method: 'POST',
    start: ProfileActionTypes.GENERATE_PROFILE_AUDIO_START,
    success: ProfileActionTypes.GENERATE_PROFILE_AUDIO_FINISHED,
    error: ProfileActionTypes.GENERATE_PROFILE_AUDIO_ERROR,
    payloadKeys: ['path'],
  }
);

export function* getFullProfile() {
  yield takeLatest(ProfileActionTypes.GET_FULL_PROFILE, handleGetFullProfile);
}

export function* updateProfile() {
  yield takeLatest(ProfileActionTypes.UPDATE_PROFILE, handleUpdateProfile);
}

export function* getExperiences() {
  yield takeLatest(ProfileActionTypes.GET_EXPERIENCES, handleGetExperiences);
}

export function* addExperience() {
  yield takeLatest(ProfileActionTypes.ADD_EXPERIENCE, handleAddExperience);
}

export function* getBankDetails() {
  yield takeLatest(ProfileActionTypes.GET_BANK_DETAILS, handleGetBankDetails);
}

export function* updateBankDetails() {
  yield takeLatest(
    ProfileActionTypes.UPDATE_BANK_DETAILS,
    handleUpdateBankDetails
  );
}

export function* getKyc() {
  yield takeLatest(ProfileActionTypes.GET_KYC, handleGetKyc);
}

export function* updateKyc() {
  yield takeLatest(ProfileActionTypes.UPDATE_KYC, handleUpdateKyc);
}

export function* getQualifications() {
  yield takeLatest(
    ProfileActionTypes.GET_QUALIFICATIONS,
    handleGetQualification
  );
}

export function* addQualification() {
  yield takeLatest(
    ProfileActionTypes.ADD_QUALIFICATION,
    handleAddQualification
  );
}

export function* removeOrUpdateQualification() {
  yield takeLatest(
    ProfileActionTypes.REMOVE_OR_UPDATE_QUALIFICATION,
    handleEditOrDeleteQualification
  );
}

export function* getCertifications() {
  yield takeLatest(
    ProfileActionTypes.GET_CERTIFICATIONS,
    handleGetCertifications
  );
}

export function* addCertification() {
  yield takeLatest(
    ProfileActionTypes.ADD_CERTIFICATION,
    handleAddCertification
  );
}

export function* removeOrUpdateCertification() {
  yield takeLatest(
    ProfileActionTypes.REMOVE_OR_UPDATE_CERTIFICATION,
    handleUpdateRemoveCertification
  );
}

export function* removeOrUpdateExperience() {
  yield takeLatest(
    ProfileActionTypes.REMOVE_OR_UPDATE_EXPERIENCE,
    handleRemoveOrUpdateExperience
  );
}

export function* updateExperienceJobFunctions() {
  yield takeLatest(
    ProfileActionTypes.ADD_EXP_JOBFUNCTIONS,
    handleUpdateJobFunctionForExperience
  );
}

export function* getDocuments() {
  yield takeLatest(ProfileActionTypes.GET_DOCUMENTS, handledGetDocuments);
}

export function* getKycDetails() {
  yield takeLatest(ProfileActionTypes.GET_KYC_DETAILS, handleGetKycDetails);
}

export function* saveKycDetails() {
  yield takeLatest(ProfileActionTypes.SAVE_KYC_DETAILS, handleSaveKycDetails);
}

export function* getShortProfile() {
  yield takeLatest(ProfileActionTypes.GET_SHORT_PROFILE, handleGetShortProfile);
}

export function* fetchNotificationPreferences() {
  yield takeLatest(
    ProfileActionTypes.GET_NOTIFICATION_PREFERENCES,
    getNotificationPreferences
  );
}

export function* toggleNotificationPreference() {
  yield takeLatest(
    ProfileActionTypes.TOGGLE_NOTIFICATION_PREFERENCE,
    handleToggleNotificationPreference
  );
}

export function* getJobFunctionPriorities() {
  yield takeLatest(
    ProfileActionTypes.GET_JOBFUNCTION_PRIORITIES,
    handleGetJobFunctionPriorities
  );
}

export function* manageJobFunctionPriority() {
  yield takeLatest(
    ProfileActionTypes.MANAGE_JOBFUNCTION_PRIORITY,
    handleManageJobFunctionPriority
  );
}

export function* fetchVoizResume() {
  yield takeLatest(ProfileActionTypes.GET_VOIZ_RESUME, handleFetchVoizResume);
}

export function* generateProfileVideo() {
  yield takeLatest(
    ProfileActionTypes.GENERATE_PROFILE_VIDEO,
    handleGenerateProfileVideo
  );
}

export function* getMockCallSet() {
  yield takeLatest(ProfileActionTypes.GET_MOCK_CALL_SET, handleGetMockCallSet);
}

export function* getMockCallScenarios() {
  yield takeLatest(
    ProfileActionTypes.GET_MOCK_CALL_SCENARIOS,
    handleGetMockCallScenarios
  );
}

export function* generateProfileAudio() {
  yield takeLatest(
    ProfileActionTypes.GENERATE_PROFILE_AUDIO,
    handleGenerateProfileAudio
  );
}
