/* eslint-disable func-style */
import { takeLatest } from 'redux-saga/effects';
import DataActionTypes from './home.types';
import { commonAjaxHandler } from '../../url';

const getProfileCompletionHandler = commonAjaxHandler(
  '/home/profileCompletion',
  {
    method: 'get',
    start: DataActionTypes.GET_PROFILECOMPLETION_STARTED,
    success: DataActionTypes.GET_PROFILECOMPLETION_FINISHED,
    error: DataActionTypes.GET_PROFILECOMPLETION_ERROR,
    payloadKeys: ['profileCompletion'],
  }
);

const getStatisticsHandler = commonAjaxHandler('/home/statistics', {
  method: 'get',
  start: DataActionTypes.GET_STATISTICS_STARTED,
  success: DataActionTypes.GET_STATISTICS_FINISHED,
  error: DataActionTypes.GET_STATISTICS_ERROR,
  payloadKeys: ['statistics', 'meta'],
});

const getJobsSnapHandler = commonAjaxHandler('/home/jobs', {
  method: 'get',
  start: DataActionTypes.GET_JOBS_SNAP_STARTED,
  success: DataActionTypes.GET_JOBS_SNAP_FINISHED,
  error: DataActionTypes.GET_JOBS_SNAP_ERROR,
  payloadKeys: ['userApplications'],
});

export function* getProfileCompletion() {
  yield takeLatest(
    DataActionTypes.GET_PROFILECOMPLETION,
    getProfileCompletionHandler
  );
}

export function* getStatistics() {
  yield takeLatest(DataActionTypes.GET_STATISTICS, getStatisticsHandler);
}

export function* getJobsSnap() {
  yield takeLatest(DataActionTypes.GET_JOBS_SNAP, getJobsSnapHandler);
}
