import { combineReducers } from 'redux';

import userReducer from './user/user.reducer';
import dataReducer from './data/data.reducer';
import candidatesReducer from './candidates/candidates.reducer';
import projectReducer from './project/project.reducer';
import businessReducer from './business/business.reducer';
import applicationReducer from './applications/applications.reducer';
import jobReducer from './jobs/jobs.reducer';
import profileReducer from './profile/profile.reducer';
import uploadReducer from './uploads/uploads.reducer';
import notificationReducer from './notifications/notifications.reducer';
import paymentReducer from './payment/payment.reducer';
import teamsReducer from './teams/teams.reducer';
import applicantsReducer from './applicants/applicants.reducer';
import assessmentsReducer from './assessments/assessments.reducer';
import homeReducer from './home/home.reducer';
import KycReducer from './kyc/kyc.reducer';
import workSessionReducer from './workSessions/workSessions.reducer';

export default combineReducers({
  user: userReducer,
  data: dataReducer,
  candidates: candidatesReducer,
  projects: projectReducer,
  business: businessReducer,
  applications: applicationReducer,
  jobs: jobReducer,
  profile: profileReducer,
  uploads: uploadReducer,
  notifications: notificationReducer,
  payment: paymentReducer,
  teams: teamsReducer,
  applicants: applicantsReducer,
  assessments: assessmentsReducer,
  home: homeReducer,
  kyc: KycReducer,
  workSession: workSessionReducer,
});
