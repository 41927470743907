import { takeEvery, takeLatest } from 'redux-saga/effects';
import ProjectActionTypes from './project.types';
import { commonAjaxHandler } from '../../url';

const handleCreateProject = commonAjaxHandler('/project/create', {
  method: 'post',
  start: ProjectActionTypes.CREATE_PROJECT_START,
  success: ProjectActionTypes.CREATE_PROJECT_FINISHED,
  error: ProjectActionTypes.CREATE_PROJECT_ERROR,
  payloadKeys: ['project'],
});

const handleAddProjectFields = commonAjaxHandler(
  (p) => `/project/${p.projectId}/projectDashboardFields/add`,
  {
    method: 'post',
    start: ProjectActionTypes.ADD_DASHBOARD_FIELDS_START,
    success: ProjectActionTypes.ADD_DASHBOARD_FIELDS_FINISHED,
    error: ProjectActionTypes.ADD_DASHBOARD_FIELDS_ERROR,
    payloadKeys: ['fields', 'errors'],
  }
);

const handleListProjects = commonAjaxHandler(
  (p) => {
    const query = [];
    if (p && p.query) {
      query.push(`query=${encodeURIComponent(p.query)}`);
    }

    if (p && p.businessId) {
      query.push(`businessId=${encodeURIComponent(p.businessId)}`);
    }

    if (p && p.page) {
      query.push(`page=${encodeURIComponent(p.page)}`);
    }

    if (p?.only) query.push(`only=${p.only}`);
    if (p?.onlyActive) query.push(`onlyActive=${p.onlyActive}`);

    const finalQuery = '?' + query.join('&');

    return `/project/list${finalQuery}`;
  },
  {
    method: 'get',
    start: ProjectActionTypes.LIST_PROJECTS_START,
    success: ProjectActionTypes.LIST_PROJECTS_FINISHED,
    error: ProjectActionTypes.LIST_PROJECTS_ERROR,
    payloadKeys: ['projects', 'pager', 'projectStats', 'demandApplications'],
  }
);

const handleProjectDetails = commonAjaxHandler(
  (p) =>
    p.access === 'public'
      ? `/${p.projectId}/project-details`
      : `/project/${p.projectId}/details`,
  {
    method: 'get',
    start: ProjectActionTypes.PROJECT_DETAIL_START,
    success: ProjectActionTypes.PROJECT_DETAIL_FINISHED,
    error: ProjectActionTypes.PROJECT_DETAIL_ERROR,
    payloadKeys: ['project'],
  }
);

const handleProjectApplicants = commonAjaxHandler(
  (p) => `/project/${p.projectId}/${p.applicationStatus}/applications`,
  {
    method: 'POST',
    start: ProjectActionTypes.PROJECT_APPLICANTS_START,
    success: ProjectActionTypes.PROJECT_APPLICANTS_FINISHED,
    error: ProjectActionTypes.PROJECT_APPLICANTS_ERROR,
    payloadKeys: [
      'applications',
      'pager',
      'summary',
      'hideActions',
      'applicationIds',
    ],
  }
);

const handleProjectApplicantsV2 = commonAjaxHandler(
  (p) => `/hiring/${p.projectId}/${p.bucket}/applications`,
  {
    method: 'POST',
    start: ProjectActionTypes.PROJECT_APPLICANTS_START_V2,
    success: ProjectActionTypes.PROJECT_APPLICANTS_FINISHED_V2,
    error: ProjectActionTypes.PROJECT_APPLICANTS_ERROR_V2,
    payloadKeys: [
      'selectedApplications',
      'rejectedApplications',
      'stages',
      'hiringStatus',
    ],
  }
);

const handleProjectApplicantsSubStatuses = commonAjaxHandler(
  (p) =>
    `/project/${p.projectId}/${p.applicationStatus}/${p.applicationSubStatus}/applications`,
  {
    method: 'POST',
    start: ProjectActionTypes.PROJECT_APPLICANTS_SUBSTAT_START,
    success: ProjectActionTypes.PROJECT_APPLICANTS_SUBSTAT_FINISHED,
    error: ProjectActionTypes.PROJECT_APPLICANTS_SUBSTAT_ERROR,
    payloadKeys: [
      'applications',
      'pager',
      'summary',
      'hideActions',
      'applicationIds',
    ],
  }
);

const handleFreezeProjectTimeSlot = commonAjaxHandler(
  (p) => `/project/${p.projectId}/hiring/${p.timeSlotId}/freeze`,
  {
    method: 'post',
    start: ProjectActionTypes.FREEZE_PROJECT_TIMESLOT_START,
    success: ProjectActionTypes.FREEZE_PROJECT_TIMESLOT_FINISHED,
    error: ProjectActionTypes.FREEZE_PROJECT_TIMESLOT_ERROR,
    payloadKeys: ['stats'],
  }
);

const handleUnfreezeProjectTimeSlot = commonAjaxHandler(
  (p) => `/project/${p.projectId}/hiring/${p.timeSlotId}/unfreeze`,
  {
    method: 'post',
    start: ProjectActionTypes.UNFREEZE_PROJECT_TIMESLOT_START,
    success: ProjectActionTypes.UNFREEZE_PROJECT_TIMESLOT_FINISHED,
    error: ProjectActionTypes.UNFREEZE_PROJECT_TIMESLOT_ERROR,
    payloadKeys: ['stats'],
  }
);

const handleProjectUpdate = commonAjaxHandler(
  (p) => `/project/${p.projectId}/${p.updateType}/update`,
  {
    method: 'post',
    start: ProjectActionTypes.UPDATE_PROJECT_START,
    success: ProjectActionTypes.UPDATE_PROJECT_FINISHED,
    error: ProjectActionTypes.UPDATE_PROJECT_ERROR,
    payloadKeys: ['project'],
  }
);

const handleProjectDelete = commonAjaxHandler(
  (p) => `/project/${p.projectId}`,
  {
    method: 'delete',
    start: ProjectActionTypes.DELETE_PROJECT_START,
    success: ProjectActionTypes.DELETE_PROJECT_FINISHED,
    error: ProjectActionTypes.DELETE_PROJECT_ERROR,
    payloadKeys: [],
  }
);

const handleGetProjectIntegrations = commonAjaxHandler(
  (p) => `/project/${p.projectId}/integrations`,
  {
    method: 'get',
    start: ProjectActionTypes.PROJECT_INTEGRATIONS_START,
    success: ProjectActionTypes.PROJECT_INTEGRATIONS_FINISHED,
    error: ProjectActionTypes.PROJECT_INTEGRATIONS_ERROR,
    payloadKeys: ['integrations'],
  }
);

const handleUpdateIntegration = commonAjaxHandler(
  (p) => `/project/${p.projectId}/integration/${p.integrationId}`,
  {
    method: 'post',
    start: ProjectActionTypes.PROJECT_UPDATE_INTEGRATION_START,
    success: ProjectActionTypes.PROJECT_UPDATE_INTEGRATION_FINISHED,
    error: ProjectActionTypes.PROJECT_UPDATE_INTEGRATION_ERROR,
    payloadKeys: ['integrations'],
  }
);

const handleGetProductivityDetails = commonAjaxHandler(
  (p) => `/project/${p.projectId}/productivityMetrics`,
  {
    method: 'post',
    start: ProjectActionTypes.GET_PRODUCTIVITY_DETAILS_START,
    success: ProjectActionTypes.GET_PRODUCTIVITY_DETAILS_FINISHED,
    error: ProjectActionTypes.GET_PRODUCTIVITY_DETAILS_ERROR,
    payloadKeys: ['productivity'],
  }
);
const handleGetHolidays = commonAjaxHandler(
  (p) => `/project/${p.projectId}/holidays`,
  {
    method: 'post',
    start: ProjectActionTypes.GET_HOLIDAY_DETAILS_START,
    success: ProjectActionTypes.GET_HOLIDAY_DETAILS_FINISHED,
    error: ProjectActionTypes.GET_HOLIDAY_DETAILS_ERROR,
    payloadKeys: ['holidays'],
  }
);
const handleDeletetHoliday = commonAjaxHandler(
  (p) => `/project/${p.holidayId}/deleteHoliday`,
  {
    method: 'delete',
    start: ProjectActionTypes.DELETE_HOLIDAY_START,
    success: ProjectActionTypes.DELETE_HOLIDAY_FINISHED,
    error: ProjectActionTypes.DELETE_HOLIDAY_ERROR,
    payloadKeys: [],
  }
);
const handleCreateHoliday = commonAjaxHandler(
  (p) => `/project/${p.projectId}/createHoliday`,
  {
    method: 'post',
    start: ProjectActionTypes.CREATE_HOLIDAY_START,
    success: ProjectActionTypes.CREATE_HOLIDAY_FINISHED,
    error: ProjectActionTypes.CREATE_HOLIDAY_ERROR,
    payloadKeys: ['holidayDetails'],
  }
);
const handleUpdateHoliday = commonAjaxHandler(
  (p) => `/project/${p.projectId}/updateHoliday`,
  {
    method: 'post',
    start: ProjectActionTypes.UPDATE_HOLIDAY_START,
    success: ProjectActionTypes.UPDATE_HOLIDAY_FINISHED,
    error: ProjectActionTypes.UPDATE_HOLIDAY_ERROR,
    payloadKeys: ['holidayDetails'],
  }
);

const handleApproveWorkReport = commonAjaxHandler(
  (p) => `/project/workReport/${p.status}`,
  {
    method: 'post',
    start: ProjectActionTypes.APPROVE_WORK_REPORT_START,
    success: ProjectActionTypes.APPROVE_WORK_REPORT_FINISHED,
    error: ProjectActionTypes.APPROVE_WORK_REPORT_ERROR,
    payloadKeys: [],
  }
);

const handleAddCrm = commonAjaxHandler((p) => `/project/${p.id}/crm`, {
  method: 'POST',
  start: ProjectActionTypes.ADD_CRM_START,
  success: ProjectActionTypes.ADD_CRM_FINISHED,
  error: ProjectActionTypes.ADD_CRM_ERROR,
  payloadKeys: ['project'],
});

const handlegetPricingEstimate = commonAjaxHandler(
  (p) =>
    `/pricingEstimate?jobFunctionId=${p.jobFunctionId}&expertiseLevelId=${p.expertiseLevelId}&noOfAgents=${p.noOfAgents}`,
  {
    method: 'GET',
    start: ProjectActionTypes.GET_PRICING_ESTIMATE_START,
    success: ProjectActionTypes.GET_PRICING_ESTIMATE_FINISHED,
    error: ProjectActionTypes.GET_PRICING_ESTIMATE_ERROR,
    payloadKeys: ['estimate', 'pricingEstimates'],
  }
);

export function* createProject() {
  yield takeLatest(ProjectActionTypes.CREATE_PROJECT, handleCreateProject);
}

export function* listProjects() {
  yield takeLatest(ProjectActionTypes.LIST_PROJECTS, handleListProjects);
}

export function* getProjectDetail() {
  yield takeLatest(ProjectActionTypes.PROJECT_DETAIL, handleProjectDetails);
}

export function* getProjectApplications() {
  yield takeLatest(
    ProjectActionTypes.PROJECT_APPLICANTS,
    handleProjectApplicants
  );
}

export function* getProjectApplicationsV2() {
  yield takeLatest(
    ProjectActionTypes.PROJECT_APPLICANTS_V2,
    handleProjectApplicantsV2
  );
}

export function* getProjectApplicationsWithSubStatus() {
  yield takeLatest(
    ProjectActionTypes.PROJECT_APPLICANTS_SUBSTAT,
    handleProjectApplicantsSubStatuses
  );
}

export function* freezeProjectTimeSlot() {
  yield takeEvery(
    ProjectActionTypes.FREEZE_PROJECT_TIMESLOT,
    handleFreezeProjectTimeSlot
  );
}

export function* unfreezeProjectTimeSlot() {
  yield takeEvery(
    ProjectActionTypes.UNFREEZE_PROJECT_TIMESLOT,
    handleUnfreezeProjectTimeSlot
  );
}

export function* updateProject() {
  yield takeEvery(ProjectActionTypes.UPDATE_PROJECT, handleProjectUpdate);
}

export function* deleteProject() {
  yield takeEvery(ProjectActionTypes.DELETE_PROJECT, handleProjectDelete);
}

export function* getProjectIntegrations() {
  yield takeEvery(
    ProjectActionTypes.PROJECT_INTEGRATIONS,
    handleGetProjectIntegrations
  );
}

export function* updateProjectIntegration() {
  yield takeEvery(
    ProjectActionTypes.PROJECT_UPDATE_INTEGRATION,
    handleUpdateIntegration
  );
}

export function* approveWorkReport() {
  yield takeEvery(
    ProjectActionTypes.APPROVE_WORK_REPORT,
    handleApproveWorkReport
  );
}

export function* addCrm() {
  yield takeEvery(ProjectActionTypes.ADD_CRM, handleAddCrm);
}

export function* getPricingEstimate() {
  yield takeEvery(
    ProjectActionTypes.GET_PRICING_ESTIMATE,
    handlegetPricingEstimate
  );
}
