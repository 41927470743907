/* eslint-disable default-param-last */
import _ from 'lodash';
import ActionTypes from './workSessions.types';

const INITIAL_STATE = {
  loading: false,
  activeTime: {
    allTime: 0,
    today: 0,
    currentSession: null,
  },
  activeBreak: null,
  session: null,
  trackInActivity: false,
};

export default function workSessionReducer(state = INITIAL_STATE, action) {
  if (
    ![
      ActionTypes.END_WORK_START,
      ActionTypes.TAKE_A_BREAK_START,
      ActionTypes.END_BREAK_START,
      ActionTypes.SESSION_DETAILS_START,
      ActionTypes.START_WORK_START,
      ActionTypes.END_WORK_FINISHED,
      ActionTypes.TAKE_A_BREAK_FINISHED,
      ActionTypes.END_BREAK_FINISHED,
      ActionTypes.SESSION_DETAILS_FINISHED,
      ActionTypes.START_WORK_FINISHED,
    ].includes(action.type)
  ) {
    return state;
  }
  const isStart = _.endsWith('_START', action.type);
  const obj = { ...state, loading: isStart };
  return isStart
    ? obj
    : Object.assign(obj, action?.payload?.sessionDetails || {});
}
