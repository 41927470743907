const keys = [
  'GET_USER_ASSESSMENTS',
  'START_ASSESSMENT',
  'GET_NEXT_QUESTION',
  'GET_PREV_QUESTION',
  'SUBMIT_TYPING_ASSESSMENT',
];

const suffixes = ['', '_START', '_FINISHED', '_ERROR'];

const types = {
  SET_SELECTED_ASSESSMENT: 'SET_SELECTED_ASSESSMENT',
  RESET_SELECTED_ASSESSMENT: 'RESET_SELECTED_ASSESSMENT',
  SET_ASSESSMENT_TIMER: 'SET_ASSESSMENT_TIMER',
  SET_WINDOW_LOCATION: 'SET_WINDOW_LOCATION',
};

keys.forEach((k) => {
  suffixes.forEach((s) => {
    const value = k + s;
    types[value] = value;
  });
});

export default types;
