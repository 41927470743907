import { takeEvery, takeLatest } from 'redux-saga/effects';
import NotificationActionTypes from './notifications.types';
import { commonAjaxHandler } from '../../url';

const handleGetUnreadCount = commonAjaxHandler('/notifications/unread/count', {
  method: 'GET',
  start: NotificationActionTypes.NOTIFICATION_GET_UNREAD_COUNT_START,
  success: NotificationActionTypes.NOTIFICATION_GET_UNREAD_COUNT_FINISHED,
  error: NotificationActionTypes.NOTIFICATION_GET_UNREAD_COUNT_ERROR,
  payloadKeys: ['unreadCount', 'totalCount'],
});

const handleGetNotifications = commonAjaxHandler(
  (p) => `/notifications?page=${p.page}${p.entries ? '&entries=' + p.entries : ''}${
      p.filter ? '&filter=' + p.filter : ''
    }`,
  {
    method: 'GET',
    start: NotificationActionTypes.GET_NOTIFICATIONS_START,
    success: NotificationActionTypes.GET_NOTIFICATIONS_FINISHED,
    error: NotificationActionTypes.GET_NOTIFICATIONS_ERROR,
    payloadKeys: ['notifications', 'pager'],
  },
);

const handleGetNotificationSnapshot = commonAjaxHandler(
  '/notifications?page=1&entries=5',
  {
    method: 'GET',
    start: NotificationActionTypes.GET_NOTIFICATION_SNAPSHOT_START,
    success: NotificationActionTypes.GET_NOTIFICATION_SNAPSHOT_FINISHED,
    error: NotificationActionTypes.GET_NOTIFICATION_SNAPSHOT_ERROR,
    payloadKeys: ['notifications'],
  },
);

const handleNotificationMarkAsRead = commonAjaxHandler(
  (p) => `/notifications/mark-as-read?type=${p.type}&${
      p.notificationId ? `&notificationId=${p.notificationId}` : ''
    }`,
  {
    method: 'GET',
    start: NotificationActionTypes.NOTIFICATION_MARK_AS_READ_START,
    success: NotificationActionTypes.NOTIFICATION_MARK_AS_READ_FINISHED,
    error: NotificationActionTypes.NOTIFICATION_MARK_AS_READ_ERROR,
    payloadKeys: [],
  },
);

const handleClearNotifications = commonAjaxHandler(
  (p) => '/notifications/clear',
  {
    method: 'POST',
    start: NotificationActionTypes.CLEAR_NOTIFICATIONS_START,
    success: NotificationActionTypes.CLEAR_NOTIFICATIONS_FINISHED,
    error: NotificationActionTypes.CLEAR_NOTIFICATIONS_ERROR,
    payloadKeys: ['notifications', 'pager'],
  },
);

const handleRemoveNotification = commonAjaxHandler(
  (p) => `/notifications/${p.id}/remove`,
  {
    method: 'DELETE',
    start: NotificationActionTypes.REMOVE_NOTIFICATION_START,
    success: NotificationActionTypes.REMOVE_NOTIFICATION_SUCCESS,
    error: NotificationActionTypes.REMOVE_NOTIFICATION_ERROR,
    payloadKeys: [],
  },
);

export function* getUnreadCount() {
  yield takeLatest(
    NotificationActionTypes.NOTIFICATION_GET_UNREAD_COUNT,
    handleGetUnreadCount,
  );
}

export function* removeNotification() {
  yield takeLatest(
    NotificationActionTypes.REMOVE_NOTIFICATION,
    handleRemoveNotification,
  );
}

export function* clearNotifications() {
  yield takeLatest(
    NotificationActionTypes.CLEAR_NOTIFICATIONS,
    handleClearNotifications,
  );
}

export function* markAsRead() {
  yield takeLatest(
    NotificationActionTypes.NOTIFICATION_MARK_AS_READ,
    handleNotificationMarkAsRead,
  );
}

export function* getNotificationSnapshot() {
  yield takeLatest(
    NotificationActionTypes.GET_NOTIFICATION_SNAPSHOT,
    handleGetNotificationSnapshot,
  );
}

export function* getNotifications() {
  yield takeLatest(
    NotificationActionTypes.GET_NOTIFICATIONS,
    handleGetNotifications,
  );
}
