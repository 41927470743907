import { put, call, takeLatest } from 'redux-saga/effects';
import DataActionTypes from './data.types';
import { commonAjaxHandler, urlFor, fetch, getUriParams } from '../../url';

const getSubProjectTypesHandler = commonAjaxHandler(
  (p) => `/subProjectTypes?projectTypeId=${p.projectTypeId}`,
  {
    method: 'get',
    start: DataActionTypes.FETCH_SUB_PROJECT_TYPES_START,
    success: DataActionTypes.FETCH_SUB_PROJECT_TYPES_FINISHED,
    error: DataActionTypes.FETCH_SUB_PROJECT_TYPES_ERROR,
    payloadKeys: ['subProjectTypes'],
  }
);

const getAddressOfGeoLocationHandler = commonAjaxHandler(
  (p) =>
    `/addressFromGeoLocation?latitude=${p.coordinates.latitude}&longitude=${p.coordinates.longitude}`,
  {
    method: 'get',
    start: DataActionTypes.FETCH_ADDRESS_OF_GEOLOCATION_START,
    success: DataActionTypes.FETCH_ADDRESS_OF_GEOLOCATION_FINISHED,
    error: DataActionTypes.FETCH_ADDRESS_OF_GEOLOCATION_ERROR,
    payloadKeys: ['address'],
  }
);

const getDetailsOfIfscHandler = commonAjaxHandler(
  (p) => `/bankDetails?ifscCode=${p.ifscCode}`,
  {
    method: 'get',
    start: DataActionTypes.FETCH_BANK_WITH_IFSC_START,
    success: DataActionTypes.FETCH_BANK_WITH_IFSC_FINISHED,
    error: DataActionTypes.FETCH_BANK_WITH_IFSC_ERROR,
    payloadKeys: ['bank'],
  }
);

const getCoursesOfQualificationHandler = commonAjaxHandler(
  (p) => `/courses?qualification=${p.qualificationId}`,
  {
    method: 'get',
    start: DataActionTypes.FETCH_COURSES_START,
    success: DataActionTypes.FETCH_COURSES_FINISHED,
    error: DataActionTypes.FETCH_COURSES_ERROR,
    payloadKeys: ['courses'],
  }
);

const fetchSkillsHandler = commonAjaxHandler(
  (p) => `/skills?${getUriParams(p.query) || ''}`,
  {
    method: 'get',
    start: DataActionTypes.FETCH_SKILLS_START,
    success: DataActionTypes.FETCH_SKILLS_FINISHED,
    error: DataActionTypes.FETCH_SKILLS_ERROR,
    payloadKeys: ['skills'],
  }
);

function handleCommonFetch(urlKey, dataKey, start, finished, error) {
  return function* () {
    try {
      yield put({ type: start });

      const { data } = yield call(fetch, 'get', urlFor('/' + urlKey));

      if (data.success) {
        yield put({
          type: finished,
          payload: (data.data && data.data[dataKey]) || data[dataKey],
        });
      } else {
        yield put({
          type: error,
          payload: data.error,
        });
      }
    } catch (e) {
      yield put({
        type: error,
        payload: e.message,
      });
    }
  };
}

export function* fetchLanguages() {
  yield takeLatest(
    DataActionTypes.FETCH_LANGUAGES,
    handleCommonFetch(
      'languages',
      'languages',
      DataActionTypes.FETCH_LANGUAGES_START,
      DataActionTypes.FETCH_LANGUAGES_FINISHED,
      DataActionTypes.FETCH_LANGUAGES_ERROR
    )
  );
}

export function* fetchProjectTypes() {
  yield takeLatest(
    DataActionTypes.FETCH_PROJECT_TYPES,
    handleCommonFetch(
      'projectTypes',
      'projectTypes',
      DataActionTypes.FETCH_PROJECT_TYPES_START,
      DataActionTypes.FETCH_PROJECT_TYPES_FINISHED,
      DataActionTypes.FETCH_PROJECT_TYPES_ERROR
    )
  );
}

export function* fetchSubProjectTypes() {
  yield takeLatest(
    DataActionTypes.FETCH_SUB_PROJECT_TYPES,
    getSubProjectTypesHandler
  );
}

export function* fetchIndustries() {
  yield takeLatest(
    DataActionTypes.FETCH_INDUSTRIES,
    handleCommonFetch(
      'industries',
      'industries',
      DataActionTypes.FETCH_INDUSTRIES_START,
      DataActionTypes.FETCH_INDUSTRIES_FINISHED,
      DataActionTypes.FETCH_INDUSTRIES_ERROR
    )
  );
}

export function* fetchQualifications() {
  yield takeLatest(
    DataActionTypes.FETCH_QUALIFICATIONS,
    handleCommonFetch(
      'qualifications',
      'qualifications',
      DataActionTypes.FETCH_QUALIFICATIONS_START,
      DataActionTypes.FETCH_QUALIFICATIONS_FINISHED,
      DataActionTypes.FETCH_QUALIFICATIONS_ERROR
    )
  );
}

export function* fetchTimeslots() {
  yield takeLatest(
    DataActionTypes.FETCH_TIME_SLOTS,
    handleCommonFetch(
      'timeSlots',
      'timeSlots',
      DataActionTypes.FETCH_TIME_SLOTS_START,
      DataActionTypes.FETCH_TIME_SLOTS_FINISHED,
      DataActionTypes.FETCH_TIME_SLOTS_ERROR
    )
  );
}

export function* fetchExpertiseLevels() {
  yield takeLatest(
    DataActionTypes.FETCH_EXPERTISE_LEVELS,
    handleCommonFetch(
      'expertiseLevels',
      'expertiseLevels',
      DataActionTypes.FETCH_EXPERTISE_LEVELS_START,
      DataActionTypes.FETCH_EXPERTISE_LEVELS_FINISHED,
      DataActionTypes.FETCH_EXPERTISE_LEVELS_ERROR
    )
  );
}

export function* fetchApplicationStatuses() {
  yield takeLatest(
    DataActionTypes.FETCH_APPLICATION_STATUSES,
    handleCommonFetch(
      'applicationStatuses',
      'applicationStatuses',
      DataActionTypes.FETCH_APPLICATION_STATUSES_START,
      DataActionTypes.FETCH_APPLICATION_STATUSES_FINISHED,
      DataActionTypes.FETCH_APPLICATION_STATUSES_ERROR
    )
  );
}

export function* fetchCourses() {
  yield takeLatest(
    DataActionTypes.FETCH_COURSES,
    getCoursesOfQualificationHandler
  );
}

export function* fetchPricingTypes() {
  yield takeLatest(
    DataActionTypes.FETCH_PRICING_TYPES,
    handleCommonFetch(
      'pricingTypes',
      'pricingTypes',
      DataActionTypes.FETCH_PRICING_TYPES_START,
      DataActionTypes.FETCH_PRICING_TYPES_FINISHED,
      DataActionTypes.FETCH_PRICING_TYPES_ERROR
    )
  );
}

export function* fetchCrms() {
  yield takeLatest(
    DataActionTypes.FETCH_CRMS,
    handleCommonFetch(
      'crms',
      'crms',
      DataActionTypes.FETCH_CRMS_START,
      DataActionTypes.FETCH_CRMS_FINISHED,
      DataActionTypes.FETCH_CRMS_ERROR
    )
  );
}

export function* fetchAssessmentTypes() {
  yield takeLatest(
    DataActionTypes.FETCH_ASSESSMENT_TYPES,
    handleCommonFetch(
      'assessmentTypes',
      'assessmentTypes',
      DataActionTypes.FETCH_ASSESSMENT_TYPES_START,
      DataActionTypes.FETCH_ASSESSMENT_TYPES_FINISHED,
      DataActionTypes.FETCH_ASSESSMENT_TYPES_ERROR
    )
  );
}

export function* fetchTypingAssessmentTypes() {
  yield takeLatest(
    DataActionTypes.FETCH_TYPINGASSESSMENTTYPE_TYPES,
    handleCommonFetch(
      'typingAssessmentTypes',
      'typingAssessmentTypes',
      DataActionTypes.FETCH_TYPINGASSESSMENTTYPE_TYPES_START,
      DataActionTypes.FETCH_TYPINGASSESSMENTTYPE_TYPES_FINISHED,
      DataActionTypes.FETCH_TYPINGASSESSMENTTYPE_TYPES_ERROR
    )
  );
}

export function* fetchJobLocationTypes() {
  yield takeLatest(
    DataActionTypes.FETCH_JOBLOCATION_TYPES,
    handleCommonFetch(
      'jobLocationTypes',
      'jobLocationTypes',
      DataActionTypes.FETCH_JOBLOCATION_TYPES_START,
      DataActionTypes.FETCH_JOBLOCATION_TYPES_FINISHED,
      DataActionTypes.FETCH_JOBLOCATION_TYPES_ERROR
    )
  );
}

export function* fetchWorkPreferenceTypes() {
  yield takeLatest(
    DataActionTypes.FETCH_WORKPREFERENCE_TYPES,
    handleCommonFetch(
      'workPreferenceTypes',
      'workPreferenceTypes',
      DataActionTypes.FETCH_WORKPREFERENCE_TYPES_START,
      DataActionTypes.FETCH_WORKPREFERENCE_TYPES_FINISHED,
      DataActionTypes.FETCH_WORKPREFERENCE_TYPES_ERROR
    )
  );
}

export function* fetchFeatureFlags() {
  yield takeLatest(
    DataActionTypes.FETCH_FEATURE_FLAGS,
    handleCommonFetch(
      'featureFlags',
      'featureFlags',
      DataActionTypes.FETCH_FEATURE_FLAGS_START,
      DataActionTypes.FETCH_FEATURE_FLAGS_FINISHED,
      DataActionTypes.FETCH_FEATURE_FLAGS_ERROR
    )
  );
}

export function* fetchWorkSpaceSetup() {
  yield takeLatest(
    DataActionTypes.FETCH_WORK_SPACE_SETUP,
    handleCommonFetch(
      'workSpaceSetup',
      'workSpaceSetup',
      DataActionTypes.FETCH_WORK_SPACE_SETUP_START,
      DataActionTypes.FETCH_WORK_SPACE_SETUP_FINISHED,
      DataActionTypes.FETCH_WORK_SPACE_SETUP_ERROR
    )
  );
}

export function* fetchLeaveCategories() {
  yield takeLatest(
    DataActionTypes.FETCH_LEAVE_CATEGORIES,
    handleCommonFetch(
      'leaveCategories',
      'leaveCategories',
      DataActionTypes.FETCH_LEAVE_CATEGORIES_START,
      DataActionTypes.FETCH_LEAVE_CATEGORIES_FINISHED,
      DataActionTypes.FETCH_LEAVE_CATEGORIES_ERROR
    )
  );
}

export function* fetchSkills() {
  yield takeLatest(DataActionTypes.FETCH_SKILLS, fetchSkillsHandler);
}

export function* fetchAddressOfGeoLocation() {
  yield takeLatest(
    DataActionTypes.FETCH_ADDRESS_OF_GEOLOCATION,
    getAddressOfGeoLocationHandler
  );
}

export function* fetchDetailsOfIfsc() {
  yield takeLatest(
    DataActionTypes.FETCH_BANK_WITH_IFSC,
    getDetailsOfIfscHandler
  );
}

export function* fetchcompanyTypes() {
  yield takeLatest(
    DataActionTypes.FETCH_COMPANY_TYPES,
    handleCommonFetch(
      'companyTypes',
      'companyTypes',
      DataActionTypes.FETCH_COMPANY_TYPES_START,
      DataActionTypes.FETCH_COMPANY_TYPES_FINISHED,
      DataActionTypes.FETCH_COMPANY_TYPES_ERROR
    )
  );
}
export function* fetchRejectReasons() {
  yield takeLatest(
    DataActionTypes.FETCH_REJECT_REASONS,
    handleCommonFetch(
      'rejectReasons',
      'rejectReasons',
      DataActionTypes.FETCH_REJECT_REASONS_START,
      DataActionTypes.FETCH_REJECT_REASONS_FINISHED,
      DataActionTypes.FETCH_REJECT_REASONS_ERROR
    )
  );
}

export function* fetchRankings() {
  yield takeLatest(
    DataActionTypes.FETCH_RANKINGS,
    handleCommonFetch(
      'rankings',
      'rankings',
      DataActionTypes.FETCH_RANKINGS_START,
      DataActionTypes.FETCH_RANKINGS_FINISHED,
      DataActionTypes.FETCH_RANKINGS_ERROR
    )
  );
}
