export default {
  APPLICATION_CHANGE_STATE: 'APPLICATION_CHANGE_STATE',
  APPLICATION_CHANGE_STATE_START: 'APPLICATION_CHANGE_STATE_START',
  APPLICATION_CHANGE_STATE_FINISHED: 'APPLICATION_CHANGE_STATE_FINISHED',
  APPLICATION_CHANGE_STATE_ERROR: 'APPLICATION_CHANGE_STATE_ERROR',

  SET_APPLICATION_REVIEW: 'SET_APPLICATION_REVIEW',

  APPLICATION_PRODUCTIVITY: 'APPLICATION_PRODUCTIVITY',
  APPLICATION_PRODUCTIVITY_START: 'APPLICATION_PRODUCTIVITY_START',
  APPLICATION_PRODUCTIVITY_FINISHED: 'APPLICATION_PRODUCTIVITY_FINISHED',
  APPLICATION_PRODUCTIVITY_ERROR: 'APPLICATION_PRODUCTIVITY_ERROR',

  APPLICATION_PRODUCTIVITY_CALENDAR: 'APPLICATION_PRODUCTIVITY_CALENDAR',
  APPLICATION_PRODUCTIVITY_CALENDAR_START:
    'APPLICATION_PRODUCTIVITY_CALENDAR_START',
  APPLICATION_PRODUCTIVITY_CALENDAR_FINISHED:
    'APPLICATION_PRODUCTIVITY_CALENDAR_FINISHED',
  APPLICATION_PRODUCTIVITY_CALENDAR_ERROR:
    'APPLICATION_PRODUCTIVITY_CALENDAR_ERROR',

  APPLICATION_PRODUCTIVITY_REPORT: 'APPLICATION_PRODUCTIVITY_REPORT',
  APPLICATION_PRODUCTIVITY_REPORT_START:
    'APPLICATION_PRODUCTIVITY_REPORT_START',
  APPLICATION_PRODUCTIVITY_REPORT_FINISHED:
    'APPLICATION_PRODUCTIVITY_REPORT_FINISHED',
  APPLICATION_PRODUCTIVITY_REPORT_ERROR:
    'APPLICATION_PRODUCTIVITY_REPORT_ERROR',

  APPLICATION_PRODUCTIVITY_DETAILS: 'APPLICATION_PRODUCTIVITY_DETAILS',
  APPLICATION_PRODUCTIVITY_DETAILS_START:
    'APPLICATION_PRODUCTIVITY_DETAILS_START',
  APPLICATION_PRODUCTIVITY_DETAILS_FINISHED:
    'APPLICATION_PRODUCTIVITY_DETAILS_FINISHED',
  APPLICATION_PRODUCTIVITY_DETAILS_ERROR:
    'APPLICATION_PRODUCTIVITY_DETAILS_ERROR',

  GET_APPLICANT_RESUME: 'GET_APPLICANT_RESUME',
  GET_APPLICANT_RESUME_START: 'GET_APPLICANT_RESUME_START',
  GET_APPLICANT_RESUME_FINISHED: 'GET_APPLICANT_RESUME_FINISHED',
  GET_APPLICANT_RESUME_ERROR: 'GET_APPLICANT_RESUME_ERROR',
};
