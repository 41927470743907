export default {
  webengageEnabled() {
    return typeof window.webengage !== 'undefined';
  },

  login(userId) {
    if (this.webengageEnabled()) {
      window.webengage.user.login(userId);
    }
  },

  pageView(name, data) {
    if (this.webengageEnabled()) {
      window.webengage.screen(name, data);
    }
  },

  event(eventName, eventAttributes) {
    if (this.webengageEnabled()) {
      window.webengage.track(eventName, eventAttributes);
    }
  },

  logout() {
    if (this.webengageEnabled()) {
      window.webengage.user.logout();
    }
  }
};
