export const UserActionType = {
  SIGNUP_SELECT_BUSINESS_TYPE: 'SIGNUP_SELECT_BUSINESS_TYPE',
  INCREMENT_UNREAD_CHAT_COUNT: 'INCREMENT_UNREAD_CHAT_COUNT',
  CLEAR_UNREAD_CHAT_COUNT: 'CLEAR_UNREAD_CHAT_COUNT',

  SIGNUP_RESET: 'SIGNUP_RESET',
  USER_SIGNUP: 'USER_SIGNUP',
  USER_SIGNUP_STARTED: 'USER_SIGNUP_STARTED',
  USER_SIGNUP_FINISHED: 'USER_SIGNUP_FINISHED',
  USER_SIGNUP_ERROR: 'USER_SIGNUP_ERROR',

  LOGIN_RESET: 'LOGIN_RESET',
  USER_SET: 'USER_SET',
  VOIZ_USER_SET: 'VOIZ_USER_SET',

  USER_LOGIN: 'USER_LOGIN',
  USER_LOGIN_STARTED: 'USER_LOGIN_STARTED',
  USER_LOGIN_FINISHED: 'USER_LOGIN_FINISHED',
  USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',

  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_STARTED: 'FORGOT_PASSWORD_STARTED',
  FORGOT_PASSWORD_FINISHED: 'FORGOT_PASSWORD_FINISHED',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',

  VALIDATE_PASSWORD_RESET_TOKEN: 'VALIDATE_RESET_PASSWORD_TOKEN',
  VALIDATE_PASSWORD_RESET_TOKEN_STARTED:
    'VALIDATE_RESET_PASSWORD_TOKEN_STARTED',
  VALIDATE_PASSWORD_RESET_TOKEN_FINISHED:
    'VALIDATE_RESET_PASSWORD_TOKEN_FINISHED',
  VALIDATE_PASSWORD_RESET_TOKEN_ERROR: 'VALIDATE_RESET_PASSWORD_TOKEN_ERROR',

  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_STARTED: 'RESET_PASSWORD_STARTED',
  RESET_PASSWORD_FINISHED: 'RESET_PASSWORD_FINISHED',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',

  OTP_SEND: 'OTP_SEND',
  OTP_SEND_STARTED: 'OTP_SEND_STARTED',
  OTP_SEND_FINISHED: 'OTP_SEND_FINISHED',
  OTP_SEND_ERROR: 'OTP_SEND_ERROR',

  BYPASS_OTP_SEND: 'BYPASS_OTP_SEND',
  BYPASS_OTP_SEND_STARTED: 'BYPASS_OTP_SEND_STARTED',
  BYPASS_OTP_SEND_FINISHED: 'BYPASS_OTP_SEND_FINISHED',
  BYPASS_OTP_SEND_ERROR: 'BYPASS_OTP_SEND_ERROR',

  OTP_VERIFY: 'OTP_VERIFY',
  OTP_VERIFY_STARTED: 'OTP_VERIFY_STARTED',
  OTP_VERIFY_FINISHED: 'OTP_VERIFY_FINISHED',
  OTP_VERIFY_ERROR: 'OTP_VERIFY_ERROR',

  SIGNOUT: 'SIGNOUT',
  SIGNOUT_START: 'SIGNOUT_START',
  SIGNOUT_FINISHED: 'SIGNOUT_FINISHED',
  SIGNOUT_ERROR: 'SIGNOUT_ERROR',

  SET_USER_AND_TOKEN: 'SET_USER_AND_TOKEN',

  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_START: 'UPDATE_USER_START',
  UPDATE_USER_FINISHED: 'UPDATE_USER_FINISHED',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_START: 'CHANGE_PASSWORD_START',
  CHANGE_PASSWORD_FINISHED: 'CHANGE_PASSWORD_FINISHED',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

  DEACTIVATE_USER: 'DEACTIVATE_USER',
  DEACTIVATE_USER_START: 'DEACTIVATE_USER_START',
  DEACTIVATE_USER_FINISHED: 'DEACTIVATE_USER_FINISHED',
  DEACTIVATE_USER_ERROR: 'DEACTIVATE_USER_ERROR',

  DELETE_USER: 'DELETE_USER',
  DELETE_USER_START: 'DELETE_USER_START',
  DELETE_USER_FINISHED: 'DELETE_USER_FINISHED',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',

  SET_FEATURE_FLAG: 'SET_FEATURE_FLAG',
  SET_FEATURE_FLAG_START: 'SET_FEATURE_FLAG_START',
  SET_FEATURE_FLAG_FINISHED: 'SET_FEATURE_FLAG_FINISHED',
  SET_FEATURE_FLAG_ERROR: 'SET_FEATURE_FLAG_ERROR',

  INITIALIZE_CHAT: 'INITIALIZE_CHAT',
  CURRENT_SELECTED_CHAT: 'CURRENT_SELECTED_CHAT',
  SET_MESSAGES: 'SET_MESSAGES',
  APPEND_MESSAGE: 'APPEND_MESSAGE',
  UNREAD_CHAT_COUNT: 'UNREAD_CHAT_COUNT',
  SET_BACK_LINK: 'SET_BACK_LINK',

  RECIEVE_MESSAGES: 'RECIEVE_MESSAGES',
  DELETE_MESSAGES: 'DELETE_MESSAGES',

  SET_ONBOARDING_DATA: 'SET_ONBOARDING_DATA',
  SET_ONBOARDING_DATA_START: 'SET_ONBOARDING_DATA_START',
  SET_ONBOARDING_DATA_FINISHED: 'SET_ONBOARDING_DATA_FINISHED',
  SET_ONBOARDING_DATA_ERROR: 'SET_ONBOARDING_DATA_ERROR',

  VERIFY_BYPASS_OTP: 'VERIFY_BYPASS_OTP',
  VERIFY_BYPASS_OTP_START: 'VERIFY_BYPASS_OTP_START',
  VERIFY_BYPASS_OTP_FINISHED: 'VERIFY_BYPASS_OTP_FINISHED',
  VERIFY_BYPASS_OTP_ERROR: 'VERIFY_BYPASS_OTP_ERROR',
  TOGGLE_OTP_MODAL: 'TOGGLE_OTP_MODAL',

  GET_ADMINBYPASS_DETAILS: 'GET_ADMINBYPASS_DETAILS',
  GET_ADMINBYPASS_DETAILS_START: 'GET_ADMINBYPASS_DETAILS_START',
  GET_ADMINBYPASS_DETAILS_FINISHED: 'GET_ADMINBYPASS_DETAILS_FINISHED',
  GET_ADMINBYPASS_DETAILS_ERROR: 'GET_ADMINBYPASS_DETAILS_ERROR',

  REPORT_ABUSE: 'REPORT_ABUSE',
  REPORT_ABUSE_START: 'REPORT_ABUSE_START',
  REPORT_ABUSE_FINISHED: 'REPORT_ABUSE_FINISHED',
  REPORT_ABUSE_ERROR: 'REPORT_ABUSE_ERROR',

  GET_USER_FAVORITES: 'GET_USER_FAVORITES',
  GET_USER_FAVORITES_START: 'GET_USER_FAVORITES_START',
  GET_USER_FAVORITES_FINISHED: 'GET_USER_FAVORITES_FINISHED',
  GET_USER_FAVORITES_ERROR: 'GET_USER_FAVORITES_ERROR',

  ADD_TO_FAVORITES: 'ADD_TO_FAVORITES',
  ADD_TO_FAVORITES_START: 'ADD_TO_FAVORITES_START',
  ADD_TO_FAVORITES_FINISHED: 'ADD_TO_FAVORITES_FINISHED',
  ADD_TO_FAVORITES_ERROR: 'ADD_TO_FAVORITES_ERROR',
};

export default UserActionType;
