import PaymentActionTypes from './payment.types';
import {takeLatest} from 'redux-saga/effects';
import {commonAjaxHandler} from '../../url';

const handlemakePayment = commonAjaxHandler(
    '/payment',
    {
        method: 'post',
        start: PaymentActionTypes.PAYMENT_START,
        success: PaymentActionTypes.PAYMENT_FINISHED,
        error: PaymentActionTypes.PAYMENT_ERROR,
        payloadKeys: [ 'payment_info','user' ]
    }
);

export function* makePayment() {
    yield takeLatest(
        PaymentActionTypes.PAYMENT,
        handlemakePayment
    );
}
